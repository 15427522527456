import { Util } from './util.js';

class Snippets {
    constructor(builder) {
        this.builder = builder;

        this.util = new Util(this.builder);
    }

    getSnippetsHtml() {
        const util = this.util;

        const snippetUrl = this.builder.opts.snippetUrl;
        const snippetPath = this.builder.opts.snippetPath;

        let snippetCategoriesString = '[';

        if ( !!this.builder.opts.snippetCategories && !!this.builder.opts.snippetCategories?.length ) 
            for (let i=0;i<this.builder.opts.snippetCategories.length;i++)
                snippetCategoriesString+= `[${this.builder.opts.snippetCategories[i][0]},'${util.out(this.builder.opts.snippetCategories[i][1])}'],`;

        snippetCategoriesString+=']';

        const defaultSnippetCategory = this.builder.opts.defaultSnippetCategory;
        
        {/* <script src="${snippetUrl}" type="text/javascript"></script> */}
        const html = `
        <!DOCTYPE HTML>
        <html>
        
        <head>
            <meta charset="utf-8">
            <title></title>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <meta name="description" content="">
            
            <style>
                body {
                    // background: #fff;
                    background: ${this.builder.styleSnippetBackground};
                    margin: 0;
                }
                /*
                body.dark {
                    background: #111;
                }
                .dark .is-categories {
                    background: #333;
                }
                .dark .is-category-list a {
                    background: #333;
                    color: #fff;
                }
                .dark .is-category-list a.active {
                    background: #525252;
                    color: #fff;
                }
                .dark .is-category-list a:hover {
                    color: #fff;
                }
                .dark .is-design-list>div img {
                    opacity: 0.93;
                }
                .dark .is-more-categories {
                    background: #333;
                }
                .dark .is-more-categories a {
                    background: #333;
                    color: #fff;
                }
                .dark .is-more-categories a:hover {
                    background: #4c4c4c;
                }
                .dark .is-more-categories a.active {
                    background: #4c4c4c;
                }
                */

                
                .dark .is-design-list>div {
                    outline: transparent 1px solid;
                }

                
                        
                /* Scrollbar for modal */

                /* Darker color, because background for snippet thumbnails is always light. */
                .dark * {
                    scrollbar-width: thin;
                    scrollbar-color: rgb(78 78 78 / 62%) auto;
                }
                .dark *::-webkit-scrollbar {
                    width: 12px;
                }
                .dark *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .dark *::-webkit-scrollbar-thumb {
                    background-color:rgb(78 78 78 / 62%);
                } 

                .colored-dark * {
                    scrollbar-width: thin;
                    scrollbar-color: rgb(100, 100, 100) auto;
                }
                .colored-dark *::-webkit-scrollbar {
                    width: 12px;
                }
                .colored-dark *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .colored-dark *::-webkit-scrollbar-thumb {
                    background-color:rgb(100, 100, 100);
                } 

                .colored * {
                    scrollbar-width: thin;
                    scrollbar-color: rgba(0, 0, 0, 0.4) auto;
                }
                .colored *::-webkit-scrollbar {
                    width: 12px;
                }
                .colored *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .colored *::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, 0.4);
                } 

                .light * {
                    scrollbar-width: thin;
                    scrollbar-color: rgba(0, 0, 0, 0.4) auto;
                }
                .light *::-webkit-scrollbar {
                    width: 12px;
                }
                .light *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .light *::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, 0.4);
                } 
        
                svg {
                    fill: ${this.builder.styleSnippetColor};
                }

                .is-design-list {
                    position: fixed;
                    top: 0px;
                    left: 0px;
                    border-top: transparent 68px solid;
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    padding: 0px 0px 30px 30px;
                    box-sizing: border-box;
                    overflow: auto;
                }
        
                .is-design-list>div {
                    width: 250px;
                    min-height:120px;
                    position:relative;
                    background: #fff;
                    // background: ${this.builder.styleToolBackground};
                    overflow: hidden;
                    margin: 32px 40px 0 0;
                    cursor: pointer;
                    display: inline-block;
                    outline: #ececec 1px solid;
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
                }
        
                .is-design-list>div img {
                    box-shadow: none;
                    opacity: 1;
                    display: block;
                    box-sizing: border-box;
                    transition: all 0.2s ease-in-out;
                    max-width: 400px;
                    width: 100%
                }
        
                // .is-design-list>div:hover img {
                //     opacity: 0.95;
                // }
                
                // .is-design-list>div:hover {
                //     background: #999;
                // }
                .is-overlay {
                    position:absolute;left:0px;top:0px;width:100%;height:100%;
                }
                .is-design-list>div .is-overlay:after {
                    background: rgba(0, 0, 0, 0.02);
                    position: absolute;
                    content: "";
                    display: block;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    transition: all 0.3s ease-in-out;
                    opacity: 0;
                }
                .is-design-list>div:hover .is-overlay:after {
                    opacity: 0.9;
                }
                .dark .is-design-list>div .is-overlay:after {
                    background: rgb(78 78 78 / 13%);
                }
        
                .is-category-list {
                    position: relative;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 80px;
                    box-sizing: border-box;
                    z-index: 1;
                }
        
                .is-category-list>div {
                    white-space: nowrap;
                    padding: 0 30px;
                    box-sizing: border-box;
                    font-family: sans-serif;
                    font-size: 10px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    // background: #f5f5f5;
                    background: ${this.builder.styleSnippetTabsBackground};
                    // box-shadow: 0 5px 8px rgb(0 0 0 / 4%);
                }
        
                .is-category-list a {
                    display: inline-block;
                    padding: 10px 20px;
                    // background: #fefefe;
                    // color: #000;
                    background: ${this.builder.styleSnippetTabItemBackground};
                    color: ${this.builder.styleSnippetTabItemColor};
                    border-radius: 50px;
        
                    margin: 0 12px 0 0;
                    text-decoration: none;
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.03);
                    transition: box-shadow ease 0.3s;
                }
        
                .is-category-list a:hover {
                    /*background: #fafafa;*/
                    background: ${this.builder.styleSnippetTabItemBackgroundHover};
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.06);
                    // color: #000;
                    color: ${this.builder.styleSnippetTabItemColor};
                }
        
                .is-category-list a.active {
                    // background: #f5f5f5;
                    background: ${this.builder.styleSnippetTabItemBackgroundActive};
                    // color: #000;
                    color: ${this.builder.styleSnippetTabItemColor};
                    box-shadow: none;
                    cursor: default;
                }
        
                .is-more-categories {
                    display: none;
                    position: absolute;
                    width: 400px;
                    box-sizing: border-box;
                    padding: 0;
                    z-index: 1;
                    font-family: sans-serif;
                    font-size: 10px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
                    // background: #fff;
                    background: ${this.builder.styleSnippetMoreItemBackground};
                }
        
                .is-more-categories a {
                    width: 200px;
                    float: left;
                    display: block;
                    box-sizing: border-box;
                    padding: 12px 20px;
                    // background: #fff;
                    background: ${this.builder.styleSnippetMoreItemBackground};
                    text-decoration: none;
                    // color: #000;
                    color: ${this.builder.styleSnippetMoreItemColor};
                    line-height: 1.6;
                }
        
                .is-more-categories a:hover {
                    // background: #eee;
                    background: ${this.builder.styleSnippetMoreItemBackgroundHover};
                }
        
                .is-more-categories a.active {
                    // background: #eee;
                    background: ${this.builder.styleSnippetMoreItemBackgroundActive};
                }
        
                .is-more-categories.active {
                    display: block;
                }
        
                /* First Loading */
                /* .is-category-list {
                    display: none;
                }
        
                .is-design-list {
                    display: none;
                }
        
                .pace {
                    -webkit-pointer-events: none;
                    pointer-events: none;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    user-select: none;
                }
        
                .pace-inactive {
                    display: none;
                }
        
                .pace .pace-progress {
                    background: #000000;
                    position: fixed;
                    z-index: 2000;
                    top: 0;
                    right: 100%;
                    width: 100%;
                    height: 2px;
                } */
        
                .is-more-categories>a:nth-child(0) {
                    display: none
                }
        
                .is-more-categories>a:nth-child(1) {
                    display: none
                }
        
                .is-more-categories>a:nth-child(2) {
                    display: none
                }
        
                .is-more-categories>a:nth-child(3) {
                    display: none
                }
        
                .is-more-categories>a:nth-child(4) {
                    display: none
                }
        
                .is-more-categories>a:nth-child(5) {
                    display: none
                }
        
                .is-more-categories>a:nth-child(6) {
                    display: none
                }
        
                .is-more-categories>a:nth-child(7) {
                    display: none
                }
        
                @media all and (max-width: 1212px) {
                    .is-categories>a:nth-child(7):not(.more-snippets) {
                        display: none
                    }
        
                    .is-more-categories>a:nth-child(7) {
                        display: block
                    }
                }
        
                @media all and (max-width: 1070px) {
                    .is-categories>a:nth-child(6):not(.more-snippets) {
                        display: none
                    }
        
                    .is-more-categories>a:nth-child(6) {
                        display: block
                    }
                }
        
                @media all and (max-width: 940px) {
                    .is-categories>a:nth-child(5):not(.more-snippets) {
                        display: none
                    }
        
                    .is-more-categories>a:nth-child(5) {
                        display: block
                    }
                }
        
                @media all and (max-width: 700px) {
                    .is-categories>a:nth-child(4):not(.more-snippets) {
                        display: none
                    }
        
                    .is-more-categories>a:nth-child(4) {
                        display: block
                    }
                }
        
                @media all and (max-width: 555px) {
                    .is-categories>a:nth-child(3):not(.more-snippets) {
                        display: none
                    }
        
                    .is-more-categories>a:nth-child(3) {
                        display: block
                    }
                }
        
                @media all and (max-width: 415px) {
                    .is-categories>a:nth-child(2):not(.more-snippets) {
                        display: none
                    }
        
                    .is-more-categories>a:nth-child(2) {
                        display: block
                    }
                }
        
                @media all and (max-width: 640px) {
                    .is-more-categories a {
                        width: 150px;
                        padding: 10px 5px 10px 15px;
                        font-size: 10px;
                    }
        
                    .is-more-categories {
                        left: 0 !important;
                        width: 100% !important;
                    }
                }
            </style>
        </head>
        
        <body${this.builder.styleDark?' class="dark"':''}${this.builder.styleColored?' class="colored"':''}${this.builder.styleColoredDark?' class="colored-dark"':''}${this.builder.styleLight?' class="light"':''}>

            <svg style="display:none">
                <defs>
                    <symbol viewBox="0 0 512 512" id="ion-ios-close-empty">
                        <path d="M340.2 160l-84.4 84.3-84-83.9-11.8 11.8 84 83.8-84 83.9 11.8 11.7 84-83.8 84.4 84.2 11.8-11.7-84.4-84.3 84.4-84.2z"></path>
                    </symbol>
                </defs>
            </svg>
        
            <div class="is-pop-close"
                style="z-index:10;width:30px;height:30px;position:absolute;top:0px;right:0px;box-sizing:border-box;padding:0;line-height:40px;font-size: 12px;text-align:center;cursor:pointer;">
                <svg class="is-icon-flex" style="width:30px;height:30px;">
                    <use xlink:href="#ion-ios-close-empty"></use>
                </svg>
            </div>
        
            <div class="is-category-list">
                <div class="is-categories" style="position:fixed;top:0;left:0;right:0;height:68px;padding-top:17px;box-sizing:border-box;">
                </div>
            </div>

            <div class="is-more-categories">
            </div>
        
            <div class="is-design-list">
            </div>
        
            <script>
        
                var snippetPath = '${snippetPath}';
                var snippetCategories = ${snippetCategoriesString};
                var defaultSnippetCategory = ${defaultSnippetCategory};
        
                var numOfCat = snippetCategories.length;
                if (numOfCat <= 7) {
                    document.querySelector('.is-more-categories').style.width = '200px';
                }
        
                var categorytabs = document.querySelector('.is-categories');
                categorytabs.innerHTML = '';
                let html_catselect = '';
                for (var i = 0; i < numOfCat; i++) {
                    if (i < 7) {
                        html_catselect += '<a href="" data-cat="' + snippetCategories[i][0] + '">' + parent._cb.out(snippetCategories[i][1]) + '</a>';
                    }
                }
                html_catselect += '<a href="" class="more-snippets">' + parent._cb.out('More') + '</a>';
                categorytabs.innerHTML = html_catselect;
        
                var categorymore = document.querySelector('.is-more-categories');
                html_catselect = '';
                for (var i = 0; i < numOfCat; i++) {
                    html_catselect += '<a href="" data-cat="' + snippetCategories[i][0] + '">' + parent._cb.out(snippetCategories[i][1]) + '</a>';
                }
                categorymore.innerHTML = html_catselect;
        
                // Show/hide "More" button
                if (numOfCat <= 7) {
                    var bHasMore = false;
        
                    const childNodes = categorymore.childNodes;
                    let i = childNodes.length;
                    while (i--) {
                        if(childNodes[i].style.display === 'block') {
                            bHasMore = true;
                        }
                    }
                    var more = document.querySelector('.more-snippets');
                    if (!bHasMore) more.style.display = 'none';
                    else more.style.display = '';
                }
        
                var elms = categorytabs.querySelectorAll('a[data-cat="' + defaultSnippetCategory + '"]'); //.classList.add('active');
                Array.prototype.forEach.call(elms, function(elm){
                    elm.classList.add('active');
                });
                elms = categorymore.querySelectorAll('a[data-cat="' + defaultSnippetCategory + '"]'); //.classList.add('active');
                Array.prototype.forEach.call(elms, function(elm){
                    elm.classList.add('active');
                });





                var data_basic = (((parent._cb||{}).opts||{}).snippetJSON||{}) || parent.window.snippetJSON;
                var snippets = (data_basic||{}).snippets || []; //DATA
        
                // Hide slider snippet if slick is not included
                var bHideSliderSnippet = true;
                if(parent.jQuery) {
                    if(parent.jQuery.fn.slick) {
                        bHideSliderSnippet = false;
                    }
                }

                if ( !!data_basic && !!data_basic.snippets && !!data_basic.snippets.length ) for (var nIndex = 0; nIndex < data_basic.snippets.length; nIndex++) {
                    if (data_basic.snippets[nIndex].thumbnail?.indexOf('element-slider.png') != -1 && bHideSliderSnippet) {
                        data_basic.snippets.splice(nIndex, 1);
                        break;
                    }
                }
                
                var designlist = document.querySelector('.is-design-list');
                if ( !!snippets ) for (let i = 0; i <snippets.length; i++) {
                    
                    snippets[i].id = i+1;
                    var thumb = snippets[i].thumbnail;
        
                    thumb = thumb;
        
                    if (snippets[i].category === defaultSnippetCategory + '') {
                        designlist.insertAdjacentHTML('beforeend', '<div data-id="' + snippets[i].id + '" data-cat="' + snippets[i].category + '"><img loading="lazy" src="' + thumb + '"><span class="is-overlay"></span></div>');
                    
                        var newitem = designlist.querySelector('[data-id="' + snippets[i].id + '"]');
                        newitem.addEventListener('click', function(e){
        
                            var snippetid = e.target.parentNode.getAttribute('data-id');
                            addSnippet(snippetid);
        
                        });
        
                    }
        
                }
        
                var categorylist = document.querySelector('.is-category-list');
                elms = categorylist.querySelectorAll('a');
                Array.prototype.forEach.call(elms, function(elm){
        
                    elm.addEventListener('click', function(e){
        
                        if(elm.classList.contains('active')) {
                            e.preventDefault();
                            return false;
                        }
        
                        var cat = elm.getAttribute('data-cat');
                        if(designlist.querySelectorAll('[data-cat="' + cat + '"]').length === 0) {
        
                            if ( !!snippets ) for (let i = 0; i <snippets.length; i++) {
                        
                                var thumb = snippets[i].thumbnail;
                                
                                thumb = thumb;    
        
                                if (snippets[i].category === cat) {
                                    designlist.insertAdjacentHTML('beforeend', '<div data-id="' + snippets[i].id + '" data-cat="' + snippets[i].category + '"><img loading="lazy" src="' + thumb + '"><span class="is-overlay"></span></div>');
                                
                                    var newitem = designlist.querySelector('[data-id="' + snippets[i].id + '"]');
                                    newitem.addEventListener('click', function(e){
                                        
                                        var snippetid = e.target.parentNode.getAttribute('data-id');
                                        addSnippet(snippetid);
        
                                    });
                                }
        
                            }    
                        }
        
                        if (cat) {
                            // Hide all, show items from selected category
                            var categorylist_items = categorylist.querySelectorAll('a');    
                            Array.prototype.forEach.call(categorylist_items, function(elm){
                                elm.className = elm.className.replace('active', '');
                            });
                            categorymore.className = categorymore.className.replace('active', ''); 
                            var categorymore_items = categorymore.querySelectorAll('a');
                            Array.prototype.forEach.call(categorymore_items, function(elm){
                                elm.className = elm.className.replace('active', '');
                            });
        
                            var items = designlist.querySelectorAll('div');
                            Array.prototype.forEach.call(items, function(elm){
                                elm.style.display = 'none';
                            });
                            Array.prototype.forEach.call(items, function(elm){
                                var catSplit = elm.getAttribute('data-cat').split(',');
                                for (var j = 0; j < catSplit.length; j++) {
                                    if (catSplit[j] == cat) {
                                        elm.style.display = ''; // TODO: hide & show snippets => animated
                                    }
                                }
                            });
                            
                        } else {
                            // more snipptes
                            var more = document.querySelector('.more-snippets');
                            var moreCategories = document.querySelector('.is-more-categories');
                            if(more.classList.contains('active')) {
                                more.className = more.className.replace('active', '');
                                moreCategories.className = moreCategories.className.replace('active', '');
                            } else {
                                var _width = moreCategories.offsetWidth;
                                more.classList.add('active');
                                moreCategories.classList.add('active');
                                var top = more.getBoundingClientRect().top;
                                var left = more.getBoundingClientRect().left;
                                top = top + 50;
                                moreCategories.style.top = top + 'px';
                                moreCategories.style.left = left + 'px';
                            }
                        }
                        elm.classList.add('active');
        
                        e.preventDefault();
                    });
        
                });
        
                elms = categorymore.querySelectorAll('a');
                Array.prototype.forEach.call(elms, function(elm){
        
                    elm.addEventListener('click', function(e){
                        
                        var cat = elm.getAttribute('data-cat');
                        if(designlist.querySelectorAll('[data-cat="' + cat + '"]').length === 0) {
        
                            if ( !!snippets ) for (let i = 0; i <snippets.length; i++) {
                        
                                var thumb = snippets[i].thumbnail;
                                
                                thumb = thumb;
        
                                if (snippets[i].category === cat) {
                      
                                    designlist.insertAdjacentHTML('beforeend', '<div data-id="' + snippets[i].id + '" data-cat="' + snippets[i].category + '"><img loading="lazy" src="' + thumb + '"><span class="is-overlay"></span></div>');
                                
                                    var newitem = designlist.querySelector('[data-id="' + snippets[i].id + '"]');
                                    newitem.addEventListener('click', function(e){
                                        
                                        var snippetid = e.target.parentNode.getAttribute('data-id');
                                        addSnippet(snippetid);
        
                                    });
                                }
        
                            }    
                        }
        
                        // Hide all, show items from selected category
                        Array.prototype.forEach.call(elms, function(elm){
                            elm.className = elm.className.replace('active', '');
                        });
                        categorymore.className = categorymore.className.replace('active', ''); // hide popup
                        //var categorymore_items = categorymore.querySelectorAll('a');
                        
                        var categorylist = document.querySelector('.is-category-list');
                        var categorylist_items = categorylist.querySelectorAll('a');                
                        Array.prototype.forEach.call(categorylist_items, function(elm){
                            elm.className = elm.className.replace('active', '');
                        });
                            
                        var more = document.querySelector('.more-snippets');
                        more.className = more.className.replace('active', '');
        
                        var items = designlist.querySelectorAll('div');
                        Array.prototype.forEach.call(items, function(elm){
                            elm.style.display = 'none';
                        });
                        Array.prototype.forEach.call(items, function(elm){
                            var catSplit = elm.getAttribute('data-cat').split(',');
                            for (var j = 0; j < catSplit.length; j++) {
                                if (catSplit[j] == cat) {
                                    elm.style.display = '';
                                }
                            }
                        });
        
                        elm.classList.add('active');
        
                        e.preventDefault();
                    });
        
                });
        
                var close = document.querySelector('.is-pop-close');
                close.addEventListener('click', function(e){
                    var modal = parent.document.querySelector('.is-modal.snippets');
                    removeClass(modal, 'active');
                });
        
                // Add document Click event
                document.addEventListener('click', function(e){
                    e = e || window.event;
                    var target = e.target || e.srcElement;  
        
                    if(parentsHasClass(target, 'more-snippets')) return;
                    if(hasClass(target, 'more-snippets')) return;
                    
                    var more = document.querySelector('.more-snippets');
                    var moreCategories = document.querySelector('.is-more-categories');
                    
                    more.className = more.className.replace('active', '');
                    moreCategories.className = moreCategories.className.replace('active', '');
                });
        
                parent.document.addEventListener('click', function(e){
                    var more = document.querySelector('.more-snippets');
                    var moreCategories = document.querySelector('.is-more-categories');
                    
                    more.className = more.className.replace('active', '');
                    moreCategories.className = moreCategories.className.replace('active', '');
                });
        
                function addSnippet(snippetid) {
                    
                    // TODO: var framework = parent._cb.opts.framework;
                    var snippetPathReplace = parent._cb.opts.snippetPathReplace;
                    var emailMode = parent._cb.opts.emailMode;
                    
                    // 
                    if ( !!snippets ) for (let i = 0; i <snippets.length; i++) {
                        if(snippets[i].id + ''=== snippetid) {
                            
                            var html = snippets[i].html;
                            var noedit = snippets[i].noedit;
                            break;
                        }
                    }
        
                    var bSnippet;
                    if (html.indexOf('row clearfix') === -1) {
                        bSnippet = true; // Just snippet (without row/column grid)
                    } else {
                        bSnippet = false; // Snippet is wrapped in row/colum
                    }
                    if (emailMode) bSnippet = false;

                    if(bSnippet) {
                        var quickadd = parent._cb.builderStuff.querySelector('.quickadd');
                        var mode = quickadd.getAttribute('data-mode');
                        if(!mode) {
                            // in case of using viewSnippets() to open the dialog (mode=null) => change to non snippet.
                            html = '<div class="row clearfix">' +
                                '<div class="column full">' +
                                html +
                                '</div>' +
                            '</div>';
                            bSnippet=false;
                        }
                    }
         
                    // Convert snippet into your defined 12 columns grid   
                    var rowClass = parent._cb.opts.row; //row
                    var colClass = parent._cb.opts.cols; //['col s1', 'col s2', 'col s3', 'col s4', 'col s5', 'col s6', 'col s7', 'col s8', 'col s9', 'col s10', 'col s11', 'col s12']
                    if(rowClass!=='' && colClass.length===12){
                        html = html.replace(new RegExp('row clearfix', 'g'), rowClass);
                        html = html.replace(new RegExp('column full', 'g'), colClass[11]);
                        html = html.replace(new RegExp('column half', 'g'), colClass[5]);
                        html = html.replace(new RegExp('column third', 'g'), colClass[3]);
                        html = html.replace(new RegExp('column fourth', 'g'), colClass[2]);
                        html = html.replace(new RegExp('column fifth', 'g'), colClass[1]);
                        html = html.replace(new RegExp('column sixth', 'g'), colClass[1]);
                        html = html.replace(new RegExp('column two-third', 'g'), colClass[7]);
                        html = html.replace(new RegExp('column two-fourth', 'g'), colClass[8]);
                        html = html.replace(new RegExp('column two-fifth', 'g'), colClass[9]);
                        html = html.replace(new RegExp('column two-sixth', 'g'), colClass[9]);
                    }
                    
                    html = html.replace(/{id}/g, makeid()); // Replace {id} with auto generated id (for custom code snippet)
        
                    if(parent._cb.opts.onAdd){
                        html = parent._cb.opts.onAdd(html);
                    }

                    if(snippetPathReplace.length>0) {
                        if (snippetPathReplace[0] != '') {
                            var regex = new RegExp(snippetPathReplace[0], 'g');
                            html = html.replace(regex, snippetPathReplace[1]);
                        }
                    }
                    
                    parent._cb.addSnippet(html, bSnippet, noedit);
        
                    var modal = parent.document.querySelector('.is-modal.snippets');
                    removeClass(modal, 'active');
        
                }
        
                function hasClass(element, classname) {
                    if(!element) return false;
                    return element.classList ? element.classList.contains(classname) : new RegExp('\\b'+ classname+'\\b').test(element.className);
                }
        
                function removeClass(element, classname) {
                    if(!element) return;
                    if(element.classList.length>0) {
                        element.className = element.className.replace(classname, '');
                    }
                }
                
                function parentsHasClass(element, classname) {
                    while (element) {
                        if(element.tagName === 'BODY' || element.tagName === 'HTML') return false;
                        if(!element.classList) return false;
                        if (element.classList.contains(classname)) {
                            return true;
                        }
                        element = element.parentNode;
                    }
                }
        
                function makeid() {//http://stackoverflow.com/questions/1349404/generate-a-string-of-5-random-characters-in-javascript
                    var text = "";
                    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
                    for (var i = 0; i < 2; i++)
                        text += possible.charAt(Math.floor(Math.random() * possible.length));
        
                    var text2 = "";
                    var possible2 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                    for (var i = 0; i < 5; i++)
                        text2 += possible2.charAt(Math.floor(Math.random() * possible2.length));
        
                    return text + text2;
                }
        
            </script>
        
        </textarea>
        </body>
        
        </html>
        `;
        return html;
    }
}

export default Snippets;
