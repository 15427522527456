import { Util, Dom, queryCellBackgroundImage, TOOLS_MOUNTED_CUSTOM_EVENT, changeCellBackgroundImageByUrl, urlRegex, changeColumnSettingsBgImage, } from './util.js';
import renderQuickAdd from './quickadd.js';
import GradientPicker from './gradientpicker.js';
import Grid from './grid.js';
import HtmlUtil from './html.js';

const dom = new Dom();

export class ColumnTool{

    constructor(builder) {
        this.builder = builder;

        this.grid = new Grid(builder);

        const util = new Util(builder);
        this.util = util;

        const builderStuff = util.builderStuff();

        let columnTool = builderStuff.querySelector('.is-column-tool');
        let columnMore;
        let htmlbutton = '';
        if(builder.opts.columnHtmlEditor) htmlbutton = `<button type="button" title="${util.out('HTML')}" class="cell-html">
                <span><svg class="is-icon-flex" style="margin-right:-3px;width:12px;height:12px;"><use xlink:href="#ion-ios-arrow-left"></use></svg><svg class="is-icon-flex" style="margin-left:-2px;width:12px;height:12px;"><use xlink:href="#ion-ios-arrow-right"></use></svg></span>${util.out('HTML')}
            </button>`;
        if(!columnTool){
            let html = `<div class="is-tool is-column-tool">
                <button type="button" title="${util.out('Add')}" class="cell-add"><svg class="is-icon-flex"><use xlink:href="#ion-ios-plus-empty"></use></svg></button>
                <button type="button" title="${util.out('More')}" class="cell-more"><svg class="is-icon-flex"><use xlink:href="#ion-more"></use></svg></button>
                <button type="button" title="${util.out('Delete')}" class="cell-remove"><svg class="is-icon-flex" style="margin-left:-1px"><use xlink:href="#ion-ios-close-empty"></use></svg></button>
            </div>
            
            <div class="is-pop columnmore">
                <div style="display:flex;flex-flow:wrap;padding-top:3px;">
                    <button type="button" title="${util.out('Move Left')}" class="cell-prev"><span><svg class="is-icon-flex" style="width:15px;height:15px;"><use xlink:href="#ion-ios-arrow-thin-left"></use></svg></span>${util.out('Move Left')}</button>
                    <button type="button" title="${util.out('Move Right')}" class="cell-next"><span><svg class="is-icon-flex" style="width:15px;height:15px;"><use xlink:href="#ion-ios-arrow-thin-right"></use></svg></span>${util.out('Move Right')}</button>
                    <button type="button" title="${util.out('Move Up')}" class="cell-up"><span><svg class="is-icon-flex" style="width:15px;height:15px;"><use xlink:href="#ion-ios-arrow-thin-up"></use></svg></span>${util.out('Move Up')}</button>
                    <button type="button" title="${util.out('Move Down')}" class="cell-down"><span><svg class="is-icon-flex" style="width:15px;height:15px;"><use xlink:href="#ion-ios-arrow-thin-down"></use></svg></span>${util.out('Move Down')}</button>
                    <button type="button" title="${util.out('Increase')}" class="cell-increase"><span><svg class="is-icon-flex" style="width:13px;height:13px;"><use xlink:href="#icon-increase"></use></svg></span>${util.out('Increase')}</button>
                    <button type="button" title="${util.out('Decrease')}" class="cell-decrease"><span><svg class="is-icon-flex" style="width:13px;height:13px;"><use xlink:href="#icon-decrease"></use></svg></span>${util.out('Decrease')}</button>
                    <button type="button" title="${util.out('Duplicate')}" class="cell-duplicate"><span><svg class="is-icon-flex" style="width:12px;height:12px;"><use xlink:href="#ion-ios-photos-outline"></use></svg></span>${util.out('Duplicate')}</button>
                    ${htmlbutton}
                    <div class="is-separator"></div>
                    <button title="${util.out('Lock')}" class="cell-locking">
                        <svg class="is-icon-flex"><use xlink:href="#icon-lock"></use></svg>
                    </button>
                    <button title="${util.out('Settings')}" class="cell-settings">
                        <svg class="is-icon-flex"><use xlink:href="#ion-ios-gear"></use></svg>
                        <span>${util.out('Settings')}</span>
                    </button>
                </div>
            </div>

            <div class="is-modal columnsettings">
                <div class="is-modal-content" style="width:370px;min-height:435px;padding: 0px;">
                    <div class="is-modal-bar is-draggable">
                        ${util.out('Column Settings')}
                        <div class="is-modal-close" role="button" tabindex="0" title="${util.out('Close')}">&#10005;</div>
                    </div>

                    <div style="padding:0;margin-top:35px;">

                        <div class="is-tabs clearfix" data-group="cellsettings">
                            <a title="${util.out('General')}" id="tabCellGeneral" href="" data-content="divCellGeneral" class="active">${util.out('General')}</a>
                            <a title="${util.out('Content')}" id="tabCellContent" href="" data-content="divCellContent">${util.out('Content')}</a>
                            ${this.builder.useLightbox?`
                            <a title="${util.out('On Click')}" id="tabCellClick" href="" data-content="divCellClick">${util.out('On Click')}</a>
                            `:''}
                        </div>
                        <div id="divCellGeneral" class="is-tab-content" data-group="cellsettings" style="display:flex">
                        
                            <div style="padding-bottom: 3px;">${util.out('Background Color')}:</div>
                            <div style="display:flex;">
                                <button title="${util.out('Background Color')}" class="input-cell-bgcolor is-btn-color" style="margin-right:15px"></button>
                                <button title="${util.out('Gradient')}" class="input-cell-gradient classic" data-value="+"> ${util.out('Gradient')} </button>
                            </div>

                            <div style="padding-top:20px;padding-bottom: 3px;">${util.out('Background Image')}:</div>
                            <div >
                                <div class="cell-bgimage-preview"></div>
                                <div style="display: flex">
                                    <button title="${util.out('Image')}" class="input-cell-bgimage">
                                        <svg class="is-icon-flex"><use xlink:href="#ion-image"></use></svg>
                                        <span>${util.out('Image')}</span>
                                    </button>
                                    <button title="${util.out('Browse')}" class="input-cell-browse">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><title>Folder</title><path d="M440 432H72a40 40 0 01-40-40V120a40 40 0 0140-40h75.89a40 40 0 0122.19 6.72l27.84 18.56a40 40 0 0022.19 6.72H440a40 40 0 0140 40v240a40 40 0 01-40 40zM32 192h448" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"/></svg>
                                        <span>${util.out('Browse')}</span>
                                    </button>
                                    <button title="${util.out('Adjust')}" class="input-cell-bgimageadjust"><svg class="is-icon-flex"><use xlink:href="#ion-wrench"></use></svg></button>
                                </div>
                            </div>

                            <div style="padding-top:20px;padding-bottom:3px;">${util.out('Text Color')}:</div>
                            <div class="div-content-textcolor">
                                <button title="0" class="input-cell-textcolor" data-command="dark">${util.out('Dark')}</button>
                                <button title="10" class="input-cell-textcolor" data-command="light">${util.out('Light')}</button>
                                
                                <button title="${util.out('Clear')}" class="input-cell-textcolor" data-command=""><svg class="is-icon-flex" style="flex:none;width:18px;height:18px;margin-top: 2px;"><use xlink:href="#ion-ios-close-empty"></use></svg></button>
                            </div>

                            <div style="display:none;padding-top:20px;padding-bottom:3px;">${util.out('Enlarge Row')}:</div>
                            <div style="display: none;">
                                <button title="${util.out('Normal')}" class="input-row-enlarge" data-command="0" style="margin-right:1px;">${util.out('Normal')}</button>
                                <button title="+10%" class="input-row-enlarge" data-command="10" style="width:50px;margin-right:1px;">+10%</button>
                                <button title="+20%" class="input-row-enlarge" data-command="20" style="width:50px;margin-right:1px;">+20%</button>
                                <button title="+30%" class="input-row-enlarge" data-command="30" style="width:50px;margin-right:1px;">+30%</button>
                                <button title="+40%" class="input-row-enlarge" data-command="40" style="width:50px;margin-right:1px;">+40%</button>
                                <button title="+50%" class="input-row-enlarge" data-command="50" style="width:50px;margin-right:1px;">+50%</button>
                                <button title="+60%" class="input-row-enlarge" data-command="60" style="width:50px;margin-right:1px;">+60%</button>
                            </div>

                        </div>

                        <div id="divCellContent" class="is-tab-content" data-group="cellsettings">

                            <div style="padding-bottom:3px;">${util.out('Padding')}:</div>
                            <div class="div-content-padding">
                                <button title="0" class="input-cell-padding" data-command="0">0</button>
                                <button title="10" class="input-cell-padding" data-command="10">10</button>
                                <button title="20" class="input-cell-padding" data-command="20">20</button>
                                <button title="30" class="input-cell-padding" data-command="30">30</button>
                                <button title="40" class="input-cell-padding" data-command="40">40</button>
                                <button title="50" class="input-cell-padding" data-command="50">50</button>
                                <button title="60" class="input-cell-padding" data-command="60">60</button>
                                <button title="${util.out('Clear')}" class="input-cell-padding" data-command=""><svg class="is-icon-flex" style="flex:none;width:18px;height:18px;margin-top: 2px;"><use xlink:href="#ion-ios-close-empty"></use></svg></button>
                            </div>

                            <div style="padding-top:20px;padding-bottom:3px;">${util.out('Height')}:</div>
                            <div class="div-content-height">
                                <button title="Decrease" class="input-cell-height" data-command="-">-</button>
                                <button title="Increase" class="input-cell-height" data-command="+">+</button>
                                <button title="300" class="input-cell-height" data-command="300">300</button>
                                <button title="400" class="input-cell-height" data-command="400">400</button>
                                <button title="500" class="input-cell-height" data-command="500">500</button>
                                <button title="600" class="input-cell-height" data-command="600">600</button>
                                <button title="700" class="input-cell-height" data-command="700">700</button>
                                <button title="${util.out('Clear')}" class="input-cell-height" data-command=""><svg class="is-icon-flex" style="flex:none;width:18px;height:18px;margin-top: 2px;"><use xlink:href="#ion-ios-close-empty"></use></svg></button>
                            </div>

                            <div style="padding-top:20px;padding-bottom:3px;">${util.out('Content Alignment')}:</div>
                            <div class="div-content-position">
                                <button class="cmd-content-pos" data-pos="topleft" title="${util.out('Top Left')}" type="button" style=""><svg class="is-icon-flex" style="transform: rotate(315deg);"><use xlink:href="#ion-ios-arrow-thin-up"></use></svg></button>
                                <button class="cmd-content-pos" data-pos="topcenter" title="${util.out('Top Center')}" type="button"><svg class="is-icon-flex" style=""><use xlink:href="#ion-ios-arrow-thin-up"></use></svg></button>
                                <button class="cmd-content-pos" data-pos="topright" topright="${util.out('Top Right')}" type="button"><svg class="is-icon-flex" style="transform: rotate(45deg);"><use xlink:href="#ion-ios-arrow-thin-up"></use></svg></button>
                            </div>
                            <div class="div-content-position">
                                <button class="cmd-content-pos" data-pos="centerleft" title="${util.out('Center Left')}" type="button"><svg class="is-icon-flex" style=""><use xlink:href="#ion-ios-arrow-thin-left"></use></svg></button>
                                <button class="cmd-content-pos" data-pos="center" title="${util.out('Center')}" type="button"><svg class="is-icon-flex" style="width:6px;height:6px;"><use xlink:href="#ion-android-contract"></use></svg></button>
                                <button class="cmd-content-pos" data-pos="centerright" title="${util.out('Center Right')}" type="button"><svg class="is-icon-flex" style=""><use xlink:href="#ion-ios-arrow-thin-right"></use></svg></button>
                            </div>
                            <div class="div-content-position">
                                <button class="cmd-content-pos" data-pos="bottomleft" title="${util.out('Bottom Left')}" type="button" style=";"><svg class="is-icon-flex" style="transform: rotate(45deg);"><use xlink:href="#ion-ios-arrow-thin-down"></use></svg></button>
                                <button class="cmd-content-pos" data-pos="bottomcenter" title="${util.out('Bottom Center')}" type="button"><svg class="is-icon-flex" style=""><use xlink:href="#ion-ios-arrow-thin-down"></use></svg></button>
                                <button class="cmd-content-pos" data-pos="bottomright" title="${util.out('Bottom Right')}" type="button"><svg class="is-icon-flex" style="transform: rotate(315deg);"><use xlink:href="#ion-ios-arrow-thin-down"></use></svg></button>
                            </div>

                        </div>

                        <div id="divCellClick" class="is-tab-content" data-group="cellsettings">

                            <label for="inpCellLinkSource" style="display:block">${util.out('Open')}:</label>
                            <div class="image-src">
                                <!--<div class="div-input-text">-->
                                <input id="inpCellLinkSource" class="input-src" type="text">
                                <!--<button title="${util.out('Clear')}" class="input-clear"><svg class="is-icon-flex"><use xlink:href="#ion-ios-close-empty"></use></svg></button>
                                </div>-->
                                <button title="${util.out('Select')}" class="input-select" style="flex:none;width:50px;height:50px;"><svg class="is-icon-flex"><use xlink:href="#ion-more"></use></svg></button>
                                <div class="image-larger4" style="position: relative; flex: 0 0 auto; width: 50px; height: 50px; box-shadow: rgba(0, 0, 0, 0.32) 0px 3px 6px -6px;">
                                    <form class="form-upload-larger" target="frameTargetLinkUpload" method="post" action="" enctype="multipart/form-data" style="position:absolute;top:0;left:0;width:100%;height:100%;">
                                        <input id="hidRefId4" name="hidRefId" type="hidden" value="">
                                        <svg class="is-icon-flex" style="position: absolute;top: 16px;left: 15px;width: 18px;height: 18px;"><use xlink:href="#ion-image"></use></svg>
                                        <input title="${util.out('Select')}" id="fileImage4" name="fileImage" type="file" accept="image/*" style="position:absolute;top:-30px;left:0;width:100%;height:80px;opacity: 0;cursor: pointer;">
                                    </form>

                                    <iframe id="frameTargetLinkUpload" name="frameTargetLinkUpload" src="about:blank" style="width:1px;height:1px;position:absolute;top:0;right:-100000px"></iframe>
                                </div>
                            </div>

                            <p>${util.out('To try the On-Click action, please disable column editing by clicking the lock button')} <svg class="is-icon-flex"><use xlink:href="#icon-lock"></use></svg>.</p>

                        </div>
                    
                    </div>
                        
                </div>
            </div>

            <div class="is-modal imageadjust">
                <div class="is-modal-content" style="max-width:420px;">
                    <div class="is-modal-bar is-draggable">
                        ${util.out('Background Image Adjustments')}
                        <div class="is-modal-close" role="button" tabindex="0" title="${util.out('Close')}">&#10005;</div>
                    </div>

                    <div style="padding:0;margin-top:25px;">

                        <div style="padding-bottom:3px;">${util.out('Scale')}:</div>
                        <div style="padding-top:4px">
                            <input type="range" min="100" max="500" value="0" class="image-scale-slider is-rangeslider">
                        </div>

                        <div style="padding-bottom:3px;padding-top: 20px;">${util.out('Horizontal')}:</div>
                        <div style="padding-top:4px">
                            <input type="range" min="0" max="100" value="0" class="image-hor-slider is-rangeslider">
                        </div>

                        <div style="padding-bottom:3px;padding-top: 20px;">${util.out('Vertical')}:</div>
                        <div style="padding-top:4px">
                            <input type="range" min="0" max="100" value="0" class="image-vert-slider is-rangeslider">
                        </div>

                    </div>

                </div>
            </div> 


            <div class="is-modal imagesource">
                <div class="is-modal-content" style="max-width:550px;">

                    <label for="inpCellImageBgSource" style="display:block">${util.out('Source')}:</label>
                    <div class="image-src">
                        <input id="inpCellImageBgSource" class="input-src" type="text">
                        <button title="${util.out('Select')}" class="input-select" style="flex:none;width:50px;height:50px;"><svg class="is-icon-flex"><use xlink:href="#ion-more"></use></svg></button>
                        <div class="image-larger3" style="position: relative; flex: 0 0 auto; width: 50px; height: 50px; box-shadow: rgba(0, 0, 0, 0.32) 0px 3px 6px -6px;">
                            <form class="form-upload-larger" target="frameTargetCellImageUpload" method="post" action="" enctype="multipart/form-data" style="position:absolute;top:0;left:0;width:100%;height:100%;">
                                <input id="hidRefId3" name="hidRefId" type="hidden" value="">
                                <svg class="is-icon-flex" style="position: absolute;top: 16px;left: 15px;width: 18px;height: 18px;"><use xlink:href="#ion-image"></use></svg>
                                <input title="${util.out('Select')}" id="fileImage3" name="fileImage" type="file" accept="image/*" style="position:absolute;top:-30px;left:0;width:100%;height:80px;opacity: 0;cursor: pointer;">
                            </form>

                            <iframe id="frameTargetCellImageUpload" name="frameTargetCellImageUpload" src="about:blank" style="width:1px;height:1px;position:absolute;top:0;right:-100000px"></iframe>
                        </div>
                    </div>

                    <div style="text-align:right;margin-top:20px;">
                        <button title="${util.out('Cancel')}" class="input-cancel classic-secondary">${util.out('Cancel')}</button>
                        <button title="${util.out('Ok')}" class="input-ok classic-primary">${util.out('Ok')}</button>
                    </div>

                </div>
            </div>

            <div class="is-locked-indicator">
                <svg class="is-icon-flex"><use xlink:href="#icon-lock"></use></svg>
            </div>
            `;

            dom.appendHtml(builderStuff, html);
            columnTool = builderStuff.querySelector('.is-column-tool');
            columnMore = builderStuff.querySelector('.columnmore');

            this.lockIndicator = builderStuff.querySelector('.is-locked-indicator');

            this.cellSettings = builderStuff.querySelector('.is-modal.columnsettings');

            const quickadd = renderQuickAdd(builder);

            // Prepare for tooltip
            let elms = columnTool.querySelectorAll('[title]');
            Array.prototype.forEach.call(elms, (elm) => {
                elm.setAttribute('data-title',elm.getAttribute('title'));
            });
            // elms = columnMore.querySelectorAll('[title]');
            // Array.prototype.forEach.call(elms, (elm) => {
            //     elm.setAttribute('data-title',elm.getAttribute('title'));
            // });

            // Add column
            let elm = columnTool.querySelector('.cell-add');
            dom.addEventListener(elm, 'click', () => {

                let cell = util.cellSelected();
                if(!cell) return; 

                let tabs = quickadd.querySelector('.is-pop-tabs');
                tabs.style.display = 'flex';

                const elm = columnTool.querySelector('.cell-add');
                const top = elm.getBoundingClientRect().top + window.pageYOffset;
                const left = elm.getBoundingClientRect().left + window.pageXOffset;
                quickadd.style.display = 'flex';
                //const w = quickadd.offsetWidth; //to get value, element must not hidden (display:none). So set display:flex before this.
                //const h = quickadd.offsetHeight;
                quickadd.style.top = (top + 35) + 'px';
                quickadd.style.left = (left) + 'px';

                dom.removeClass(quickadd,'arrow-bottom');
                dom.removeClass(quickadd,'arrow-left');
                dom.removeClass(quickadd,'arrow-right');
                dom.removeClass(quickadd,'center');
                dom.removeClass(quickadd,'right');
                dom.addClass(quickadd,'arrow-top');
                dom.addClass(quickadd,'left');

                let val = quickadd.querySelector('.active').getAttribute('data-value');
                if(val==='left') {
                    quickadd.setAttribute('data-mode', 'cell-left');
                } else {
                    quickadd.setAttribute('data-mode', 'cell-right');
                }
            });

            // More
            elm = columnTool.querySelector('.cell-more');
            dom.addEventListener(elm, 'click', () => {

                let cell = util.cellSelected();
                if(!cell) return;

                const elm = columnTool.querySelector('.cell-more');
                const top = elm.getBoundingClientRect().top + window.pageYOffset;
                const left = elm.getBoundingClientRect().left + window.pageXOffset;
                columnMore.style.display = 'flex';
                //const w = columnMore.offsetWidth; //to get value, element must not hidden (display:none). So set display:flex before this.
                //const h = columnMore.offsetHeight;
                columnMore.style.top = (top + 35) + 'px';
                columnMore.style.left = (left - 7) + 'px';

                dom.removeClass(columnMore,'arrow-bottom');
                dom.removeClass(columnMore,'arrow-left');
                dom.removeClass(columnMore,'arrow-right');
                dom.removeClass(columnMore,'center');
                dom.removeClass(columnMore,'right');
                dom.addClass(columnMore,'arrow-top');
                dom.addClass(columnMore,'left');

                const btnCellLocking = this.columnMore.querySelector('.cell-locking');
                if(cell.hasAttribute('data-noedit')) {
                    dom.addClass(btnCellLocking, 'on');
                    // btnCellLocking.innerHTML = '<svg class="is-icon-flex"><use xlink:href="#icon-lock"></use></svg>';
                } else {
                    dom.removeClass(btnCellLocking, 'on');
                    // btnCellLocking.innerHTML = '<svg class="is-icon-flex"><use xlink:href="#icon-lock-off"></use></svg>';
                }

            });

            // Delete column
            elm = columnTool.querySelector('.cell-remove');
            dom.addEventListener(elm, 'click', () => {

                this.grid.removeColumn();
                
                util.clearControls();

            });

            // Move Previous
            elm = columnMore.querySelector('.cell-prev');
            dom.addEventListener(elm, 'click', () => {

                this.grid.moveColumnPrevious();
                
                util.clearControls();

            });

            // Move Next
            elm = columnMore.querySelector('.cell-next');
            dom.addEventListener(elm, 'click', () => {
                
                this.grid.moveColumnNext();
                
                util.clearControls();
                
            });

            // Move Up
            elm = columnMore.querySelector('.cell-up');
            dom.addEventListener(elm, 'click', () => {

                this.grid.moveColumnUp();
                
                util.clearControls();

            });

            // Move Down
            elm = columnMore.querySelector('.cell-down');
            dom.addEventListener(elm, 'click', () => {
                
                this.grid.moveColumnDown();
                
                util.clearControls();
   
            });

            // Increase
            elm = columnMore.querySelector('.cell-increase');
            dom.addEventListener(elm, 'click', () => {
                
                this.grid.increaseColumn();
                
                util.clearControls();
   
            });

            // Decrease
            elm = columnMore.querySelector('.cell-decrease');
            dom.addEventListener(elm, 'click', () => {
                
                this.grid.decreaseColumn();
                
                util.clearControls();
   
            });

            // Duplicate
            elm = columnMore.querySelector('.cell-duplicate');
            dom.addEventListener(elm, 'click', () => {
                
                this.grid.duplicateColumn();
                this.columnMore.style.display = '';
                
                util.clearControls();
                
            });

            // View HTML
            elm = columnMore.querySelector('.cell-html');
            if(elm) dom.addEventListener(elm, 'click', () => {

                let cell = util.cellSelected();
                if(!cell) return;
                
                const htmlutil = new HtmlUtil(this.builder);
                htmlutil.view('cell');
   
            });

            document.addEventListener('mousedown', (e) => {
                e = e || window.event;
                var target = e.target || e.srcElement;  

                if(columnMore.style.display==='flex') {
                    let a = dom.parentsHasClass(target, 'columnmore');
                    let b = dom.parentsHasClass(target, 'cell-more');
                    if(a||b) {
                        return;
                    }
                    else {
                        columnMore.style.display = '';
                    }
                }

            });


            // Column Settings

            const cellSettings = document.querySelector('.is-modal.columnsettings');
            const imageSource = document.querySelector('.is-modal.imagesource');
            const imageAdjust = document.querySelector('.is-modal.imageadjust');
            this.cellSettings = cellSettings;
            this.imageSource = imageSource;
            this.imageAdjust = imageAdjust;
            
            // Lock/Unlock Column
            const btnCellLocking = columnMore.querySelector('.cell-locking');
            if(btnCellLocking) dom.addEventListener(btnCellLocking, 'click', (e) => {

                let cell = util.cellSelected();
                if(!cell) return;

                if(!cell.hasAttribute('data-noedit')) {

                    cell.setAttribute('data-noedit','');

                    cell.contentEditable = false;

                    dom.addClass(btnCellLocking, 'on');

                    this.util.clearActiveElement(true);

                } else {

                    cell.removeAttribute('data-noedit');

                    cell.contentEditable = true;

                    dom.removeClass(btnCellLocking, 'on');

                }

                this.showHideLockIndicator(cell);
                this.builder.element.applyBehavior(cell);

                e.preventDefault();
            });

            // Open Column Settings
            elm = columnMore.querySelector('.cell-settings');
            if(elm) dom.addEventListener(elm, 'click', () => {

                let cell = util.cellSelected();
                if(!cell) return;

                this.readCellStyles(cell);

                //save selection
                util.saveSelection();
                
                util.showModal(cellSettings, false, ()=>{

                    let rtetool = builderStuff.querySelector('.is-rte-tool');
                    if(rtetool.style.display === 'flex') { // means text selection

                        util.restoreSelection();

                        // if(this.builder.activeElement) {
                        //     dom.addClass(this.builder.activeElement, 'elm-active');
                        //     this.builder.elmTool.repositionElementTool();
                        // }
                    }

                });

                this.columnMore.style.display = '';
                
            });

            const btnClose = cellSettings.querySelector('.is-modal-close')
            btnClose.addEventListener('click', ()=> 
                (
                    util.hideModal(cellSettings),
                    builderStuff.querySelector('.insertimage')?.classList.remove( 'background-image--related' )))

            
            // Column Background color
            let btnCellBgColor = cellSettings.querySelector('.input-cell-bgcolor');
            btnCellBgColor.addEventListener('click', (e) => {

                this.builder.uo.saveForUndo(true); // checkLater = true

                let elm = e.target;
                this.builder.colorPicker.open((color)=>{


                    let cell = util.cellSelected();
                    cell.style.backgroundColor = color;
                    
                    elm.style.backgroundColor = color; // preview
            
                    //Trigger Change event
                    this.builder.opts.onChange();

                }, cellSettings.querySelector('.input-cell-bgcolor').style.backgroundColor);
            });

            // Column Background Image
            elm = cellSettings.querySelector('.input-cell-bgimage');
            if(elm) dom.addEventListener(elm, 'click', () => {

                util.showModal(imageSource, false);
   
            });






            
            // when builder tools are all loaded
            builderStuff.addEventListener( TOOLS_MOUNTED_CUSTOM_EVENT, ()=> {


                // new feature requested
                // attach click to browse button
                const browseElement = cellSettings.querySelector( '.input-cell-browse' )
                !browseElement ?
                    null:
                    dom.addEventListener(browseElement, 'click', ()=> {
                        
                        const modal = builderStuff.querySelector('.insertimage')
                        util.showModal(modal, !0)

                        modal.classList?.add( 'background-image--related' )


                        //Clear previous
                        modal.querySelector('#fileInsertImage').value = ''
                        modal.querySelector('.is-preview-area').style.display = 'none'
                        modal.querySelector('.is-drop-area').style.display = 'block'    
                        dom.removeClass(modal.querySelector('.is-drop-area'), 'image-dropping')

                        //Clear image source input
                        modal.querySelector('.input-src').value = ''
                        
                    })

                const modalInsertImage = builderStuff.querySelector('.insertimage')
                const fileInsertImage = modalInsertImage?.querySelector('#fileInsertImage')


                // 1. listen to insert image 'change' event
                !fileInsertImage ? 
                null:
                dom.addEventListener(fileInsertImage, 'change', (e) => {

                    const { result, files } = e.target || {}
                    const resolvedFiles = files || []

                    if ( !resolvedFiles?.length ) return 


                    let reader = new FileReader()

                    reader.onload = ()=> {

                        modalInsertImage.querySelector('.is-drop-area').style.display = 'none'

                        modalInsertImage.querySelector('#imgInsertImagePreview').src = result
                        modalInsertImage.querySelector('.is-preview-area').style.display = 'block'

                        modalInsertImage.querySelector('#imgInsertImagePreview').setAttribute('data-filename', resolvedFiles[0].name)

                    }

                    reader.readAsDataURL(resolvedFiles[0])
                
                    return modalInsertImage.querySelector('.input-src').value = ''

                })

    
                // 2. listen to insertImage modal 'ok' click event
                const btnInsertImageOk = modalInsertImage?.querySelector('.input-ok')

                !btnInsertImageOk ? 
                    null:
                    dom.addEventListener(btnInsertImageOk, 'click', () => {

                        if ( !this.builder.activeCol ) return util.hideModal(modalInsertImage)

                        this.builder.uo.saveForUndo()

                        util.restoreSelection()

                        // check where to get the image from
                        const val = modalInsertImage.querySelector('.is-drop-area').style.display === 'none' ? 
                            modalInsertImage.querySelector('#imgInsertImagePreview').src:
                            modalInsertImage.querySelector('.input-src').value

                        const valueIsUrl = urlRegex.test(val)
                            
                        if ( !val || val === '' ) return
                        
                        const updateImageByUrl = resolveImageUrl=> {

                            this.builder.uo.saveForUndo()

                            // change cell background image (editor level)
                            changeCellBackgroundImageByUrl(util.cellSelected(), resolveImageUrl)

                            // change preview background image (column-settings level) (modal)
                            !cellSettings ? 
                                null:
                                changeColumnSettingsBgImage(cellSettings, resolveImageUrl)

                                                                    
                            util.hideModal(modalInsertImage)
                                            
                            this.builder.applyBehavior()

                            //save selection
                            util.saveSelection()

                            //Trigger Change event
                            this.builder.opts.onChange()

                            //Trigger Render event
                            this.builder.opts.onRender()
                            
                        }

                        const saveImageRemotely = this.builder.opts?.onSaveBase64ImageRemotely

                        // double check if the extracted value is a url
                        return valueIsUrl ?
                            updateImageByUrl(val):
                            saveImageRemotely(val, updateImageByUrl)

                    })


                // 3. listen to insertImage modal 'cancel' click event
                const btnInsertImageCancel = modalInsertImage?.querySelector('.input-cancel')
                !btnInsertImageCancel ? 
                    null:
                    dom.addEventListener(btnInsertImageCancel, 'click', () => (
                        util.hideModal(modalInsertImage),
                        modalInsertImage.classList?.remove( 'background-image--related' )))
                        
            })
            























            // Column Background Image Adjust
            elm = cellSettings.querySelector('.input-cell-bgimageadjust');
            if(elm) dom.addEventListener(elm, 'click', () => {

                util.showModal(imageAdjust, false);
   
            });

            const btnAdjustClose = imageAdjust.querySelector('.is-modal-close');
            btnAdjustClose.addEventListener('click', ()=>{
                util.hideModal(imageAdjust);
            });
            
            // Column Background Image Select Dialog (.is-modal.imagesource) 

            if(this.builder.opts.largerImageHandler==='' && !this.builder.opts.onLargerImageUpload) {
                imageSource.querySelector('.image-larger3').style.display = 'none';
            }

            if (!this.builder.opts.onImageSelectClick && this.builder.opts.imageselect==='' ) {
                imageSource.querySelector('.input-select').style.display = 'none';
            }

            // Select image (opens Asset Manager plugin or custom dialog)
            let divImageSrc = imageSource.querySelector('.image-src');
            if (this.builder.opts.onImageSelectClick || this.builder.opts.imageselect ) {
  
                elm = imageSource.querySelector('.input-select');
                if(elm) dom.addEventListener(elm, 'click', () => {

                    if(this.builder.opts.onImageSelectClick) {
                                   
                        this.builder.opts.onImageSelectClick({targetInput: imageSource.querySelector('.input-src'), theTrigger: elm});

                    } else {

                        let modalImageSelect = builderStuff.querySelector('.is-modal.imageselect');
                        let iframe = modalImageSelect.querySelector('iframe');
                        if(iframe.src === 'about:blank'){
                            iframe.src = this.builder.opts.imageselect;
                        }
                        util.showModal(modalImageSelect);

                        this.builder.selectFileTarget = 'columnsettings-bg'; // set target for value set (see selectImage() in contentbuilder.js)

                    }

                }); 

            } else {
                dom.removeClass(divImageSrc, 'image-select');
            }

            let fileLargerImage = imageSource.querySelector('#fileImage3');
            dom.addEventListener(fileLargerImage, 'change', (e) => {

                let element = fileLargerImage;
                while(element.nodeName.toLowerCase() !== 'form') {
                    element = element.parentNode;
                }
                let frmUpload = element;
            
                dom.addClass(frmUpload, 'please-wait');

                imageSource.querySelector('#hidRefId3').value = this.builder.opts.customval;

                // frmUpload.submit();

                if(this.builder.opts.onLargerImageUpload) {

                    this.builder.opts.onLargerImageUpload(e);
                    
                } else {
                    
                    frmUpload.submit();

                }
            });

            const btnImageOk = imageSource.querySelector('.input-ok');
            dom.addEventListener(btnImageOk, 'click', () => {

                let cell = util.cellSelected()

                const { isColumnBackgroundImage } = queryCellBackgroundImage(cell)
                const elementHoldingBg = !isColumnBackgroundImage ? 
                    cell:
                    cell.querySelector( '.column[style]' )

                if ( !cell ) return

                this.builder.uo.saveForUndo()

                const inputSrc = imageSource.querySelector('.input-src');
                elementHoldingBg.style.backgroundImage = `url("${inputSrc.value}")`;
                elementHoldingBg.style.backgroundSize = 'cover';
                elementHoldingBg.style.backgroundRepeat = 'no-repeat';

                const div = cellSettings.querySelector('.cell-bgimage-preview');
                const btnImageAdjust = this.cellSettings.querySelector('.input-cell-bgimageadjust');
                btnImageAdjust.style.display = 'none';
                if(inputSrc.value!=='') {
                    div.innerHTML = `<img src="${inputSrc.value}">`;

                    btnImageAdjust.style.display = 'flex';
                } else {
                    div.innerHTML = '';
                }

                util.hideModal(imageSource);
                                
                //Trigger Change event
                this.builder.opts.onChange();

            });

            const btnImageCancel = imageSource.querySelector('.input-cancel');
            dom.addEventListener(btnImageCancel, 'click', () => {
                        
                util.hideModal(imageSource);

            });

            // Scale
            const inpImageScaleSlider = imageAdjust.querySelector('.image-scale-slider');
            inpImageScaleSlider.onfocus = () => {

                this.builder.uo.saveForUndo(true); // checkLater = true

                inpImageScaleSlider.blur();

            };
            inpImageScaleSlider.oninput = () => {

                //val: 100 - 500
                let val = inpImageScaleSlider.value;
                
                let cell = util.cellSelected();
                cell.style.backgroundSize = val + '%';
    
            };        
            inpImageScaleSlider.onchange = () => {

                //Trigger Change event
                this.builder.opts.onChange();

            };

            // Horizontal
            const inpImageHorSlider = imageAdjust.querySelector('.image-hor-slider');
            inpImageHorSlider.onfocus = () => {

                this.builder.uo.saveForUndo(true); // checkLater = true

                inpImageHorSlider.blur();

            };
            inpImageHorSlider.oninput = () => {

                //val: 0 - 100
                let val = inpImageHorSlider.value;
                
                let cell = util.cellSelected();
                cell.style.backgroundPositionX = val + '%';
    
            };        
            inpImageHorSlider.onchange = () => {

                //Trigger Change event
                this.builder.opts.onChange();
                
            };

            // Vertical
            const inpImageVertSlider = imageAdjust.querySelector('.image-vert-slider');
            inpImageVertSlider.onfocus = () => {

                this.builder.uo.saveForUndo(true); // checkLater = true

                inpImageVertSlider.blur();

            };
            inpImageVertSlider.oninput = () => {

                //val: 0 - 100
                let val = inpImageVertSlider.value;
                
                let cell = util.cellSelected();
                cell.style.backgroundPositionY = val + '%';
    
            };        
            inpImageVertSlider.onchange = () => {

                //Trigger Change event
                this.builder.opts.onChange();
                
            };

            // Row Enlarge (Currently not used)
            elms = cellSettings.querySelectorAll('.input-row-enlarge');
            Array.prototype.forEach.call(elms, (elm) => {

                dom.addEventListener(elm, 'click', () => {

                    this.builder.uo.saveForUndo();
                        
                    let val = elm.getAttribute('data-command');

                    let cell = util.cellSelected();
                    let row = cell.parentNode;

                    dom.removeClass(row, 'expand-10');
                    dom.removeClass(row, 'expand-20');
                    dom.removeClass(row, 'expand-30');
                    dom.removeClass(row, 'expand-40');
                    dom.removeClass(row, 'expand-50');
                    dom.removeClass(row, 'expand-60');
                    dom.addClass(row, 'expand-' + val);
                                
                    //Trigger Change event
                    this.builder.opts.onChange();
    
                });
                
            });

            // Background gradient
            const gradientPicker = new GradientPicker({
                colors: this.builder.colors,
                gradientcolors: this.builder.opts.gradientcolors,
                lang: this.builder.opts.lang
            });
            
            let btnCellGradient = cellSettings.querySelector('.input-cell-gradient');
            btnCellGradient.addEventListener('click', () => {
                
                this.builder.uo.saveForUndo(true); // checkLater = true

                let cell = util.cellSelected();

                gradientPicker.open(cell, ()=>{

                    const btnImageAdjust = this.cellSettings.querySelector('.input-cell-bgimageadjust');
                    btnImageAdjust.style.display = 'none';

                    const div = this.cellSettings.querySelector('.cell-bgimage-preview');
                    div.innerHTML = '';

                    const inpSrc = this.imageSource.querySelector('.input-src');
                    inpSrc.value = '';
            
                    //Trigger Change event
                    this.builder.opts.onChange();

                }, (isChanged) => {
                    
                    if(isChanged) {
                        
                        // Do Nothing

                    } 
                });
            });

            elms = cellSettings.querySelectorAll('.input-cell-textcolor');
            Array.prototype.forEach.call(elms, (elm) => {

                dom.addEventListener(elm, 'click', () => {

                    this.builder.uo.saveForUndo();
                        
                    let val = elm.getAttribute('data-command');

                    let cell = util.cellSelected();

                    if(val==='dark') {
                        dom.removeClass(cell, 'is-light-text');
                        dom.addClass(cell, 'is-dark-text');
                    } else if(val === 'light') {
                        dom.removeClass(cell, 'is-dark-text');
                        dom.addClass(cell, 'is-light-text');
                    } else {
                        dom.removeClass(cell, 'is-dark-text');
                        dom.removeClass(cell, 'is-light-text');
                    }

                    let ns = cell.querySelectorAll('*');
                    Array.prototype.forEach.call(ns, (n) => {
                        if(n.style.color) n.style.color = '';
                    });
            
                    //Trigger Change event
                    this.builder.opts.onChange();
    
                });
                
            });

            elms = cellSettings.querySelectorAll('.input-cell-padding');
            Array.prototype.forEach.call(elms, (elm) => {

                dom.addEventListener(elm, 'click', () => {

                    this.builder.uo.saveForUndo();
                        
                    let val = elm.getAttribute('data-command');

                    let cell = util.cellSelected();

                    let ns = this.cellSettings.querySelectorAll('.input-cell-padding');
                    Array.prototype.forEach.call(ns, (n) => {
                        dom.removeClass(n, 'on');
                    });
                    dom.removeClass(cell, 'padding-0');
                    dom.removeClass(cell, 'padding-10');
                    dom.removeClass(cell, 'padding-20');
                    dom.removeClass(cell, 'padding-30');
                    dom.removeClass(cell, 'padding-40');
                    dom.removeClass(cell, 'padding-50');
                    dom.removeClass(cell, 'padding-60');
                    if(val!=='') {
                        dom.addClass(cell, 'padding-' + val);
                        dom.addClass(elm, 'on');
                    }
            
                    //Trigger Change event
                    this.builder.opts.onChange();
    
                });
                
            });

            elms = cellSettings.querySelectorAll('.input-cell-height');
            Array.prototype.forEach.call(elms, (elm) => {

                dom.addEventListener(elm, 'click', () => {

                    let arrHeight = this.builder.colHeight;

                    this.builder.uo.saveForUndo();
                        
                    let val = elm.getAttribute('data-command');

                    let cell = util.cellSelected();

                    let ns = this.cellSettings.querySelectorAll('.input-cell-height');
                    Array.prototype.forEach.call(ns, (n) => {
                        dom.removeClass(n, 'on');
                    });

                    if(val==='') {

                        Array.prototype.forEach.call(arrHeight, (h) => {
                            dom.removeClass(cell,'min-height-' + h);
                        });

                    } else if(val === '-') {

                        let offHeight = cell.offsetHeight;
                        Array.prototype.forEach.call(arrHeight, (h) => {
                            if(offHeight-h>0 && offHeight-h<=50) {

                                Array.prototype.forEach.call(arrHeight, (h) => {
                                    dom.removeClass(cell,'min-height-' + h);
                                });

                                dom.addClass(cell, 'min-height-' + h);

                                if(h===300) {
                                    let btn = this.cellSettings.querySelector('.input-cell-height[data-command="300"');
                                    dom.addClass(btn, 'on');
                                } else if(h===400) {
                                    let btn = this.cellSettings.querySelector('.input-cell-height[data-command="400"');
                                    dom.addClass(btn, 'on');
                                } else if(h===500) {
                                    let btn = this.cellSettings.querySelector('.input-cell-height[data-command="500"');
                                    dom.addClass(btn, 'on');
                                } else if(h===600) {
                                    let btn = this.cellSettings.querySelector('.input-cell-height[data-command="600"');
                                    dom.addClass(btn, 'on');
                                } else if(h===700) {
                                    let btn = this.cellSettings.querySelector('.input-cell-height[data-command="700"');
                                    dom.addClass(btn, 'on');
                                }

                            }
                        });

                    } else if(val === '+') {

                        let offHeight = cell.offsetHeight;
                        Array.prototype.forEach.call(arrHeight, (h) => {
                            if(h-offHeight>0 && h-offHeight<=50) {

                                Array.prototype.forEach.call(arrHeight, (h) => {
                                    dom.removeClass(cell,'min-height-' + h);
                                });

                                dom.addClass(cell, 'min-height-' + h);

                                if(h===300) {
                                    let btn = this.cellSettings.querySelector('.input-cell-height[data-command="300"');
                                    dom.addClass(btn, 'on');
                                } else if(h===400) {
                                    let btn = this.cellSettings.querySelector('.input-cell-height[data-command="400"');
                                    dom.addClass(btn, 'on');
                                } else if(h===500) {
                                    let btn = this.cellSettings.querySelector('.input-cell-height[data-command="500"');
                                    dom.addClass(btn, 'on');
                                } else if(h===600) {
                                    let btn = this.cellSettings.querySelector('.input-cell-height[data-command="600"');
                                    dom.addClass(btn, 'on');
                                } else if(h===700) {
                                    let btn = this.cellSettings.querySelector('.input-cell-height[data-command="700"');
                                    dom.addClass(btn, 'on');
                                }

                            }
                        });

                    } else {

                        Array.prototype.forEach.call(arrHeight, (h) => {
                            dom.removeClass(cell,'min-height-' + h);
                        });
                        
                        dom.addClass(cell,'min-height-' + val);
                        dom.addClass(elm,'on');
                    }
            
                    //Trigger Change event
                    this.builder.opts.onChange();
    
                });
                
            });


            elms = cellSettings.querySelectorAll('.cmd-content-pos');
            Array.prototype.forEach.call(elms, (elm) => {

                dom.addEventListener(elm, 'click', () => {

                    this.builder.uo.saveForUndo();
                        
                    let val = elm.getAttribute('data-pos');

                    let cell = util.cellSelected();

                    cell.style.display = 'flex';
                    cell.style.flexDirection = 'column';
                  
                    let ns = this.cellSettings.querySelectorAll('.cmd-content-pos');
                    Array.prototype.forEach.call(ns, (n) => {
                        dom.removeClass(n, 'on');
                    });
                    if(val==='topleft') {
                        cell.style.justifyContent = 'flex-start';
                        cell.style.alignItems = 'flex-start';
                        dom.addClass(elm,'on');
                    }
                    if(val==='topcenter') {
                        cell.style.justifyContent = 'flex-start';
                        cell.style.alignItems = 'center';
                        dom.addClass(elm,'on');
                    }
                    if(val==='topright') {
                        cell.style.justifyContent = 'flex-start';
                        cell.style.alignItems = 'flex-end';
                        dom.addClass(elm,'on');
                    }
                    if(val==='centerleft') {
                        cell.style.justifyContent = 'center';
                        cell.style.alignItems = 'flex-start';
                        dom.addClass(elm,'on');
                    }
                    if(val==='center') {
                        cell.style.justifyContent = 'center';
                        cell.style.alignItems = 'center';
                        dom.addClass(elm,'on');
                    }
                    if(val==='centerright') {
                        cell.style.justifyContent = 'center';
                        cell.style.alignItems = 'flex-end';
                        dom.addClass(elm,'on');
                    }
                    if(val==='bottomleft') {
                        cell.style.justifyContent = 'flex-end';
                        cell.style.alignItems = 'flex-start';
                        dom.addClass(elm,'on');
                    }
                    if(val==='bottomcenter') {
                        cell.style.justifyContent = 'flex-end';
                        cell.style.alignItems = 'center';
                        dom.addClass(elm,'on');
                    }
                    if(val==='bottomright') {
                        cell.style.justifyContent = 'flex-end';
                        cell.style.alignItems = 'flex-end';
                        dom.addClass(elm,'on');
                    }
            
                    //Trigger Change event
                    this.builder.opts.onChange();
    
                });
                
            });


            // Click to Open Image Select Dialog (.is-modal.columnsettings)

            if(this.builder.opts.largerImageHandler==='' && !this.builder.opts.onLargerImageUpload) {
                this.cellSettings.querySelector('.image-larger4').style.display = 'none';
            }

            if (!this.builder.opts.onImageSelectClick && this.builder.opts.imageselect==='' ) {
                this.cellSettings.querySelector('.input-select').style.display = 'none';
            }

        
            // Select image (opens Asset Manager plugin or custom dialog)
            if (this.builder.opts.onImageSelectClick || this.builder.opts.imageselect ) {
  
                elm = this.cellSettings.querySelector('.input-select');
                if(elm) dom.addEventListener(elm, 'click', () => {

                    if(this.builder.opts.onImageSelectClick) {
                                   
                        this.builder.opts.onImageSelectClick({targetInput: this.cellSettings.querySelector('.input-src'), theTrigger: elm});

                    } else {

                        let modalImageSelect = builderStuff.querySelector('.is-modal.imageselect');
                        let iframe = modalImageSelect.querySelector('iframe');
                        if(iframe.src === 'about:blank'){
                            iframe.src = this.builder.opts.imageselect;
                        }
                        util.showModal(modalImageSelect);

                        this.builder.selectFileTarget = 'columnsettings-click'; // set target for value set (see selectImage() in contentbuilder.js)

                    }

                }); 

            } 

            let fileLargerImage2 = this.cellSettings.querySelector('#fileImage4');
            dom.addEventListener(fileLargerImage2, 'change', (e) => {

                let element = fileLargerImage2;
                while(element.nodeName.toLowerCase() !== 'form') {
                    element = element.parentNode;
                }
                let frmUpload = element;
            
                dom.addClass(frmUpload, 'please-wait');

                this.cellSettings.querySelector('#hidRefId4').value = this.builder.opts.customval;

                // frmUpload.submit();

                if(this.builder.opts.onLargerImageUpload) {

                    this.builder.opts.onLargerImageUpload(e);
                    
                } else {
                    
                    frmUpload.submit();

                }
            });

            const inpClickSrc = this.cellSettings.querySelector('.input-src');
            inpClickSrc.addEventListener('focus', ()=>{

                this.builder.uo.saveForUndo(true); // checkLater = true

            });
            // this.util.attachInputTextClear(inpClickSrc);

            inpClickSrc.addEventListener('keyup', ()=>{

                let cell = this.util.cellSelected();
                if(!cell) return;

                if(inpClickSrc.value==='') {
                    // Remove click
                    dom.removeClass(cell, 'block-click');
                    cell.removeAttribute('data-modal-url');
                } else {
                    dom.addClass(cell, 'block-click');
                    cell.setAttribute('data-modal-url', inpClickSrc.value);
                }

                //Trigger Change event
                this.builder.opts.onChange();
                
            });

        }
        
        this.columnTool = columnTool;
        this.columnMore = columnMore;
        
    }

    applyClick() {
        let cell = this.util.cellSelected();
        if(!cell) return;

        this.builder.uo.saveForUndo();

        const inpSrc = this.cellSettings.querySelector('.input-src');
        dom.addClass(cell, 'block-click');
        cell.setAttribute('data-modal-url', inpSrc.value);

        //Trigger Change event
        this.builder.opts.onChange();
    }

    readCellStyles(cell) {

        this.util.clearActiveElement();

        // Background color
        let s = cell.style.backgroundColor;
        let btn = this.cellSettings.querySelector('.input-cell-bgcolor');  
        if(s) btn.style.backgroundColor = s;
        else btn.style.backgroundColor = 'transparent';   
        
        let imgUrl = '';
        const div = this.cellSettings.querySelector('.cell-bgimage-preview');
        const btnImageAdjust = this.cellSettings.querySelector('.input-cell-bgimageadjust');
        btnImageAdjust.style.display = 'none';

        // refactored in util
        const { backgroundImage: resolvedBackgroundImage } = queryCellBackgroundImage(cell)

        if( resolvedBackgroundImage ) {

            if( !!~resolvedBackgroundImage.indexOf('url(') ) {

                imgUrl = resolvedBackgroundImage.slice(4, -1).replace(/["']/g, '');
            
                div.innerHTML = `<img src="${imgUrl}">`;
                btnImageAdjust.style.display = 'flex';
            } else {
                div.innerHTML = '';
            }
        } else {
            div.innerHTML = '';
        }

        const inpSrc = this.imageSource.querySelector('.input-src');
        inpSrc.value = imgUrl;

        const inpClickSrc = this.cellSettings.querySelector('.input-src');
        inpClickSrc.value = '';
        let clickUrl = cell.getAttribute('data-modal-url');
        if(clickUrl) {
            inpClickSrc.value = clickUrl;
        }

        let elms = this.cellSettings.querySelectorAll('.input-cell-padding');
        Array.prototype.forEach.call(elms, (elm) => {
            dom.removeClass(elm, 'on');
        });
        if(dom.hasClass(cell,'padding-0')) {
            let btn = this.cellSettings.querySelector('.input-cell-padding[data-command="0"');
            dom.addClass(btn, 'on');
        } else if(dom.hasClass(cell,'padding-10')) {
            let btn = this.cellSettings.querySelector('.input-cell-padding[data-command="10"');
            dom.addClass(btn, 'on');
        } else if(dom.hasClass(cell,'padding-20')) {
            let btn = this.cellSettings.querySelector('.input-cell-padding[data-command="20"');
            dom.addClass(btn, 'on');
        } else if(dom.hasClass(cell,'padding-30')) {
            let btn = this.cellSettings.querySelector('.input-cell-padding[data-command="30"');
            dom.addClass(btn, 'on');
        } else if(dom.hasClass(cell,'padding-40')) {
            let btn = this.cellSettings.querySelector('.input-cell-padding[data-command="40"');
            dom.addClass(btn, 'on');
        } else if(dom.hasClass(cell,'padding-50')) {
            let btn = this.cellSettings.querySelector('.input-cell-padding[data-command="50"');
            dom.addClass(btn, 'on');
        }

        elms = this.cellSettings.querySelectorAll('.input-cell-height');
        Array.prototype.forEach.call(elms, (elm) => {
            dom.removeClass(elm, 'on');
        });
        let minHeight = 0;
        let arrHeight = this.builder.colHeight;
        Array.prototype.forEach.call(arrHeight, (h) => {
            if(dom.hasClass(cell,'min-height-' + h)) minHeight = h;
        });

        if(minHeight===300) {
            let btn = this.cellSettings.querySelector('.input-cell-height[data-command="300"');
            dom.addClass(btn, 'on');
        } else if(minHeight===400) {
            let btn = this.cellSettings.querySelector('.input-cell-height[data-command="400"');
            dom.addClass(btn, 'on');
        } else if(minHeight===500) {
            let btn = this.cellSettings.querySelector('.input-cell-height[data-command="500"');
            dom.addClass(btn, 'on');
        } else if(minHeight===600) {
            let btn = this.cellSettings.querySelector('.input-cell-height[data-command="600"');
            dom.addClass(btn, 'on');
        } else if(minHeight===700) {
            let btn = this.cellSettings.querySelector('.input-cell-height[data-command="700"');
            dom.addClass(btn, 'on');
        }
        
        elms = this.cellSettings.querySelectorAll('.cmd-content-pos');
        Array.prototype.forEach.call(elms, (elm) => {
            dom.removeClass(elm, 'on');
        });
        let alignItems = cell.style.alignItems;
        let justifyContent = cell.style.justifyContent;
        let pos = '';
        if(justifyContent==='flex-start') {
            //top
            if(alignItems==='flex-start') {
                pos = 'topleft';
            } else if(alignItems==='center') {
                pos = 'topcenter';
            } else if(alignItems==='flex-end') {
                pos = 'topright';
            }
        } else if(justifyContent==='center') {
            //top
            if(alignItems==='flex-start') {
                pos = 'centerleft';
            } else if(alignItems==='center') {
                pos = 'center';
            } else if(alignItems==='flex-end') {
                pos = 'centerright';
            }
        } else if(justifyContent==='flex-end') {
            //top
            if(alignItems==='flex-start') {
                pos = 'bottomleft';
            } else if(alignItems==='center') {
                pos = 'bottomcenter';
            } else if(alignItems==='flex-end') {
                pos = 'bottomright';
            }
        }
        if(pos==='topleft') {
            let btn = this.cellSettings.querySelector('.cmd-content-pos[data-pos="topleft"');
            dom.addClass(btn, 'on');
        } else if(pos==='topcenter') {
            let btn = this.cellSettings.querySelector('.cmd-content-pos[data-pos="topcenter"');
            dom.addClass(btn, 'on');
        } else if(pos==='topright') {
            let btn = this.cellSettings.querySelector('.cmd-content-pos[data-pos="topright"');
            dom.addClass(btn, 'on');
        } else if(pos==='centerleft') {
            let btn = this.cellSettings.querySelector('.cmd-content-pos[data-pos="centerleft"');
            dom.addClass(btn, 'on');
        } else if(pos==='center') {
            let btn = this.cellSettings.querySelector('.cmd-content-pos[data-pos="center"');
            dom.addClass(btn, 'on');
        } else if(pos==='centerright') {
            let btn = this.cellSettings.querySelector('.cmd-content-pos[data-pos="centerright"');
            dom.addClass(btn, 'on');
        } else if(pos==='bottomleft') {
            let btn = this.cellSettings.querySelector('.cmd-content-pos[data-pos="bottomleft"');
            dom.addClass(btn, 'on');
        } else if(pos==='bottomcenter') {
            let btn = this.cellSettings.querySelector('.cmd-content-pos[data-pos="bottomcenter"');
            dom.addClass(btn, 'on');
        } else if(pos==='bottomright') {
            let btn = this.cellSettings.querySelector('.cmd-content-pos[data-pos="bottomright"');
            dom.addClass(btn, 'on');
        }


        // Background Image Adjust
        const inpImageScaleSlider = this.imageAdjust.querySelector('.image-scale-slider');
        const inpImageHorSlider = this.imageAdjust.querySelector('.image-hor-slider');
        const inpImageVertSlider = this.imageAdjust.querySelector('.image-vert-slider');

        inpImageScaleSlider.value = 100;
        inpImageHorSlider.value = 0;
        inpImageVertSlider.value = 0;

        if(cell.style.backgroundSize) {
            let val;
            let bgSize = cell.style.backgroundSize;
            if(isNaN(parseInt(bgSize))) {
                val = 100;
            } else {
                val = parseInt(bgSize);
            }
            if(bgSize.indexOf('%')!==-1) {
                inpImageScaleSlider.value = val;
            }
        }

        if(cell.style.backgroundPositionX) {
            let val;
            let bgPosX = cell.style.backgroundPositionX;
            if(isNaN(parseInt(bgPosX))) {
                val = 0;
            } else {
                val = parseInt(bgPosX);
            }
            if(bgPosX.indexOf('%')!==-1) {
                inpImageHorSlider.value = val;
            }
        }

        if(cell.style.backgroundPositionY) {
            let val;
            let bgPosY = cell.style.backgroundPositionY;
            if(isNaN(parseInt(bgPosY))) {
                val = 0;
            } else {
                val = parseInt(bgPosY);
            }
            if(bgPosY.indexOf('%')!==-1) {
                inpImageVertSlider.value = val;
            }
        }

    }

    showHideLockIndicator(col) {
        const lockIndicator = this.lockIndicator;
        if(col.hasAttribute('data-noedit')) {
            const top = col.getBoundingClientRect().top + window.pageYOffset;
            const left = col.getBoundingClientRect().left + window.pageXOffset;
            const w = col.offsetWidth * this.builder.zoom;
            // const h = col.offsetHeight * this.builder.zoom;
            lockIndicator.style.display = 'flex';
            lockIndicator.style.top = (top + 4) + 'px';
            lockIndicator.style.left = (left + w - 33) + 'px';
        } else {
            lockIndicator.style.display = '';
        }
    }

    click(col) {

        dom.addClass(this.columnTool, 'active');
        this.columnTool.style.top = (col.getBoundingClientRect().top - 29 + window.pageYOffset) + 'px';
        this.columnTool.style.left = (col.getBoundingClientRect().left - 1) + 'px';

        let nogrid = dom.parentsHasAttribute(col, 'nogrid');
        if(nogrid) {
            this.columnMore.querySelector('.cell-prev').style.display = 'none';
            this.columnMore.querySelector('.cell-next').style.display = 'none';
            this.columnMore.querySelector('.cell-up').style.display = 'none';
            this.columnMore.querySelector('.cell-down').style.display = 'none';
            this.columnMore.querySelector('.cell-increase').style.display = 'none';
            this.columnMore.querySelector('.cell-decrease').style.display = 'none';
            this.columnMore.querySelector('.cell-duplicate').style.display = 'none';

            let btnColHtml = this.columnMore.querySelector('.cell-html');
            if(btnColHtml) btnColHtml.style.display = '';

            this.columnTool.querySelector('.cell-add').style.display = 'none';
            this.columnTool.querySelector('.cell-remove').style.display = 'none';

            if(col.getAttribute('data-html')) {
                this.columnMore.querySelector('.cell-html').style.display = 'none';
                this.columnTool.querySelector('.cell-more').style.display = 'none';
            }
        } else {
            this.columnMore.querySelector('.cell-prev').style.display = '';
            this.columnMore.querySelector('.cell-next').style.display = '';
            this.columnMore.querySelector('.cell-up').style.display = '';
            this.columnMore.querySelector('.cell-down').style.display = '';
            this.columnMore.querySelector('.cell-increase').style.display = '';
            this.columnMore.querySelector('.cell-decrease').style.display = '';
            this.columnMore.querySelector('.cell-duplicate').style.display = '';

            let btnColHtml = this.columnMore.querySelector('.cell-html');
            if(btnColHtml) btnColHtml.style.display = '';

            this.columnTool.querySelector('.cell-add').style.display = '';
            this.columnTool.querySelector('.cell-remove').style.display = '';

            let row = col.parentNode;

            let num = 2; //is-row-tool & is-rowadd-tool
            if(row.querySelector('.is-row-overlay')) {
                num = 3; //is-row-tool, is-rowadd-tool & is-row-overlay
            }

            if (row.childElementCount - num === 1) {//-num => minus is-row-tool, is-rowadd-tool & is-row-overlay
                this.columnMore.querySelector('.cell-prev').style.display = 'none';
                this.columnMore.querySelector('.cell-next').style.display = 'none';
                this.columnMore.querySelector('.cell-increase').style.display = 'none';
                this.columnMore.querySelector('.cell-decrease').style.display = 'none';
            } else {
                this.columnMore.querySelector('.cell-prev').style.display = '';
                this.columnMore.querySelector('.cell-next').style.display = '';
                this.columnMore.querySelector('.cell-increase').style.display = '';
                this.columnMore.querySelector('.cell-decrease').style.display = '';
            }
    
            // let btnColHtml = this.columnMore.querySelector('.cell-html');
            let btnColDuplicate = this.columnMore.querySelector('.cell-duplicate');
            if(col.getAttribute('data-html')) {
                if(btnColHtml) btnColHtml.style.display = 'none';
                if(btnColDuplicate) btnColDuplicate.style.display = 'none';
            } else {
                if(btnColHtml) btnColHtml.style.display = '';
                if(btnColDuplicate) btnColDuplicate.style.display = '';
            }

            //data-protected
            if(row.hasAttribute('data-protected')){
                row.querySelector('.is-row-tool').style.display = 'none';

                this.columnTool.style.display = 'none'; // if row protected, then all column protected
            } else {
                if(row.querySelectorAll('[data-protected]').length>0){
                    row.querySelector('.is-row-tool').style.display = 'none';
                } else {
                    !row.querySelector('.is-row-tool') ?
                        null:
                        row.querySelector('.is-row-tool').style.display = '';
                }

                this.columnTool.style.display = '';
                //check columnTool buttons if need to show or hide
                let _protected = dom.parentsHasAttribute(col, 'data-protected');
                if(_protected){
                    this.columnTool.querySelector('.cell-remove').style.display = 'none';
                    this.columnTool.querySelector('.cell-more').style.display = 'none';
                } else {
                    this.columnTool.querySelector('.cell-remove').style.display = '';
                    this.columnTool.querySelector('.cell-more').style.display = '';
                }
            }

        }

    }

    refreshColumnTool(cell) {
        this.grid.refreshColumnTool(cell);
    }

    showColumnTool(cell) {
        this.grid.showColumnTool(cell);
    }

    hideColumnTool() {
        this.grid.hideColumnTool();
    }

}

export default ColumnTool;