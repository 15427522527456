import { Dom, Util } from './util.js';
import ElementStyleEditor from './elementpanel-css.js';

const dom = new Dom();

class ElementVisibilityStyles {
    constructor(builder) {
        this.visibilityClasses = ['d-none', 'd-block', 'd-sm-none', 'd-sm-block', 'd-md-none', 'd-lg-none', 'd-md-block'];

        this.builder = builder;

        const util = new Util(builder);
        this.util = util;

        const builderStuff = util.builderStuff();
        this.builderStuff = builderStuff;

        const elementStyleEditor = new ElementStyleEditor(builder);

        let panelStuff = builderStuff.querySelector('#divElementVisibility');
        this.panelStuff = panelStuff;
        
        const html = `
            <div style="margin-top: 25px;font-weight: bold;width:100%">Visibility:</div>
        
            <div class="is-settings clearfix" style="margin-top:0;width:100%;">
                <div>
                    <label for="chkDesktop" style="letter-spacing: 0.5px;"><input type="checkbox" id="chkDesktop" value="" /> Desktop </label>
                </div>
            </div>
            <div class="is-settings clearfix" style="margin-top:0;width:100%;">
                <div>
                    <label for="chkTablelet" style="letter-spacing: 0.5px;"><input type="checkbox" id="chkTablelet" value="" /> Tablelet </label>
                </div>
            </div>
            <div class="is-settings clearfix" style="margin-top:0;width:100%;">
                <div>
                    <label for="chkMobile" style="letter-spacing: 0.5px;"><input type="checkbox" id="chkMobile" value="" /> Mobile </label>
                </div>
            </div>
        `;
        dom.appendHtml(panelStuff, html);
        
        // Visible

        let chkDesktop = panelStuff.querySelector('#chkDesktop');
        let chkTablelet = panelStuff.querySelector('#chkTablelet');
        let chkMobile = panelStuff.querySelector('#chkMobile');

        

        const updateSetting = () => {
            this.builder.uo.saveForUndo();

            let elm = this.builder.inspectedElement;

            this.visibilityClasses.forEach(c => {
                dom.removeClass(elm, c);
            });

            const isDesktop = chkDesktop.checked;
            const isTablelet = chkTablelet.checked;
            const isMobile = chkMobile.checked;
            let classes = '';
            if (!isDesktop && !isTablelet && !isMobile) {
                classes = ['d-none'];
            } 
            if (!isDesktop && !isTablelet && isMobile) {
                classes = ['d-block d-sm-none'];
            }
            if (!isDesktop && isTablelet && !isMobile) {
                classes = ['d-none d-sm-block d-md-none'];
            }
            if (!isDesktop && isTablelet && isMobile) {
                classes = ['d-md-none'];
            }
            
            if (isDesktop && !isTablelet && !isMobile) {
                classes = ['d-none d-md-block d-lg-none'];
            } 
            if (isDesktop && !isTablelet && isMobile) {
                classes = ['d-block d-sm-none d-md-block'];
            } 
            if (isDesktop && isTablelet && !isMobile) {
                classes = ['d-none d-sm-block'];
            }
            if (isDesktop && isTablelet && isMobile) {
                classes = ['d-block'];
            }
            classes.forEach(c=> {
                dom.addClass(elm, c);
            });

            elementStyleEditor.refresh();
        
            //Trigger Change event
            this.builder.opts.onChange();
        };
        
        chkDesktop.addEventListener('click', updateSetting);
        chkTablelet.addEventListener('click', updateSetting);
        chkMobile.addEventListener('click', updateSetting);
    }

    checkOnlyClasses (list) {
        let ele = this.builder.inspectedElement;
        let classList = [].slice.call(ele.classList);

        const invertList = this.visibilityClasses.filter(n => !list.includes(n));

        if(!list.every(val => classList.includes(val))) return false;
        if(invertList.every(val => classList.includes(val))) return false;
        return true;
    }

    readElementStyles() {

        let panelStuff = this.panelStuff;

        let isDesktop = true;
        let isTablelet = true;
        let isMobile = true;

        if (this.checkOnlyClasses(['d-none'])) {
            isDesktop = false; isTablelet = false; isMobile = false;
        }
        if (this.checkOnlyClasses(['d-block', 'd-sm-none'])) {
            isDesktop = false; isTablelet = false; isMobile = true;
        }
        if (this.checkOnlyClasses(['d-none', 'd-sm-block', 'd-md-none'])) {
            isDesktop = false; isTablelet = true; isMobile = false;
        }
        if (this.checkOnlyClasses(['d-md-none'])) {
            isDesktop = false; isTablelet = true; isMobile = true;
        }

        if (this.checkOnlyClasses(['d-none', 'd-md-block', 'd-lg-none'])) {
            isDesktop = true; isTablelet = false; isMobile = false;
        }
        if (this.checkOnlyClasses(['d-block', 'd-sm-none', 'd-md-block'])) {
            isDesktop = true; isTablelet = false; isMobile = true;
        }
        if (this.checkOnlyClasses(['d-none', 'd-sm-block'])) {
            isDesktop = true; isTablelet = true; isMobile = false;
        }
        if (this.checkOnlyClasses(['d-block'])) {
            isDesktop = true; isTablelet = true; isMobile = true;
        }
        
                
        panelStuff.querySelector('#chkDesktop').checked = isDesktop;
        panelStuff.querySelector('#chkTablelet').checked = isTablelet;
        panelStuff.querySelector('#chkMobile').checked = isMobile;
    }
}

export default ElementVisibilityStyles;