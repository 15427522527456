import {Util, Dom, logg, surroundSelectionByElement} from './util.js';

const dom = new Dom();

class Hyperlink {

    constructor(builder) {

        this.builder = builder;

        const util = new Util(builder);

        const builderStuff = util.builderStuff();
        this.builderStuff = builderStuff;

        let linkTool = builderStuff.querySelector('#divLinkTool');
        if (!linkTool) {
            var pages = builder.pageList || [];

            var pageItemHtml = pages.map(p => `<div class="page-item" data-page-id="${p.id}" data-website-id="${p.websiteId}" data-url="${p.url}" data-title="${p.title}">${p.text}</div>`).join('');

            let html = `<style>
            .page-item {
                cursor: pointer;
                border: 1px transparent solid;
                width: 100%;
                padding: 10px 10px;
            }
            .page-item.selected {
                border: 1px #8cc20f solid;
            }
            .page-item:hover {
                font-weight: bold;
            }
            </style>
            
            <div id="divLinkTool" class="is-tool">
                <button title="${util.out('Link')}" data-title="${util.out('Link')}" class="link-edit"><svg class="is-icon-flex" style="width:13px;height:13px;"><use xlink:href="#ion-link"></use></svg></button>
                <button title="${util.out('Duplicate')}" data-title="${util.out('Duplicate')}" class="link-duplicate"><svg class="is-icon-flex" style="width:11px;height:11px;"><use xlink:href="#ion-ios-photos-outline"></use></svg></button>
                <button title="${util.out('Delete')}" data-title="${util.out('Delete')}" class="link-remove"><svg class="is-icon-flex" style="width:20px;height:20px;"><use xlink:href="#ion-ios-close-empty"></use></svg></button>
            </div>
            
            <!--
            <div class="is-modal createlink">
                <div class="is-modal-content">  
                    <div class="is-tabs" style="" data-group="hyperlink">
                        <a href="#" data-content="extenerl" class="active" >Extenerl</a>
                        <a href="#" data-content="int">Intenal</a>
                    </div>
                    <div id="extenerl" class="is-tab-content container clearfix active"  data-group="hyperlink" style="display:flex;">
                        <label for="inpCreateLinkUrl">${util.out('Link')}:</label>
                        <div class="link-src">
                            <input id="inpCreateLinkUrl" class="input-url" type="text"/>
                            <button title="${util.out('Select')}" class="input-select"><svg class="is-icon-flex"><use xlink:href="#ion-more"></use></svg></button>
                        </div>
                        <label style="display:block;margin-top:14px;">
                            <input class="input-newwindow" type="checkbox" />  ${util.out('Open new window')}&nbsp;
                        </label>
                        <label id="lblOpenLightbox" style="${(this.builder.useLightbox ? 'display:block' : 'display:none')};margin-top:14px;">
                            <input class="input-openlightbox" type="checkbox" />  ${util.out('Open in a lightbox (for image, video or Youtube)')}&nbsp;
                        </label>

                        <label for="inpCreateLinkText" style="margin-top:14px;display:block">${util.out('Text')}:</label>
                        <input id="inpCreateLinkText" class="input-text" type="text" style="width:100%;"/>
                        
                        <label for="inpCreateLinkTitle" style="margin-top:14px;display:block">${util.out('Title')}:</label>
                        <input id="inpCreateLinkTitle" class="input-title" type="text" style="width:100%;border-top: none;"/>
                    </div>

                    <div id="int" class="is-tab-content container clearfix"  data-group="hyperlink" style="padding:20px;">
                        <div style="height: 165px;width: 100%; overflow-y: auto;">
                            ${pageItemHtml}
                        </div>
                    </div>
                    
                    <div style="text-align:right;margin-top:14px;">
                        <button title="${util.out('Cancel')}" class="input-cancel classic-secondary">${util.out('Cancel')}</button>
                        <button title="${util.out('Ok')}" class="input-ok classic-primary">${util.out('Ok')}</button>
                    </div>
                </div>
            </div>
            -->
            
            <div class="is-modal fileselect">
                <div class="is-modal-content" style="max-width:800px;height:80%;padding:0;">
                    <iframe style="width:100%;height:100%;border: none;display: block;" src="about:blank"></iframe>
                </div>
            </div>
            `;

            dom.appendHtml(builderStuff, html);

            linkTool = builderStuff.querySelector('#divLinkTool');

            let linkEdit = linkTool.querySelector('.link-edit');
            dom.addEventListener(linkEdit, 'click', () => { // old: 10853

              this.editLink();

            });


            let linkDuplicate = linkTool.querySelector('.link-duplicate');
            dom.addEventListener(linkDuplicate, 'click', () => {

                if (this.builder.activeLink) {

                    this.builder.uo.saveForUndo();

                    if (this.builder.opts.emailMode) {
                        var element = this.builder.activeLink;
                        while (element.tagName.toLowerCase() !== 'table' && !dom.hasClass(element, 'button')) {
                            element = element.parentNode;
                        }
                        element = element.parentNode;
                        let activeLinkTD = element;
                        const newlinkTD = activeLinkTD.cloneNode(true);

                        // if there is active icon class, do not copy the class
                        let iconActive = newlinkTD.querySelector('.icon-active');
                        if (iconActive) dom.removeClass(iconActive, 'icon-active');

                        newlinkTD.style.paddingRight = '15px';
                        activeLinkTD.parentNode.insertBefore(newlinkTD, activeLinkTD);
                        this.showTool(this.builder.activeLink);

                    } else {

                        const newlink = this.builder.activeLink.cloneNode(true);

                        // if there is active icon class, do not copy the class
                        let iconActive = newlink.querySelector('.icon-active');
                        if (iconActive) dom.removeClass(iconActive, 'icon-active');

                        this.builder.activeLink.parentNode.insertBefore(newlink, this.builder.activeLink);
                        this.showTool(this.builder.activeLink);

                    }

                }

            });

            let linkRemove = linkTool.querySelector('.link-remove');
            dom.addEventListener(linkRemove, 'click', () => {

                this.builder.uo.saveForUndo();

                if (this.builder.opts.emailMode) {
                    var element = this.builder.activeLink;
                    while (element.tagName.toLowerCase() !== 'table' && !dom.hasClass(element, 'button')) {
                        element = element.parentNode;
                    }
                    element = element.parentNode;
                    let activeLinkTD = element;

                    if (activeLinkTD) activeLinkTD.parentNode.removeChild(activeLinkTD);
                    linkTool.style.display = '';

                } else {

                    if (this.builder.activeLink) this.builder.activeLink.parentNode.removeChild(this.builder.activeLink);
                    linkTool.style.display = '';

                }

                // if there is active icon, set null.
                let iconActive = this.builder.activeLink.querySelector('.icon-active');
                if (iconActive) this.builder.activeIcon = null;

            });

            // let modal = this.builderStuff.querySelector('.is-modal.createlink');
            // let inputOk = modal.querySelector('.input-ok');
            // dom.addEventListener(inputOk, 'click', () => {
            //
            //     this.builder.uo.saveForUndo();
            //
            //     var extenerlTab = this.builderStuff.querySelector('#extenerl');
            //
            //     const isExteneral = extenerlTab.style.display == 'none';
            //
            //     if (!isExteneral) {
            //         let link = this.builder.activeLink;
            //         if (link) {
            //             // Edit Existing Link
            //
            //             let url = modal.querySelector('.input-url').value;
            //             let title = modal.querySelector('.input-title').value;
            //             let linktext = modal.querySelector('.input-text').value;
            //
            //             if (linktext === '') linktext = url;
            //
            //             if (url !== '') {
            //                 link.setAttribute('href', url);
            //
            //                 if (modal.querySelector('.input-newwindow').checked) {
            //                     link.setAttribute('target', '_blank');
            //                 } else {
            //                     link.removeAttribute('target');
            //                 }
            //                 if (modal.querySelector('.input-openlightbox').checked) {
            //                     dom.addClass(link, 'is-lightbox');
            //                 } else {
            //                     dom.removeClass(link, 'is-lightbox');
            //                 }
            //
            //                 if (this.builder.activeIcon) { // Icon is selected
            //
            //                 } else {
            //                     link.innerHTML = linktext;
            //                 }
            //
            //                 link.setAttribute('title', title);
            //             } else {
            //                 var el = link;
            //                 dom.selectElementContents(el);
            //                 document.execCommand('unlink', false, null);
            //             }
            //
            //             //Trigger Change event
            //             this.builder.opts.onChange();
            //
            //         } else {
            //             // Create New Link
            //
            //             util.restoreSelection();
            //
            //             let url = modal.querySelector('.input-url').value;
            //             let title = modal.querySelector('.input-title').value;
            //             let linktext = modal.querySelector('.input-text').value;
            //
            //             if (linktext === '') linktext = url;
            //
            //             if (url !== '') {
            //
            //                 this.builder.uo.saveForUndo();
            //
            //                 let activeLink;
            //                 if (this.builder.activeIcon) { // Icon is selected
            //
            //                     let iconhtml = this.builder.activeIcon.outerHTML;
            //                     this.builder.activeIcon.outerHTML = `<a class="__dummy" href="${url}">${iconhtml}</a>`;
            //                     activeLink = document.querySelector('.__dummy');
            //                     dom.removeClass(activeLink, '__dummy');
            //
            //                     if (modal.querySelector('.input-newwindow').checked) {
            //                         activeLink.setAttribute('target', '_blank');
            //                     } else {
            //                         activeLink.removeAttribute('target');
            //                     }
            //                     if (modal.querySelector('.input-openlightbox').checked) {
            //                         dom.addClass(activeLink, 'is-lightbox');
            //                     } else {
            //                         dom.removeClass(activeLink, 'is-lightbox');
            //                     }
            //
            //                     activeLink.setAttribute('title', title);
            //
            //                     this.builder.activeIcon = activeLink.childNodes[0];
            //
            //                     if (!util.appleMobile) dom.selectElementContents(this.builder.activeIcon);
            //
            //                 } else {
            //                     document.execCommand('createLink', false, 'http://dummy');
            //                     let activeLink = document.querySelector('a[href="http://dummy"]');
            //                     activeLink.setAttribute('href', url);
            //
            //                     activeLink.innerHTML = linktext;
            //
            //                     if (modal.querySelector('.input-newwindow').checked) {
            //                         activeLink.setAttribute('target', '_blank');
            //                     } else {
            //                         activeLink.removeAttribute('target');
            //                     }
            //                     if (modal.querySelector('.input-openlightbox').checked) {
            //                         dom.addClass(activeLink, 'is-lightbox');
            //                     } else {
            //                         dom.removeClass(activeLink, 'is-lightbox');
            //                     }
            //
            //                     activeLink.setAttribute('title', title);
            //
            //                     if (!util.appleMobile) dom.selectElementContents(activeLink);
            //                 }
            //
            //             }
            //
            //             //save selection
            //             util.saveSelection();
            //
            //             //Trigger Change event
            //             this.builder.opts.onChange();
            //
            //             //Trigger Render event
            //             this.builder.opts.onRender();
            //
            //         }
            //     } else {
            //         var selectedPage = this.builderStuff.querySelector('.page-item.selected');
            //         if (!selectedPage) {
            //             // alert('Select page');
            //             return;
            //         }
            //
            //         let link = this.builder.activeLink;
            //
            //         if (link) {
            //             // Edit Existing Link
            //
            //             let url = selectedPage.getAttribute('data-url');
            //             let pageId = selectedPage.getAttribute('data-page-id');
            //             let websiteId = selectedPage.getAttribute('data-website-id');
            //             let title = selectedPage.getAttribute('data-title');
            //             let linktext = modal.querySelector('.input-text').value;
            //
            //             if (linktext === '') linktext = url;
            //
            //             if (url !== '') {
            //                 link.setAttribute('href', url);
            //
            //                 // if (modal.querySelector('.input-newwindow').checked) {
            //                 //     link.setAttribute('target', '_blank');
            //                 // } else {
            //                 link.removeAttribute('target');
            //                 // }
            //
            //                 if (this.builder.activeIcon) { // Icon is selected
            //
            //                 } else {
            //                     link.innerHTML = linktext;
            //                 }
            //
            //                 link.setAttribute('title', title);
            //                 link.setAttribute('data-internal', 'true');
            //                 link.setAttribute('data-page-id', pageId);
            //                 link.setAttribute('data-website-id', websiteId);
            //             } else {
            //                 el = link;
            //                 dom.selectElementContents(el);
            //                 document.execCommand('unlink', false, null);
            //             }
            //
            //             //Trigger Change event
            //             this.builder.opts.onChange();
            //
            //         } else {
            //             // Create New Link
            //
            //             util.restoreSelection();
            //
            //             let url = selectedPage.getAttribute('data-url');
            //             let pageId = selectedPage.getAttribute('data-page-id');
            //             let websiteId = selectedPage.getAttribute('data-website-id');
            //             let title = selectedPage.getAttribute('data-title');
            //             let linktext = modal.querySelector('.input-text').value;
            //
            //             if (linktext === '') linktext = url;
            //
            //             if (url !== '') {
            //
            //                 this.builder.uo.saveForUndo();
            //
            //                 let activeLink;
            //                 if (this.builder.activeIcon) { // Icon is selected
            //
            //                     let iconhtml = this.builder.activeIcon.outerHTML;
            //                     this.builder.activeIcon.outerHTML = `<a class="__dummy" href="${url}" data-inteneral="true">${iconhtml}</a>`;
            //                     activeLink = document.querySelector('.__dummy');
            //                     dom.removeClass(activeLink, '__dummy');
            //
            //                     if (modal.querySelector('.input-newwindow').checked) {
            //                         activeLink.setAttribute('target', '_blank');
            //                     } else {
            //                         activeLink.removeAttribute('target');
            //                     }
            //
            //                     activeLink.setAttribute('title', title);
            //                     activeLink.setAttribute('data-internal', 'true');
            //                     activeLink.setAttribute('data-page-id', pageId);
            //                     activeLink.setAttribute('data-website-id', websiteId);
            //
            //
            //                     this.builder.activeIcon = activeLink.childNodes[0];
            //
            //                     if (!util.appleMobile) dom.selectElementContents(this.builder.activeIcon);
            //
            //                 } else {
            //                     // if(this.builder.isIE) {
            //                     //     util.hideModal(modal);
            //                     //     return;
            //                     // }
            //                     document.execCommand('createLink', false, 'http://dummy');
            //                     let activeLink = document.querySelector('a[href="http://dummy"]');
            //                     activeLink.setAttribute('href', url);
            //                     activeLink.setAttribute('data-internal', 'true');
            //                     activeLink.setAttribute('data-page-id', pageId);
            //                     activeLink.setAttribute('data-website-id', websiteId);
            //
            //                     activeLink.innerHTML = linktext;
            //
            //                     // if (modal.querySelector('.input-newwindow').checked) {
            //                     //     activeLink.setAttribute('target', '_blank');
            //                     // } else {
            //                     activeLink.removeAttribute('target');
            //                     // }
            //
            //                     activeLink.setAttribute('title', title);
            //
            //                     if (!util.appleMobile) dom.selectElementContents(activeLink);
            //                 }
            //
            //             }
            //
            //             //save selection
            //             util.saveSelection();
            //
            //             //Trigger Change event
            //             this.builder.opts.onChange();
            //
            //             //Trigger Render event
            //             this.builder.opts.onRender();
            //
            //         }
            //     }
            //
            //     util.hideModal(modal);
            //
            // });
            //
            // let inputCancel = modal.querySelector('.input-cancel');
            // dom.addEventListener(inputCancel, 'click', () => {
            //     util.hideModal(modal);
            //     if (!util.appleMobile) util.restoreSelection();
            // });

        }
        this.linkTool = linkTool;

    }

    click(col, e) {

        let customcode = false;
        if (col.hasAttribute('data-html')) { // Column contains custom code.
            customcode = true;
        }
        let noedit = false;
        if (col.hasAttribute('data-noedit')) { // Column is not (text) editable.
            noedit = true;
        }

        let _protected = false;
        if (col.hasAttribute('data-protected')) { // Column is not (text) editable and also: cannot be deleted, moved or duplicated.
            _protected = true;
        }

        const elm = e.target;

        this.builder.activeLink = null;
        if ((elm.tagName.toLowerCase() === 'a' || dom.parentsHasTag(elm, 'a')) && !customcode && !noedit && !_protected) {

            if (elm.childNodes.length > 0) { // Sometimes happens when click a link icon, but not exactly on the icon <i>, but the link <a>
                if (elm.childNodes[0].tagName) if (elm.childNodes[0].tagName.toLowerCase() === 'i' && elm.childNodes.length === 1 && elm.childNodes[0].innerHTML === '') {

                    const icons = document.querySelectorAll('.icon-active');
                    Array.prototype.forEach.call(icons, (icon) => {
                        dom.removeClass(icon, 'icon-active');
                    });
                    dom.addClass(elm.childNodes[0], 'icon-active');

                    dom.selectElementContents(elm.childNodes[0]);

                    this.builder.activeIcon = elm.childNodes[0];
                }
            }

            if (elm.tagName.toLowerCase() === 'img') {
                return;
            }

            let link;
            if (elm.tagName.toLowerCase() === 'a') {
                link = elm;
            } else {
                let element = elm;
                while (element.tagName.toLowerCase() !== 'a') {
                    element = element.parentNode;
                }
                link = element;
            }

            logg( 'active link ', link )
            this.builder.activeLink = link;

            this.showTool(link);

            e.preventDefault();

        } else {

            this.hideTool();

        }
    }

    showTool(link) {

        // Link tool
        const linkTool = this.linkTool;

        let linkDisplay = dom.getStyle(link, 'display');
        if (linkDisplay === 'inline-block') {
            linkTool.querySelector('.link-duplicate').style.display = 'block';
            linkTool.querySelector('.link-remove').style.display = 'block';
        } else {
            if (this.builder.activeIcon) {
                linkTool.querySelector('.link-duplicate').style.display = 'block';
                linkTool.querySelector('.link-remove').style.display = 'block';
            } else {
                linkTool.querySelector('.link-duplicate').style.display = 'none';
                linkTool.querySelector('.link-remove').style.display = 'none';
            }
        }

        const top = link.getBoundingClientRect().top;
        const left = link.getBoundingClientRect().left;
        linkTool.style.display = 'flex';
        const w = linkTool.offsetWidth; //to get value, element must not hidden (display:none). So set display:flex before this.
        const h = linkTool.offsetHeight;
        linkTool.style.top = (top - h + window.pageYOffset - 3) + 'px';
        linkTool.style.left = (left + (link.offsetWidth * this.builder.opts.zoom) - w) + 'px';

    }

    hideTool() {

        this.linkTool.style.display = '';

    }

    createLink() {
        const util = new Util(this.builder);

        let link;
        if (this.builder.activeLink) {

            link = this.builder.activeLink;

        } else {

            //Adjust selection: If cursor is on existing link, set active link (select the link if it is not icon)
            let elm;
            try {
                let curr;
                if (window.getSelection) {
                    curr = window.getSelection().getRangeAt(0).commonAncestorContainer;
                    if (curr.nodeType === 3) {  //text node
                        elm = curr.parentNode;
                    } else {
                        elm = curr;
                    }
                } else if (document.selection) {
                    curr = document.selection.createRange();
                    elm = document.selection.createRange().parentElement();
                }
            } catch (e) {
                return;
            }

            if (elm.nodeName.toLowerCase() === 'a') {
                link = elm;
            } else {
                let element = elm;
                while (element.nodeName.toLowerCase() !== 'a') {
                    if (element.tagName === 'BODY' || element.tagName === 'HTML') break;
                    if (element.parentNode) element = element.parentNode;
                }
                if (element.nodeName.toLowerCase() === 'a') {
                    link = element;
                }
            }

            if (link) {
                this.builder.activeLink = link;

                if (elm.nodeName.toLowerCase() === 'i' && elm.innerHTML === '') {
                    // Icon is selected
                    // console.log('icon is selected')
                } else {
                    dom.selectElementContents(this.builder.activeLink);
                    util.saveSelection();
                }
            }

        }


        // critical before mutating DOM
        this.builder.uo.saveForUndo();

        if (!link) {

          this.builder.activeLink = surroundSelectionByElement('a', { className: 'anchor--inline' });

        }

        const editExternally = this.builder.opts?.onEditLink
        const canEdit = !!editExternally?.call;
        const text = dom.getSelected() || '';

        if (canEdit)
          editExternally(this.builder.activeLink, {
            text: text
          })
          // .then(
          //     (res) => {
          //         console.log('edited!');
          //     });

        return;

        let modal = this.builderStuff.querySelector('.is-modal.createlink');

        var pageItems = this.builderStuff.querySelectorAll('.page-item');
        Array.prototype.forEach.call(pageItems, (pageItem) => {
            dom.addEventListener(pageItem, 'click', () => {
                Array.prototype.forEach.call(pageItems, (pageItem) => {
                    dom.removeClass(pageItem, 'selected');
                });
                dom.addClass(pageItem, 'selected');
            });
        });

        Array.prototype.forEach.call(pageItems, (pageItem) => {
            dom.removeClass(pageItem, 'selected');
        });

        if (this.builder.opts.onFileSelectClick || this.builder.opts.fileselect !== '') {

            modal.querySelector('.input-select').style.display = 'block';
            // modal.querySelector('.input-url').style.width = '444px';

            let inputSelect = modal.querySelector('.input-select');
            dom.addEventListener(inputSelect, 'click', (e) => {

                if (this.builder.opts.onFileSelectClick) {
                    this.builder.opts.onFileSelectClick({targetInput: modal.querySelector('.input-url'), theTrigger: modal.querySelector('.input-select')});
                } else {
                    let modalFileSelect = this.builderStuff.querySelector('.is-modal.fileselect');
                    if (modalFileSelect.querySelector('iframe').src === 'about:blank') {
                        modalFileSelect.querySelector('iframe').src = this.builder.opts.fileselect;
                    }
                    util.showModal(modalFileSelect, false, null, false);
                }
                e.preventDefault();
                e.stopImmediatePropagation();

            });

        } else {

            modal.querySelector('.input-select').style.display = 'none';
            // modal.querySelector('.input-url').style.width = '100%';

        }

        modal.querySelector('.input-url').value = '';
        modal.querySelector('.input-newwindow').checked = false;
        modal.querySelector('.input-openlightbox').checked = false;
        modal.querySelector('.input-title').value = '';
        modal.querySelector('.input-text').value = '';

        if (link) {

            // Get values
            let url = link.getAttribute('href');
            let target = link.getAttribute('target');
            let title = link.getAttribute('title');
            let linktext = link.innerHTML;
            modal.querySelector('.input-url').value = url;
            if (target === '_blank') {
                modal.querySelector('.input-newwindow').checked = true;
            } else {
                modal.querySelector('.input-newwindow').checked = false;
            }
            if (dom.hasClass(link, 'is-lightbox')) {
                modal.querySelector('.input-openlightbox').checked = true;
            } else {
                modal.querySelector('.input-openlightbox').checked = false;
            }
            modal.querySelector('.input-title').value = title;
            modal.querySelector('.input-text').value = linktext;

            if (link.getAttribute('data-internal') == 'true') {
                modal.querySelector('a[data-content="int"]').click();

                Array.prototype.forEach.call(pageItems, (pageItem) => {
                    dom.removeClass(pageItem, 'selected');
                });
                var selectedPageItem = modal.querySelector(`.page-item[data-url="${url}"]`);
                if (selectedPageItem) {
                    dom.addClass(selectedPageItem, 'selected');
                }
            } else {
                Array.prototype.forEach.call(pageItems, (pageItem) => {
                    dom.removeClass(pageItem, 'selected');
                });
                modal.querySelector('a[data-content="extenerl"]').click();
            }

        } else {
            modal.querySelector('.input-text').value = text;
        }

        // Show modal
        util.showModal(modal, true, () => {
            if (!util.appleMobile) util.restoreSelection();
        }, true);

        modal.querySelector('.input-url').focus();

    }

    editLink() {
        const util = new Util(this.builder);

        let link = this.builder.activeLink;

        if (this.builder.opts.onEditLink)
            this.builder.opts.onEditLink(this.builder.activeLink, {}).then(
                (res) => {
                    logg('eedit link - dited!');
                });

        return;

        let modal = this.builderStuff.querySelector('.is-modal.createlink');

        if (this.builder.opts.onFileSelectClick || this.builder.opts.fileselect !== '') {

            modal.querySelector('.input-select').style.display = 'block';
            modal.querySelector('.input-url').style.width = '444px';

            let inputSelect = modal.querySelector('.input-select');
            dom.addEventListener(inputSelect, 'click', () => {

                if (this.builder.opts.onFileSelectClick) {
                    this.builder.opts.onFileSelectClick({targetInput: modal.querySelector('.input-url'), theTrigger: modal.querySelector('.input-select')});
                } else {
                    let modalFileSelect = this.builderStuff.querySelector('.is-modal.fileselect');
                    if (modalFileSelect.querySelector('iframe').src === 'about:blank') {
                        modalFileSelect.querySelector('iframe').src = this.builder.opts.fileselect;
                    }
                    util.showModal(modalFileSelect, false, null, false);
                }

            });

        } else {

            modal.querySelector('.input-select').style.display = 'none';
            modal.querySelector('.input-url').style.width = '100%';

        }

        // Get values
        let url = link.getAttribute('href');
        let target = link.getAttribute('target');
        let title = link.getAttribute('title');
        let linktext = link.innerHTML;
        modal.querySelector('.input-url').value = url;
        if (target === '_blank') {
            modal.querySelector('.input-newwindow').checked = true;
        } else {
            modal.querySelector('.input-newwindow').checked = false;
        }
        if (dom.hasClass(link, 'is-lightbox')) {
            modal.querySelector('.input-openlightbox').checked = true;
        } else {
            modal.querySelector('.input-openlightbox').checked = false;
        }
        modal.querySelector('.input-title').value = title;
        modal.querySelector('.input-text').value = linktext;

        // Show modal
        util.showModal(modal, true, null, true);

        modal.querySelector('.input-url').focus();

    }

}

export default Hyperlink;
