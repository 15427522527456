import { Util, Dom } from './util.js';

const dom = new Dom();

class Lightbox {
    constructor(builder) {

        this.builder = builder;
        const util = new Util(builder);

        let builderStuff = document.querySelector('#_cbhtml'); // All editing controls will be placed within <div id="_cbhtml">...</div>
        if(!builderStuff) {
            builderStuff = dom.createElement('div');
            builderStuff.id = '_cbhtml';
            builderStuff.className = 'is-ui';
            dom.appendChild(document.body, builderStuff);
        } 
        this.builderStuff = builderStuff;

        let div = this.builderStuff.querySelector('#_lightbox');
        if(!div) {
            div = dom.createElement('div');
            div.id = '_cbhtml';
            div.className = 'is-ui';
            dom.appendChild(this.builderStuff, div);

            div.innerHTML = `
                <div class="is-lightbox lightbox-externalvideo">
                    <button class="cmd-lightbox-close" title="${util.out('Close')}" type="button" style="flex:none;position:absolute;top:0;right:0;background:none;">
                        <svg><use xlink:href="#icon-close"></use></svg>
                    </button>
                    <div class="lightbox-content" style="width:100%;">
                        <div class="embed-responsive embed-responsive-16by9" style="width:100%;">
                        <iframe width="560" height="315" src="about:blank" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <div class="is-lightbox lightbox-video light">
                    <button class="cmd-lightbox-close" title="${util.out('Close')}" type="button" style="flex:none;position:absolute;top:0;right:0;background:none;">
                        <svg><use xlink:href="#icon-close"></use></svg>
                    </button>
                    <div class="lightbox-content" style="width:100%;"></div>
                </div>
                <div class="is-lightbox lightbox-image light">
                    <button class="cmd-lightbox-close" title="${util.out('Close')}" type="button" style="flex:none;position:absolute;top:0;right:0;background:none;">
                        <svg><use xlink:href="#icon-close"></use></svg>
                    </button>
                    <div class="lightbox-content" style="width:100%;"></div>
                </div>
                <svg width="0" height="0" style="position:absolute;display:none;">
                    <defs>
                        <symbol viewBox="0 0 24 24" id="icon-close" stroke-width="0.7" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line>
                        </symbol>
                    </defs>
                </svg>
            `;

            // Click overlay will close lightbox
            const lightboxes = this.builderStuff.querySelectorAll('div.is-lightbox');
            lightboxes.forEach((lightbox)=>{
                lightbox.addEventListener('click', (e)=>{
                    if(dom.parentsHasClass(e.target,'lightbox-content')) return;
                    dom.removeClass(lightbox,'active');

                    const btnClose = lightbox.querySelector('.cmd-lightbox-close');
                    btnClose.style.opacity = 0;
                    
                    document.body.style.overflowY = '';
                    setTimeout(()=>{
                        let iframe = lightbox.querySelector('iframe');
                        if(iframe) {
                            iframe.setAttribute('src','about:blank');
                        }
                        lightbox.style.display = '';

                        this.builder.preserveSelection = false;
                    },300);
                });
            });

            // this.init();

        }

    } //constructor

    /*
    init() {

        // Find all elements that have .block-click & .button-click class.
        // Then read data attributes:
        //  - data-modal-theme: dark or light
        //  - data-modal-color (background color)
        //  - data-modal-image (image url)
        //  - data-modal-video (video url)
        //  - data-modal-externalvideo (ex. youtube url)
        const blockClickables = document.querySelectorAll('.is-lightbox'); //.block-click,.button-click
        blockClickables.forEach((block)=>{
            block.addEventListener('click', (e)=>{
                let url;
                let extension;

                if(block.tagName.toLowerCase() === 'a') {
                    url = block.getAttribute('href');
                    extension = url.split('.').pop();
                } else {
                    return;
                }

                let theme = block.getAttribute('data-modal-theme');
                if(!theme) theme='light';

                const color = block.getAttribute('data-modal-color');

                if(extension==='jpg'||extension==='jpeg'||extension==='png'||extension==='gif'||extension==='webm') {
                    this.openImage(url, theme, color);
                } 
                if(extension==='mp4') {
                    this.openVideo(url, 'dark', color);
                } 

                if(url.toLowerCase().indexOf('youtube.com')!==-1 ||
                url.toLowerCase().indexOf('vimeo.com')!==-1) {
                    this.openExternalVideo(url, 'dark', color);
                } 

                if(block.getAttribute('data-modal-image')) {
                    url = block.getAttribute('data-modal-image');
                    this.openImage(url, theme, color);
                }
                if(block.getAttribute('data-modal-video')) {
                    url = block.getAttribute('data-modal-video');
                    this.openVideo(url, 'dark', color);
                }
                if(block.getAttribute('data-modal-externalvideo')) {
                    url = block.getAttribute('data-modal-externalvideo');
                    this.openExternalVideo(url, 'dark', color);
                }

                e.preventDefault();
            });
        });

    } //init
    */

    openImage(url, theme, color) {

        this.builder.preserveSelection = true;

        let lightbox = this.builderStuff.querySelector('div.is-lightbox.lightbox-image');

        // in case opened in an iframe (ex. preview)
        if(window.frameElement && !lightbox) {
            lightbox = parent.document.querySelector('.is-lightbox.lightbox-image');
        }

        const btnClose = lightbox.querySelector('.cmd-lightbox-close');
        btnClose.style.opacity = 0;

        if(color) lightbox.style.backgroundColor = color;

        const div = lightbox.querySelector('.lightbox-content');
        div.innerHTML = '<img src='+url+'>';

        if(theme==='light') {
            dom.addClass(lightbox, 'light');
            dom.removeClass(lightbox, 'dark');
        } else {
            dom.addClass(lightbox, 'dark');
            dom.removeClass(lightbox, 'light');
        }
        
        lightbox.style.display = 'flex';
        
        if(!window.frameElement) document.body.style.overflowY = 'hidden';

        setTimeout(()=>{
            dom.addClass(lightbox,'active');

            setTimeout(()=>{
                btnClose.style.opacity = 1;
            },450);
        },10);
    }

    openVideo(url, theme, color) {

        this.builder.preserveSelection = true;

        let lightbox = document.querySelector('.is-lightbox.lightbox-video');

        // in case opened in an iframe (ex. preview)
        if(window.frameElement && !lightbox) {
            lightbox = parent.document.querySelector('.is-lightbox.lightbox-video');
        }

        const btnClose = lightbox.querySelector('.cmd-lightbox-close');
        btnClose.style.opacity = 0;

        if(color) lightbox.style.backgroundColor = color;

        const div = lightbox.querySelector('.lightbox-content');
        div.innerHTML = '<video class="is-video-bg" playsinline controls autoplay width="100%">' +
            '<source src="'+url+'" type="video/mp4">' +
        '</video>';

        if(theme==='light') {
            dom.addClass(lightbox, 'light');
            dom.removeClass(lightbox, 'dark');
        } else {
            dom.addClass(lightbox, 'dark');
            dom.removeClass(lightbox, 'light');
        }
        
        lightbox.style.display = 'flex';

        if(!window.frameElement) document.body.style.overflowY = 'hidden';

        setTimeout(()=>{
            dom.addClass(lightbox,'active');

            setTimeout(()=>{
                btnClose.style.opacity = 1;
            },450);
        },10);
    }

    openExternalVideo(url, theme, color) {

        this.builder.preserveSelection = true;

        let lightbox = document.querySelector('.is-lightbox.lightbox-externalvideo');

        // in case opened in an iframe (ex. preview)
        if(window.frameElement && !lightbox) {
            lightbox = parent.document.querySelector('.is-lightbox.lightbox-externalvideo');
        }

        const btnClose = lightbox.querySelector('.cmd-lightbox-close');
        btnClose.style.opacity = 0;

        if(color) lightbox.style.backgroundColor = color;
        
        if(theme==='light') {
            dom.addClass(lightbox, 'light');
            dom.removeClass(lightbox, 'dark');
        } else {
            dom.addClass(lightbox, 'dark');
            dom.removeClass(lightbox, 'light');
        }

        const iframe = lightbox.querySelector('iframe');
        url = this.getIframeVideoUrl(url);
        if(url!=='') iframe.setAttribute('src', url);
        lightbox.style.display = 'flex';

        if(!window.frameElement) document.body.style.overflowY = 'hidden';
        
        const div = lightbox.querySelector('.lightbox-content');
        div.style.width=((div.offsetHeight*16)/9) + 'px';
        
        setTimeout(()=>{
            dom.addClass(lightbox,'active');

            setTimeout(()=>{
                btnClose.style.opacity = 1;
            },450);
        },10);
    }

    getIframeVideoUrl(src) {

        // https://regex101.com/library/OY96XI
        // https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
        // let youRegex = /^http[s]?:\/\/(((www.youtube.com\/watch\?(feature=player_detailpage&)?)v=)|(youtu.be\/))([^#&?]*)/;
        let youRegex = /^.*(?:https?:)?(?:\/\/)?(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\/\S*?[^\w\s-])((?!videoseries)[\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/;
        let vimeoRegex = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/)|(video\/))?([0-9]+)\/?/;
        // let youRegexMatches = youRegex.exec(src);
        let youRegexMatches = src.match(youRegex);
        let vimeoRegexMatches = vimeoRegex.exec(src);
        // if (youRegexMatches !== null || vimeoRegexMatches !== null) {
        if ((youRegexMatches !== null || vimeoRegexMatches !== null) && src.indexOf('player.vimeo.com')===-1 && src.indexOf('youtube.com/embed/')===-1) {
            if (youRegexMatches !== null) { // && youRegexMatches.length >= 7) {
                // let youMatch = youRegexMatches[6];
                let youMatch = youRegexMatches[1];

                src = 'https://www.youtube.com/embed/' + youMatch + '?rel=0&autoplay=1&color=white';
            }
            if (vimeoRegexMatches !== null && vimeoRegexMatches.length >= 7) {
                let vimeoMatch = vimeoRegexMatches[6];
                src = 'https://player.vimeo.com/video/' + vimeoMatch;
            }
        }
        return src;
    }

} //Lightbox

export default Lightbox;

            