import {
    Dom,
    ELEMENT_BACKGROUND_ACTIVE_SELECTION,
    ELEMENT_BACKGROUND_NO_ACTIVE_SELECTION,
    MOBILE_BACKGROUND_CLASS,
    bindUnit,
    Util,
    ELEMENT_BACKGROUND_MOUNT_RESOURCE,
    isAChildOf,
    query,
    queryImageDimenions,
    getResourceFromBackgroundImageUrl,
    listify,
    logg,
    createVideoIframe, 
    manageProgress,
    MOBILE,
    DESKTOP,
    findElementParentAnchor,
} from './util.js';
import Cropper from 'cropperjs';

const dom = new Dom();

export class ElementBackgroundTool {

    builder = null;
    util = null;
    stuff = null;
    ownTool = null;

    focusedEl = null;

    buttonWidth = 40;

    backgroundImageInput = 'fileEmbedBackgroundImage';
    backgroundImageInputForMobile = 'fileEmbedBackgroundImageForMobile';

    debug = false;

    cropper = null;

    previewDimensions = {
        width: 800,
        height: 550,
    };

    constructor(builder, debug) {

        this.debug = debug;
        this.builder = builder;
        this.util = new Util(builder);

        this.setup();
    }

    setup() {

        this.stuff = this.util.builderStuff()

        this

            // add necessary markup for the tool to operate
            .addBoilerplate()

            // listen to dispatched custom events
            .listenToActiveSelection()

            // listen to when nullify the focused element
            .listenToDeactivateSelection()

            // decide if element is elligible for background-image edit
            .detectFocusedEl()

            // listen to image-on-change
            .listenToImageOnChange()

            // listen to mount new resource
            .listenToMountResource();


        // edit background

        // listen to open modal
        this.listenToRequestEditMode();
        this.hyperlinkBgImage();
        this.controlRatio();

    }

    addBoilerplate() {

        if (this.queryOwnTool()) {
            this.ownTool = this.queryOwnTool();
            return this;
        }

        const html = `
          <div id="divBackgroundImageTool" class="is-tool">
            <div role="button" tabindex="0" style="width:${this.buttonWidth}px;height:40px;overflow:hidden;">
              <div style="position:absolute;width:100%;height:100%;">
                <svg class="is-icon-flex" style="position: absolute;top: 13px;left: 15px;width: 14px;height: 14px;"><use xlink:href="#ion-image"></use></svg>
              </div>
              <input title="${this.util.out('Change Background Media (Desktop)')}" data-title="${this.util.out('Change Background Media (Desktop)')}" id="${this.backgroundImageInput}" data-version="${DESKTOP}" type="file" accept="image/*, video/*" style="position:absolute;top:-20px;left:0;width:100%;height:60px;opacity: 0;cursor: pointer;"/>
            </div>
            <div role="button" tabindex="0" style="width:${this.buttonWidth}px;height:40px;overflow:hidden;">
              <div style="position:absolute;width:100%;height:100%;">
                <svg class="is-icon-flex" style="position: absolute;top: 13px;left: 15px;width: 14px;height: 14px;"><use xlink:href="#ion-image"></use></svg>
              </div>
              <input title="${this.util.out('Change Background Media (Mobile)')}" data-title="${this.util.out('Change Background Media (Mobile)')}" id="${this.backgroundImageInputForMobile}" data-version="${MOBILE}" type="file" accept="image/*, video/*" style="position:absolute;top:-20px;left:0;width:100%;height:60px;opacity: 0;cursor: pointer;"/>
            </div>
            <button tabindex="0" title="Edit Background Image (Desktop)" data-title="Edit Background Image (Desktop)" class="background-edit" style="width:40px;height:40px;">
              <svg class="is-icon-flex" style="width:14px;height:14px;"><use xlink:href="#ion-android-create"></use></svg>
            </button>
            <button tabindex="0" title="Hyperlink" data-title="Hyperlink" class="hyperlink-bg" style="width:40px;height:40px;">
              <svg class="is-icon-flex" style="width:14px;height:14px;"><use xlink:href="#ion-link"></use></svg>
            </button>
            <button tabindex="0" title="Control Aspect" data-title="Control Aspect" class="control-aspect" style="width:40px;height:40px;">
              <svg class="is-icon-flex" style="width:14px;height:14px;"><use xlink:href="#ion-gear-b"></use></svg>
            </button>
          </div>
          
          <div class="is-modal backgroundedit">
            <div class="is-modal-content">
              <div class="backgroundedit-crop" style="display:flex;height:80px;align-items:center;align-self:flex-start;">
                <button title="5x5" data-crop-size="1" style="width: 60px;height: 60px;">5x5</button>
                <button title="4x3" data-crop-size="1.33333" style="width: 60px;height: 45px;">4x3</button>
                <button title="3x4" data-crop-size="0.75" style="width: 45px;height: 60px;">3x4</button>
                <button title="6x4" data-crop-size="1.5" style="width: 60px;height: 40px;">6x4</button>
                <button title="4x6" data-crop-size="0.6666" style="width: 40px;height: 60px;">4x6</button>
                <button title="${this.util.out('Free')}" data-crop-size="" style="width: 60px;height: 45px;">${this.util.out('Free')}</button>
              </div>
              <div class="backgroundedit-preview" style="min-width:200px;">
              </div>
              <div style="margin-top:7px;text-align:right;align-self:flex-end;">
                <button title="${this.util.out('Cancel')}" class="backgroundedit-cancel classic-secondary">${this.util.out('Cancel')}</button>
                <button title="${this.util.out('Apply')}" class="backgroundedit-proceed classic-primary">${this.util.out('Apply')}</button>
              </div>
            </div>
          </div>`;

        dom.appendHtml(this.stuff, html);

        this.ownTool = this.queryOwnTool();

        return this;

    }

    detectFocusedEl() {

        const element = !!this.builder.opts.container && 'container' in this.builder.opts ? query(this.builder.opts.container) : document;

        // narrow down the range by querying builder.opts.container
        element.addEventListener('click', e => {
            const element = e.target;
            if (!element || !(element instanceof HTMLElement)) return;

            if (element.style.backgroundImage) {
                this.focusedEl = element;
                this.ownTool.dispatchEvent(new CustomEvent(ELEMENT_BACKGROUND_ACTIVE_SELECTION));
            } else {
                this.ownTool.dispatchEvent(new CustomEvent(ELEMENT_BACKGROUND_NO_ACTIVE_SELECTION));
            }

        });

        return this;
    }

    listenToDeactivateSelection() {

        this.ownTool.addEventListener(ELEMENT_BACKGROUND_NO_ACTIVE_SELECTION, e => {

            if (isAChildOf(this.stuff, e.target)) return;

            logg('remove focused element ', e.target);

            this.focusedEl = null;
            this.ownTool.style.display = 'none';
            this.ownTool.style.top = '0';
            this.ownTool.style.left = '0';

        });

        return this;

    }

    listenToActiveSelection() {

      this.ownTool.addEventListener(ELEMENT_BACKGROUND_ACTIVE_SELECTION, e => {

        // ensure the element is visible
        this.ownTool.style.display = 'flex';

        const {offsetWidth: toolWidth,} = this.ownTool;
        const {top, left: focusedElementLeft, width: focusedElementWidth} = this.focusedEl.getBoundingClientRect() || {};
        const toolLeft = focusedElementLeft + (focusedElementWidth / 2) - (toolWidth / 2) + window.pageXOffset;

        if (isNaN(toolLeft) || isNaN(top)) {

          this.ownTool.style.display = 'none'
          return logg('invalid bounding client rect ', top, focusedElementLeft);
        }

        this.ownTool.style.top = bindUnit(top + window.pageYOffset, 'px');
        this.ownTool.style.left = bindUnit(toolLeft, 'px');

      });

      return this;
    }

    listenToImageOnChange() {

        const inputs = [this.backgroundImageInput, this.backgroundImageInputForMobile]
          .map(a=> document.getElementById(a))
          .filter(a=> !!a)

        if (inputs?.length) {
          inputs.forEach(item=>
            item.addEventListener('change', (e) => {

              const {version} = item.dataset||{};
              const {files} = e.target;

              const [uploadedFile] = files || [];
              const type = uploadedFile?.type;
              const isImage = type.startsWith('image');

              if (!files?.length) return logg('input change - files not found');

              // dispatch event immediately if video
              if (!isImage) {
                return this.ownTool.dispatchEvent(
                  new CustomEvent(ELEMENT_BACKGROUND_MOUNT_RESOURCE, {
                      detail: {
                          result: files,
                          type,
                          version,
                      }
                  }));
              }


              // create file reader
              let reader = new FileReader();

              // wait until resource is loaded
              reader.onload = e => {

                  const {result} = e.target||{};

                  return (!result?.length || result === '') ?
                      null :
                      this.ownTool.dispatchEvent(new CustomEvent(ELEMENT_BACKGROUND_MOUNT_RESOURCE, {
                          detail: {
                              result,
                              type,
                              version,
                          }
                      }));

              }

              // load selected resource
              return reader.readAsDataURL(files[0]);

            }))
        }

        return this;

    }

    listenToMountResource() {

        const saveRemotely = this.builder?.opts?.onSaveBase64ImageRemotely;
        const canSaveRemotely = !!saveRemotely && !!saveRemotely.call;

        const saveVideoRemotely = this.builder?.opts?.saveVideoRemotely;
        const canSaveVideoRemotely = !!saveVideoRemotely && !!saveVideoRemotely.call;

        this.ownTool.addEventListener(ELEMENT_BACKGROUND_MOUNT_RESOURCE, e => {

            if (!e.detail) {
              return logg('mounted resource is empty');
            }

            // show progress
            manageProgress(this.focusedEl, this.stuff);

            const {type, result, version} = e.detail||{};

            // if video
            if (type.startsWith('video') && canSaveVideoRemotely) {
              return saveVideoRemotely(result, this.injectVideoResource.bind(this, version));
            }

            // or just else
            if (type.startsWith('image') && canSaveRemotely) {
              return saveRemotely(result, this.updateImageSource.bind(this, version));
            }

            // hide progress for any unhandled types
            manageProgress(undefined, this.stuff, !0);

            return logg('listen to mount resource - invalid resource type');

        })

        return this;
    }

    removeExistingBackgrounds(version) {

      try {

        let iframe = null;

        if (this.focusedEl) {
          
          if (version === DESKTOP) {
            iframe = listify(`.embed-responsive-item:not(.${MOBILE}-resource)`, this.focusedEl);
          } else if (version === MOBILE) {
            iframe = listify(`.embed-responsive-item.${MOBILE}-resource`, this.focusedEl);
          }

          if (iframe) {
            iframe.forEach(a=> a.remove());
          }

          if (version === DESKTOP) {
            // remove img
            this.focusedEl.style.backgroundImage = 'none';
          } else if (version === MOBILE) {
            Reflect.deleteProperty(this.focusedEl.dataset, MOBILE_BACKGROUND_CLASS);
          }

        }

      } catch(e) { logg('remove existing background caught ', e) };

    }

    injectVideoResource(version, asset) {

        manageProgress(undefined, this.stuff, !0);

        if (!asset?.url || !this.focusedEl) return;

        this.builder.uo.saveForUndo();

        dom.addClass(this.focusedEl, 'cms-video embed-responsive');

        // todo remove old iframe before adding a new
        this.removeExistingBackgrounds(version);

        const videoIframeEl = createVideoIframe(asset.url, null, { className: `${version}-resource` });
        this.focusedEl.prepend(videoIframeEl);


        this.builder.applyBehavior();

        //save selection
        this.util.saveSelection();

        //Trigger Change event
        this.builder.opts.onChange();

        //Trigger Render event
        this.builder.opts.onRender();

        this.resetFileElement(version);

        return this;

    }

    updateImageSource(version, sourceUrl) {

        manageProgress(undefined, this.stuff, !0);

        if (!this.focusedEl) {
          return logg('focused element not found');
        }

        manageProgress(undefined, this.stuff, !0);

        this.builder.uo.saveForUndo();

        this.removeExistingBackgrounds(version);

        if (version === DESKTOP) {
          this.focusedEl.style.background = `transparent url(${sourceUrl}) no-repeat center center/cover`;
        } else if (version === MOBILE) {
          this.focusedEl.dataset[MOBILE_BACKGROUND_CLASS] = `transparent url(${sourceUrl}) no-repeat center center/cover`;
        }

        this.builder.applyBehavior();

        //save selection
        this.util.saveSelection();

        //Trigger Change event
        this.builder.opts.onChange();

        //Trigger Render event
        this.builder.opts.onRender();

        this.resetFileElement(version);

        return this;
    }

    inputByVersion(version) {

      if (version === DESKTOP) {
        return document.getElementById(this.backgroundImageInput);
      } else if (version === MOBILE) {
        return document.getElementById(this.backgroundImageInputForMobile);
      }
    }

    resetFileElement(version) {
      let input = this.inputByVersion(version);
      if (input) {
        return input.value = null;
      }
    }

    hyperlinkBgImage() {
      const element = query('.hyperlink-bg');
      const editLinkExternally = this.builder.opts?.onEditLink;
      const canEditLinkExternally = !!editLinkExternally?.call;
      
      if (element && canEditLinkExternally) element.addEventListener('click', e=> {
        try {
          e.preventDefault();

          this.builder.uo.saveForUndo();

          let foundLink = findElementParentAnchor(this.focusedEl);
          let anchor = document.createElement('A');
          anchor.className = 'd-flex w-100 h-100';
          if (!foundLink) {
            this.focusedEl.prepend(anchor);
          } else {
            anchor = this.focusedEl.querySelector('a');
          }
          
          return editLinkExternally(anchor, { type: 'image' });
           
        } catch(e) {
        }
      });
    }

    controlRatio() {
      const element = query('.control-aspect');
      const fn = this.builder.opts?.onControlRatio;
      const canFn = !!fn?.call;
      
      if (element && canFn) element.addEventListener('click', e=> {
        try {
          e.preventDefault();
          this.builder.uo.saveForUndo();
          return fn(this.focusedEl, { type: 'image' });
           
        } catch(e) {
          console.log('[controlRatio] caught ', e);
        }
      });
    }

    listenToRequestEditMode() {

        const element = query('.background-edit')
        const editElementOverlay = query('.backgroundedit')

        const closeElement = query('.backgroundedit-cancel')
        const cropElement = query('.backgroundedit-proceed', editElementOverlay)

        // open modal on click
        if (element) {
          element.addEventListener('click', e => {

            const imageUrl = this.getFocusedImageUrl() || ''
            const resolvedUrl = getResourceFromBackgroundImageUrl(imageUrl);

            if (!resolvedUrl) {
              return logg('focused element has no background image');
            }

            this.updateEditPreview(editElementOverlay, resolvedUrl);
            this.util.showModal(editElementOverlay, true);

          })
        }

        // close modal on click
        if (closeElement) {
          closeElement.addEventListener('click', e => {
            this.util.hideModal(editElementOverlay)
          })
        }


        // Set crop proportion
        const cropButtons = listify('.backgroundedit-crop button', editElementOverlay);

        if (cropButtons) {
          cropButtons.forEach(button => {
            dom.addEventListener(button, 'click', () => {
              this.cropper.setAspectRatio(button.getAttribute('data-crop-size') * 1);
            });
          });
        }


        // submit cropped data
        if (cropElement) {
          cropElement.addEventListener('click', this.onCropSaveImage.bind(this, editElementOverlay));
        }

        return this;

    }

    async updateEditPreview(parent, resolvedUrl) {

        const {width: maxWidth, height: maxHeight} = this.previewDimensions;

        try {

            const previewElement = query('.backgroundedit-preview', parent);

            if (!previewElement) {
              return logg('preview element not found');
            }

            const {width: imageWidth, height: imageHeight} = await queryImageDimenions(resolvedUrl)||{};

            if (imageWidth < maxWidth && imageHeight < maxHeight) {
                previewElement.style.width = bindUnit(imageWidth, 'px');
            } else {

                let h = maxWidth * imageHeight / imageWidth;

                if (h <= maxHeight) {
                    previewElement.style.width = bindUnit(maxWidth, 'px');
                } else {
                    previewElement.style.height = bindUnit(maxHeight, 'px');
                }
            }

            previewElement.innerHTML = `<img src="${resolvedUrl}" style="max-width:100%" />`;
            let imagePreview = query('img', parent);

            this.cropper = new Cropper(imagePreview, {
              zoomable: false,
            })

            return this;

        } catch (e) {
            logg('update edit preview - caught ', e);

            return this;
        }

    }

    onCropSaveImage(parent) {

        try {

          this.ownTool.dispatchEvent(new CustomEvent(ELEMENT_BACKGROUND_MOUNT_RESOURCE, {
              detail: {
                result: this.cropper.getCroppedCanvas({}).toDataURL(),
                type: 'image/png',
              }
          }))

          this.util.hideModal(parent);

        } catch (e) {
          logg('on crop save image - caught', e);
        }

    }

    getFocusedImageUrl() {
        return !this.focusedEl ?
            null :
            this.focusedEl.style.backgroundImage;
    }

    queryOwnTool() {
        return this.stuff.querySelector('#divBackgroundImageTool');
    }

}

export default ElementBackgroundTool;
