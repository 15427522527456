/*
import font_arial from './fonts/arial.png';
import font_courier from './fonts/courier.png';
import font_georgia from './fonts/georgia.png';
import font_monospace from './fonts/monospace.png';
import font_sans_serif from './fonts/sans_serif.png';
import font_serif from './fonts/serif.png';
import font_abel from './fonts/abel.png';
import font_abril_fatface from './fonts/abril_fatface.png';
import font_advent_pro from './fonts/advent_pro.png';
import font_aladin from './fonts/aladin.png';
import font_alegreya from './fonts/alegreya.png';
import font_alegreya_sans_sc from './fonts/alegreya_sans_sc.png';
import font_alegreya_sc from './fonts/alegreya_sc.png';
import font_alice from './fonts/alice.png';
import font_allerta_stencil from './fonts/allerta_stencil.png';
import font_allura from './fonts/allura.png';
import font_almendra_display from './fonts/almendra_display.png';
import font_amatic_sc from './fonts/amatic_sc.png';
import font_andika from './fonts/andika.png';
import font_anonymous_pro from './fonts/anonymous_pro.png';
import font_architects_daughter from './fonts/architects_daughter.png';
import font_arimo from './fonts/arimo.png';
import font_arsenal from './fonts/arsenal.png';
import font_assistant from './fonts/assistant.png';
import font_aubrey from './fonts/aubrey.png';
import font_anton from './fonts/anton.png';
import font_archivo_narrow from './fonts/archivo_narrow.png';
import font_bad_script from './fonts/bad_script.png';
import font_benchNine from './fonts/benchNine.png';
import font_bevan from './fonts/bevan.png';
import font_bigelow_rules from './fonts/bigelow_rules.png';
import font_bilbo from './fonts/bilbo.png';
import font_bonbon from './fonts/bonbon.png';
import font_bowlby_one_sc from './fonts/bowlby_one_sc.png';
import font_cabin_condensed from './fonts/cabin_condensed.png';
import font_carrois_gothic_sc from './fonts/carrois_gothic_sc.png';
import font_caveat from './fonts/caveat.png';
import font_chewy from './fonts/chewy.png';
import font_cinzel from './fonts/cinzel.png';
import font_comfortaa from './fonts/comfortaa.png';
import font_concert_one from './fonts/concert_one.png';
import font_cormorant from './fonts/cormorant.png';
import font_cormorant_garamond from './fonts/cormorant_garamond.png';
import font_cormorant_infant from './fonts/cormorant_infant.png';
import font_cormorant_sc from './fonts/cormorant_sc.png';
import font_cormorant_unicase from './fonts/cormorant_unicase.png';
import font_cousine from './fonts/cousine.png';
import font_crafty_girls from './fonts/crafty_girls.png';
import font_cuprum from './fonts/cuprum.png';
import font_cutive_mono from './fonts/cutive_mono.png';
import font_devonshire from './fonts/devonshire.png';
import font_didact_gothic from './fonts/didact_gothic.png';
import font_diplomata_sc from './fonts/diplomata_sc.png';
import font_dosis from './fonts/dosis.png';
import font_eb_garamond from './fonts/eb_garamond.png';
import font_el_messiri from './fonts/el_messiri.png';
import font_elsie from './fonts/elsie.png';
import font_encode_sans from './fonts/encode_sans.png';
import font_exo from './fonts/exo.png';
import font_exo_2 from './fonts/exo_2.png';
import font_felipa from './fonts/felipa.png';
import font_fira_code from './fonts/fira_code.png';
import font_fira_mono from './fonts/fira_mono.png';
import font_fira_sans from './fonts/fira_sans.png';
import font_fira_sans_condensed from './fonts/fira_sans_condensed.png';
import font_fira_sans_extra_condensed from './fonts/fira_sans_extra_condensed.png';
import font_fjalla_one from './fonts/fjalla_one.png';
import font_forum from './fonts/forum.png';
import font_frank_ruhl_libre from './fonts/frank_ruhl_libre.png';
import font_fredericka_the_great from './fonts/fredericka_the_great.png';
import font_gabriela from './fonts/gabriela.png';
import font_gilda_display from './fonts/gilda_display.png';
import font_give_you_glory from './fonts/give_you_glory.png';
import font_gruppo from './fonts/gruppo.png';
import font_handlee from './fonts/handlee.png';
import font_happy_monkey from './fonts/happy_monkey.png';
import font_hind from './fonts/hind.png';
import font_ibm_plex_mono from './fonts/ibm_plex_mono.png';
import font_ibm_plex_sans from './fonts/ibm_plex_sans.png';
import font_ibm_plex_serif from './fonts/ibm_plex_serif.png';
import font_iceland from './fonts/iceland.png';
import font_inconsolata from './fonts/inconsolata.png';
import font_josefin_sans from './fonts/josefin_sans.png';
import font_istok_web from './fonts/istok_web.png';
import font_julee from './fonts/julee.png';
import font_julius_sans_one from './fonts/julius_sans_one.png';
import font_junge from './fonts/junge.png';
import font_jura from './fonts/jura.png';
import font_just_me_again_down_here from './fonts/just_me_again_down_here.png';
import font_kaushan_script from './fonts/kaushan_script.png';
import font_kelly_slab from './fonts/kelly_slab.png';
import font_kite_one from './fonts/kite_one.png';
import font_kosugi from './fonts/kosugi.png';
import font_kosugi_maru from './fonts/kosugi_maru.png';
import font_kurale from './fonts/kurale.png';
import font_lato from './fonts/lato.png';
import font_ledger from './fonts/ledger.png';
import font_lekton from './fonts/lekton.png';
import font_life_savers from './fonts/life_savers.png';
import font_literata from './fonts/literata.png';
import font_lobster from './fonts/lobster.png';
import font_lobster_two from './fonts/lobster_two.png';
import font_londrina_shadow from './fonts/londrina_shadow.png';
import font_lora from './fonts/lora.png';
import font_lovers_quarrel from './fonts/lovers_quarrel.png';
import font_m_plus_1p from './fonts/m_plus_1p.png';
import font_m_plus_rounded_1c from './fonts/m_plus_rounded_1c.png';
import font_macondo from './fonts/macondo.png';
import font_marcellus_sc from './fonts/marcellus_sc.png';
import font_marck_script from './fonts/marck_script.png';
import font_martel from './fonts/martel.png';
import font_maven_pro from './fonts/maven_pro.png';
import font_merriweather from './fonts/merriweather.png';
import font_merriweather_sans from './fonts/merriweather_sans.png';
import font_mogra from './fonts/mogra.png';
import font_monoton from './fonts/monoton.png';
import font_montez from './fonts/montez.png';
import font_montserrat from './fonts/montserrat.png';
import font_montserrat_alternates from './fonts/montserrat_alternates.png';
import font_montserrat_subrayada from './fonts/montserrat_subrayada.png';
import font_neucha from './fonts/neucha.png';
import font_neuton from './fonts/neuton.png';
import font_nixie_one from './fonts/nixie_one.png';
import font_nothing_you_could_do from './fonts/nothing_you_could_do.png';
import font_noto_sans from './fonts/noto_sans.png';
import font_noto_sans_sc from './fonts/noto_sans_sc.png';
import font_noto_serif from './fonts/noto_serif.png';
import font_noto_serif_tc from './fonts/noto_serif_tc.png';
import font_nunito from './fonts/nunito.png';
import font_old_standard_tt from './fonts/old_standard_tt.png';
import font_open_sans from './fonts/open_sans.png';
import font_open_sans_condensed from './fonts/open_sans_condensed.png';
import font_oranienbaum from './fonts/oranienbaum.png';
import font_oswald from './fonts/oswald.png';
import font_oxygen from './fonts/oxygen.png';
import font_pacifico from './fonts/pacifico.png';
import font_pangolin from './fonts/pangolin.png';
import font_passion_one from './fonts/passion_one.png';
import font_pathway_gothic_one from './fonts/pathway_gothic_one.png';
import font_pattaya from './fonts/pattaya.png';
import font_petit_formal_script from './fonts/petit_formal_script.png';
import font_philosopher from './fonts/philosopher.png';
import font_play from './fonts/play.png';
import font_playfair_display from './fonts/playfair_display.png';
import font_playfair_display_sc from './fonts/playfair_display_sc.png';
import font_podkova from './fonts/podkova.png';
import font_poiret_one from './fonts/poiret_one.png';
import font_pompiere from './fonts/pompiere.png';
import font_poppins from './fonts/poppins.png';
import font_prata from './fonts/prata.png';
import font_press_start_2p from './fonts/press_start_2p.png';
import font_prosto_one from './fonts/prosto_one.png';
import font_pt_mono from './fonts/pt_mono.png';
import font_pt_sans from './fonts/pt_sans.png';
import font_pt_sans_caption from './fonts/pt_sans_caption.png';
import font_pt_sans_narrow from './fonts/pt_sans_narrow.png';
import font_pt_serif from './fonts/pt_serif.png';
import font_pt_serif_caption from './fonts/pt_serif_caption.png';
import font_quattrocento_sans from './fonts/quattrocento_sans.png';
import font_quattrocento from './fonts/quattrocento.png';
import font_quicksand from './fonts/quicksand.png';
import font_qwigley from './fonts/qwigley.png';
import font_raleway from './fonts/raleway.png';
import font_raleway_dots from './fonts/raleway_dots.png';
import font_redressed from './fonts/redressed.png';
import font_ribeye_marrow from './fonts/ribeye_marrow.png';
import font_righteous from './fonts/righteous.png';
import font_roboto from './fonts/roboto.png';
import font_roboto_condensed from './fonts/roboto_condensed.png';
import font_roboto_mono from './fonts/roboto_mono.png';
import font_roboto_slab from './fonts/roboto_slab.png';
import font_rochester from './fonts/rochester.png';
import font_rouge_script from './fonts/rouge_script.png';
import font_rubik from './fonts/rubik.png';
import font_rubik_mono_one from './fonts/rubik_mono_one.png';
import font_ruslan_display from './fonts/ruslan_display.png';
import font_russo_one from './fonts/russo_one.png';
import font_sacramento from './fonts/sacramento.png';
import font_sanchez from './fonts/sanchez.png';
import font_satisfy from './fonts/satisfy.png';
import font_sawarabi_gothic from './fonts/sawarabi_gothic.png';
import font_scada from './fonts/scada.png';
import font_seaweed_script from './fonts/seaweed_script.png';
import font_seymour_one from './fonts/seymour_one.png';
import font_shadows_into_light_two from './fonts/shadows_into_light_two.png';
import font_six_caps from './fonts/six_caps.png';
import font_snowburst_one from './fonts/snowburst_one.png';
import font_source_code_pro from './fonts/source_code_pro.png';
import font_source_sans_pro from './fonts/source_sans_pro.png';
import font_special_elite from './fonts/special_elite.png';
import font_spectral from './fonts/spectral.png';
import font_spectral_sc from './fonts/spectral_sc.png';
import font_squada_one from './fonts/squada_one.png';
import font_stalinist_one from './fonts/stalinist_one.png';
import font_stint_ultra_expanded from './fonts/stint_ultra_expanded.png';
import font_syncopate from './fonts/syncopate.png';
import font_tangerine from './fonts/tangerine.png';
import font_tenor_sans from './fonts/tenor_sans.png';
import font_tinos from './fonts/tinos.png';
import font_ubuntu from './fonts/ubuntu.png';
import font_ubuntu_condensed from './fonts/ubuntu_condensed.png';
import font_ubuntu_mono from './fonts/ubuntu_mono.png';
import font_underdog from './fonts/underdog.png';
import font_unifrakturmaguntia from './fonts/unifrakturmaguntia.png';
import font_vast_shadow from './fonts/vast_shadow.png';
import font_viga from './fonts/viga.png';
import font_vollkorn from './fonts/vollkorn.png';
import font_vollkorn_sc from './fonts/vollkorn_sc.png';
import font_voltaire from './fonts/voltaire.png';
import font_wire_one from './fonts/wire_one.png';
import font_yanone_kaffeesatz from './fonts/yanone_kaffeesatz.png';
import font_yeseva_one from './fonts/yeseva_one.png';
*/


export const ELEMENT_BACKGROUND_MOUNT_RESOURCE = 'element--mount-resource';
export const ELEMENT_BACKGROUND_NO_ACTIVE_SELECTION = 'element--without-background';
export const ELEMENT_BACKGROUND_ACTIVE_SELECTION = 'element--with-background';
export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';
export const MOBILE_BACKGROUND_CLASS = 'mobileBackground';
export const TOOLS_MOUNTED_CUSTOM_EVENT = 'tools--mounted';
export const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i;
export const localhostRegex = /http(s?)\:[\/]{2}(localhost|127\.0\.0\.1|0\.0\.0\.0)/i;

export const videoMimetypes = [
    'video/mp4',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/mp4',
    'video/webm',
    'video/mpeg',
    'video/mp2t',
    'video/ogg',
];

const isDevelopment = localhostRegex.test(location.href);

export function logConditionally(dev) {

    return dev ?
        console.log.bind(console) :
        () => undefined;
}

export const logg = logConditionally(isDevelopment)

export function typeOf(...arg) {

  if (!arguments[0]) return !1;
  if (!arguments[1]) return Object.prototype.toString.call(arguments[0]).slice(8, -1).toLowerCase()

  return Object.prototype.toString.call(arguments[0]).slice(8, -1).toLowerCase() === arguments[1]

}

export function pickFilesThroughBrowser(document, accept, multiple) {

    return new Promise((resolve) => {

        let input = document.createElement('input');

        input.type = 'file';
        input.multiple = multiple || false;
        input.accept = !accept?.length ?
            '*' : accept.join(',');

        input.onchange = () =>
            resolve(input.files);

        return input.click();

    });

}

export function applyStylesToElement(element, styles) {

    if (!element || !styles) return element

    Object
        .entries(styles)
        .forEach(([key, value]) =>
            element.style[key] = value)

    return element
}

export function isAChildOf(_parent, _child) {
    if (_parent === _child) {
        return false;
    }
    while (_child && _child !== _parent) {
        _child = _child.parentNode;
    }

    return _child === _parent;
}

export function elementBelongsTo(_parent, _child) {
    if (_parent === _child) return !0

    while (_child && _child !== _parent)
        _child = _child.parentNode
    return _child === _parent;
}


export function bindUnit(value, unit) {

    return `${value}${unit}`;
}

export function getResourceFromBackgroundImageUrl(url) {

    return !url?.length || url === '' ?
        undefined :
        url.slice(4, -1).replace(/["']/g, '');
}

export function queryCellBackgroundImage(cell) {

    if (!cell) return undefined;

    const {backgroundImage: cellBackgroundImage} = cell.style || {};
    const {backgroundImage: closestColumnBackgroundImage} = !cell.querySelector('.column[style]') ?
        {} :
        (cell.querySelector('.column[style]').style || {});

    return {
        backgroundImage: cellBackgroundImage || closestColumnBackgroundImage || null,
        get isColumnBackgroundImage() {
            return this.backgroundImage === closestColumnBackgroundImage;
        }
    };

}

export function encapsulateIntoSection(html) {

    const section = document.createElement('section');
    section.innerHTML = html.trim();

    return section;

}


export function queryClosestColumn(cols, e, requestedCol) {

    const targetClosestModule = e?.target.closest('[data-module]');

    // in case there is no module
    if (!targetClosestModule) return requestedCol;

    const {module: closestModuleCode} = targetClosestModule.dataset || {};

    return !cols?.length ?
        requestedCol :
        (cols.find(a => a.dataset?.module === closestModuleCode) || requestedCol);

}

export function queryAllModules(dom, row) {

    try {
        return Array.from(new Set([
            ...dom.elementChildren(row),
            ...Array.from(row.querySelectorAll('[data-module]'))]));
    } catch (e) {
        return dom.elementChildren(row);
    }

}

export function changeCellBackgroundImageByUrl(cell, imageUrl) {

    const {isColumnBackgroundImage} = queryCellBackgroundImage(cell);
    const elementHoldingBg = !isColumnBackgroundImage ?
        cell :
        cell.querySelector('.column[style]');

    if (elementHoldingBg) elementHoldingBg.style.backgroundImage = `url(${imageUrl})`;

    return elementHoldingBg;
}

export function changeColumnSettingsBgImage(cellSettings, imageUrl) {

    const image = cellSettings.querySelector('.cell-bgimage-preview img');

    if (image) image.src = imageUrl;

    return image;
}

export function query(selector, el) {

    return !el ?
        document.querySelector(selector) :
        el.querySelector(selector);
}

export function listify(selector, el) {

    return !el ?
        Array.from(document.querySelectorAll(selector)) :
        Array.from(el.querySelectorAll(selector));

}

export function generateRandomText() {

    const randomText = () =>
        Math.random().toString(36).slice(2);

    return [randomText(), randomText()].join('');
}


export function createElementByTagName(tag, className, htmlContent, id) {

    const element = document.createElement(tag?.toUpperCase());

    // assign class name
    if (className) element.className = className;

    // assign id
    if (id) element.id = id;

    // assign innerHtml
    element.innerHTML = htmlContent;

    return element;

}

export function createVideoIframe(url, embedConfig = null, opts={}) {
    // TODO we need to do something elegant about hiding UI
    let config = embedConfig || 'autoplay=true&controlsVisibleOnLoad=false&playbar=false&endVideoBehavior=loop&playButton=false&volumeControl=false&fullscreenButton=false&qualityControl=false&playPauseNotifier=false&playbackRateControl=false&playerColor=000000';

    const el = document.createElement('iframe');

    el.setAttribute('src', `${url}?${config}`);
    el.setAttribute('allowfullscreen', '');
    el.setAttribute('allow', 'autoplay; fullscreen');
    el.setAttribute('frameborder', '0');
    el.setAttribute('scrolling', 'no');

    el.className = 'embed-responsive-item ' + opts?.className;

    return el;
}

export function createVideoIframeElement(url, embedConfig = null) {
    const el = document.createElement('div');
    el.className = 'cms-video embed-responsive embed-responsive-16by9';

    el.prepend(createVideoIframe(url, embedConfig));

    return el;
}

export function queryImageDimenions(url) {

    return new Promise((resolve, reject) => {

        const divId = generateRandomText();
        const div = document.createElement('DIV');

        // referenced to be removed later
        div.id = divId;

        // ensure no flashing content
        div.style.opacity = 0;
        div.style.visibility = 'hidden';

        const img = document.createElement('IMG');

        img.id = generateRandomText();
        img.src = url;
        img.onload = e => {

            const {offsetWidth: width, offsetHeight: height} = e.target || {};

            document.getElementById(divId)?.remove();

            return resolve({width, height});

        };

        img.onerror = e => reject(e);

        // append image to div
        div.appendChild(img);

        // append div to body
        return document.body.appendChild(div);

    });

}

export function manageProgress(element, tools, shouldHide = false) {

	let imageProgress = tools?.querySelector('#divImageProgress');
	
	if ( !element || shouldHide ) return imageProgress.style.display = 'none';

	logg( 'manage progress for ', element );
	
	const { pageXOffset, pageYOffset, scrollX, scrollY } = window || {};
	const { top, left } = element?.getBoundingClientRect() || {};

	const windowScrollX = scrollX || pageXOffset;
	const windowScrollY = scrollY || pageYOffset;

	return applyStylesToElement(imageProgress, {
		display: 'table',
		width: bindUnit(element.offsetWidth, 'px'),
		height: bindUnit(element.offsetHeight, 'px'),
		top: bindUnit((top + windowScrollY), 'px'),
		left: bindUnit(left + windowScrollX, 'px')
	});

}

export function getDocumentSelectionRef() {

  const s = window.getSelection || document.getSelection;
  const isSupported = !!s && typeOf(s, 'function')

  if ( !isSupported ) return undefined;

  return s

}

// mutates DOM
export function surroundSelectionByElement(tagName, opts = null) {

  let surroundingEl = document.createElement(tagName);

  if (opts) Object
    .entries(opts)
    .forEach(([key, value])=> 
      surroundingEl[key] = value);

  const selection = getDocumentSelectionRef()();

  if (!selection) return undefined;

  const range = selection.getRangeAt(0);
  const parentElement = range.commonAncestorContainer?.parentNode;

  // mutating function
  range.surroundContents(surroundingEl);

  try {

    // get anchor after mutation
    return parentElement.querySelector( 'a' );

  } catch(e) {

    // older browsers (ie 9) might crash
    return undefined;
  }

}

export function createSelectionRangeFromElement(element, canRemoveOtherSelections) {

  if (!element) return undefined

  const selection = getDocumentSelectionRef()();

  if (!selection) return undefined;

  const range = document.createRange();

  range.setStartBefore(element);
  range.setEndAfter(element);

  if (canRemoveOtherSelections) selection.removeAllRanges();

  selection.addRange(range);

  return range;

}

export function findElementParentAnchor(elm) {
    
  if (!elm) return null;
  let element = elm;
  
  while (element.nodeName.toLowerCase() !== 'a') {
      if (element.tagName === 'BODY' || element.tagName === 'HTML') break;
      if (element.parentNode) element = element.parentNode;
  }

  if (element.nodeName.toLowerCase() === 'a') return element;
  return null;
}

export function surroundElementBy(tagName, host, opts) {

  if ( !host ) return;

  const hostClone = host.cloneNode();
  let elm = document.createElement(tagName);

  if (opts) Object.entries(opts).forEach(([key, value])=> elm[key] = value);

  elm.prepend(hostClone);
  host.replaceWith(elm);

  return elm;
}


export class Util {

    constructor(builder) {
        this.builder = builder;

        this.dom = new Dom();
    }

    cellSelected() {
        return document.querySelector('.cell-active');
    }

    builderStuff() {

        return document.querySelector('#_cbhtml')

    }

    cellNext(cell) {
        const dom = this.dom;
        let c = cell.nextElementSibling;
        if (c) {
            if (!dom.hasClass(c, 'is-row-tool') && !dom.hasClass(c, 'is-rowadd-tool') && !dom.hasClass(c, 'is-row-overlay')) {
                return c;
            } else {
                return null;
            }
        }
        return null;
    }

    out(s) {
        if (this.builder) {
            let val = this.builder.opts.lang[s];
            if (val) return val;
            else {
                if (this.builder.checkLang) console.log(s);
                return s;
            }
        } else {
            return s;
        }
    }

    confirm(message, callback) {

        const dom = this.dom;
        let html = `<div class="is-modal is-confirm">
            <div class="is-modal-content" style="padding-bottom:20px;">
                <p>${message}</p>
                <button title="${this.out('Delete')}" class="input-ok classic">${this.out('Delete')}</button>
            </div>
        </div>`;

        const builderStuff = this.builder.builderStuff;

        let confirmmodal = builderStuff.querySelector('.is-confirm');
        if (!confirmmodal) {
            dom.appendHtml(builderStuff, html);
            confirmmodal = builderStuff.querySelector('.is-confirm');
        }

        this.showModal(confirmmodal, false, () => {

            //this function runs when overlay is clicked. Remove modal.
            confirmmodal.parentNode.removeChild(confirmmodal);

            //do task
            callback(false);

        }, true);

        let buttonok = confirmmodal.querySelector('.is-confirm .input-ok');
        dom.addEventListener(buttonok, 'click', () => {

            this.hideModal(confirmmodal);
            confirmmodal.parentNode.removeChild(confirmmodal); //remove modal

            //do task
            callback(true);
        });
    }

    /*
    Note:
    - if overlayStay = false, cancelCallback will be called if overlay is clicked.
    - hideModal will remove the modal element, so calling show modal multiple times won't attach multiple events (safe).
    */
    showModal(modal, overlayStay, cancelCallback, animated) {
        const dom = this.dom;
        dom.addClass(modal, 'active');

        /* Disable Modal Animation */
        let animate = false;
        if (this.builder) {
            if (this.builder.opts.animateModal) {
                animate = true;
                if (!animated) { // if not set or false
                    animate = false; // overide
                }
            }
        } else {
            if (animated) { // if set true
                animate = true; // overide
            }
        }

        if (animate) {
            if (this.builder) {
                const buildercontainers = document.querySelectorAll(this.builder.opts.container);
                Array.prototype.forEach.call(buildercontainers, (buildercontainer) => {
                    // buildercontainer.style.transform = 'scale(0.98)';
                    // buildercontainer.style.WebkitTransform= 'scale(0.98)';
                    // buildercontainer.style.MozTransform= 'scale(0.98)';
                    buildercontainer.style.transform = `scale(${this.builder.opts.zoom - 0.02})`;
                    buildercontainer.style.WebkitTransform = `scale(${this.builder.opts.zoom - 0.02})`;
                    buildercontainer.style.MozTransform = `scale(${this.builder.opts.zoom - 0.02})`;
                    buildercontainer.setAttribute('scaled-down', '1');
                });
            }
        }

        if (!modal.querySelector('.is-modal-overlay')) {

            let html;
            if (overlayStay) {
                html = '<div class="is-modal-overlay overlay-stay"></div>';
            } else {
                html = '<div class="is-modal-overlay"></div>';
            }

            modal.insertAdjacentHTML('afterbegin', html);

            if (!overlayStay) {
                let overlay = modal.querySelector('.is-modal-overlay');
                dom.addEventListener(overlay, 'click', () => {

                    //cancelCallback
                    if (cancelCallback) cancelCallback();

                    this.hideModal(modal);
                });
            }
        }
    }

    hideModal(modal) {

        /* Disable Modal Animation */
        if (this.builder) {
            const buildercontainers = document.querySelectorAll(this.builder.opts.container);
            Array.prototype.forEach.call(buildercontainers, (buildercontainer) => {
                // buildercontainer.style.transform = '';
                // buildercontainer.style.WebkitTransform= '';
                // buildercontainer.style.MozTransform= '';
                if (buildercontainer.getAttribute('scaled-down')) {
                    buildercontainer.style.transform = `scale(${this.builder.opts.zoom})`;
                    buildercontainer.style.WebkitTransform = `scale(${this.builder.opts.zoom})`;
                    buildercontainer.style.MozTransform = `scale(${this.builder.opts.zoom})`;
                    buildercontainer.removeAttribute('scaled-down');
                }
            });
        }

        const dom = this.dom;
        dom.removeClass(modal, 'active');
    }

    // attachInputTextClear(inp) {
    //     const dom = this.dom;

    //     inp.addEventListener('click', ()=>{
    //         dom.addClass(inp.parentNode, 'focus'); 
    //     });
    //     inp.addEventListener('blur', (e)=>{
    //         setTimeout(()=>{
    //             dom.removeClass(inp.parentNode, 'focus');
    //         },400);
    //     });
    //     const btnClear = inp.parentNode.querySelector('.input-clear');
    //     if(btnClear) btnClear.addEventListener('click', ()=> {
    //         inp.value = '';
    //         dom.removeClass(inp.parentNode, 'focus');
    //         inp.focus();
    //     });
    // }

    fixLayout(row) {
        const dom = this.dom;

				if ( !dom ) return 

        let num = 2; //is-row-tool & is-rowadd-tool
        if (row.querySelector('.is-row-overlay')) {
            num = 3; //is-row-tool, is-rowadd-tool & is-row-overlay
        }

        const cellCount = row.childElementCount - num; //minus is-row-tool & is-rowadd-tool

        const rowClass = this.builder.opts.row;
        const colClass = this.builder.opts.cols;
        const colEqual = this.builder.opts.colequal;

        if (colEqual.length > 0) {

            // const cols = dom.elementChildren(row);
            const cols = dom.queryAllModules(dom, row);

            // safety check
            cols?.forEach((col) => {

                if (dom.hasClass(col, 'is-row-tool') || dom.hasClass(col, 'is-rowadd-tool') || dom.hasClass(col, 'is-row-overlay')) return;

                for (let i = 0; i <= colClass.length - 1; i++) {
                    dom.removeClass(col, colClass[i]);
                }

                for (let i = 0; i <= colEqual.length - 1; i++) {
                    if (colEqual[i].length === cellCount) {
                        dom.addClass(col, colEqual[i][0]);
                        break;
                    }
                }

                if (cellCount === 1) {
                    dom.addClass(col, colClass[colClass.length - 1]);
                }

            });

            return;
        }

        //others (12 columns grid)
        if (rowClass !== '' && colClass.length > 0) {
            let n = 0;

            // const cols = dom.elementChildren(row);
            const cols = dom.queryAllModules(dom, row);

            // safety check
            cols?.forEach((col) => {

                if (dom.hasClass(col, 'is-row-tool') || dom.hasClass(col, 'is-rowadd-tool') || dom.hasClass(col, 'is-row-overlay')) return;

                n++;
                for (var i = 0; i <= colClass.length - 1; i++) {
                    dom.removeClass(col, colClass[i]);
                }

                if (cellCount === 1) dom.addClass(col, colClass[11]);
                if (cellCount === 2) dom.addClass(col, colClass[5]);
                if (cellCount === 3) dom.addClass(col, colClass[3]);
                if (cellCount === 4) dom.addClass(col, colClass[2]);
                if (cellCount === 5) { // 2, 2, 2, 2, 4
                    if (n === 5) dom.addClass(col, colClass[3]);
                    else dom.addClass(col, colClass[1]);
                }
                if (cellCount === 6) dom.addClass(col, colClass[1]); // 2, 2, 2, 2, 2, 2


                if (cellCount === 7) { // 2, 2, 2, 2, 2, 1, 1
                    if (n >= 6) dom.addClass(col, colClass[0]);
                    else dom.addClass(col, colClass[1]);
                }
                if (cellCount === 8) { // 2, 2, 2, 2, 1, 1, 1, 1
                    if (n >= 5) dom.addClass(col, colClass[0]);
                    else dom.addClass(col, colClass[1]);
                }
                if (cellCount === 9) { // 2, 2, 2, 1, 1, 1, 1, 1, 1
                    if (n >= 4) dom.addClass(col, colClass[0]);
                    else dom.addClass(col, colClass[1]);
                }
                if (cellCount === 10) { // 2, 2, 1, 1, 1, 1, 1, 1, 1, 1
                    if (n >= 3) dom.addClass(col, colClass[0]);
                    else dom.addClass(col, colClass[1]);
                }
                if (cellCount === 11) { // 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1
                    if (n >= 2) dom.addClass(col, colClass[0]);
                    else dom.addClass(col, colClass[1]);
                }
                if (cellCount === 12) dom.addClass(col, colClass[0]); // 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1

            });

        }

    }

    addContent(html, mode, attr) {
        const dom = this.dom;

        if (this.builder.opts.onAdd) {
            html = this.builder.opts.onAdd(html);
        }

        const cell = this.cellSelected();
        let row;
        if (!cell) {
            // If no active cell, check if it is from .row-add-initial (empty info)
            row = document.querySelector('.row-active');
            if (!row) return;
            else {
                // Empty content will always use 'row' mode to insert block.
                mode = 'row';
            }
        } else {
            row = cell.parentNode;
        }

        if (mode === 'cell' || mode === 'cell-left' || mode === 'cell-right') {

            let maxCols = 4;
            if (this.builder.maxColumns) {
                maxCols = this.builder.maxColumns;
            }
            //Limit up to 4 cells in a row

            let num = 2; //is-row-tool & is-rowadd-tool
            if (row.querySelector('.is-row-overlay')) {
                num = 3; //is-row-tool, is-rowadd-tool & is-row-overlay
            }

            if (row.childElementCount >= maxCols + num) { //+3 => includes is-row-tool, is-rowadd-tool & is-row-overlay
                alert(this.out('You have reached the maximum number of columns'));
                return false;
            }

            this.builder.uo.saveForUndo();

            let cellElement;

            if (this.builder.opts.row === '') {

                // TODO: Test using in old Insite
                let s = this.builder.opts.cellFormat;
                let pos = s.indexOf('</');
                html = s.substring(0, pos) + html + s.substring(pos);

                cellElement = this.createElementFromHTML(html);

            } else {

                cellElement = cell.cloneNode(true);

                // Cleanup from module related clone
                cellElement.removeAttribute('data-noedit');
                cellElement.removeAttribute('data-protected');
                cellElement.removeAttribute('data-module');
                cellElement.removeAttribute('data-module-desc');
                cellElement.removeAttribute('data-dialog-width');
                cellElement.removeAttribute('data-html');
                cellElement.removeAttribute('data-settings');
                for (let i = 1; i <= 20; i++) {
                    cellElement.removeAttribute('data-html-' + i);
                }
                cellElement.removeAttribute('data-noedit');

                dom.removeClass(cellElement, 'cell-active');
                cellElement.removeAttribute('data-click');

                if (attr) {
                    cellElement.setAttribute(attr, '');
                }

                cellElement.innerHTML = html;

            }

            row.insertBefore(cellElement, cell);
            if (mode === 'cell' || mode === 'cell-right') {
                dom.moveAfter(cellElement, cell);
            }

            this.builder.applyBehavior();

            this.fixLayout(row);

            cellElement.click(); //change active block to the newly created

        }

        if (mode === 'row') {

            this.builder.uo.saveForUndo();

            let rowElement, cellElement;

            if (this.builder.opts.row === '') {
                rowElement = this.htmlToElement(this.builder.opts.rowFormat);

                let s = this.builder.opts.cellFormat;
                let pos = s.indexOf('</');
                html = s.substring(0, pos) + html + s.substring(pos);

                // go to last deeper level
                let targetrow = dom.elementChildren(rowElement);
                while (targetrow.length > 0) {
                    targetrow = targetrow[0];
                    if (dom.elementChildren(targetrow).length > 0) {
                        targetrow = dom.elementChildren(targetrow);
                    } else {
                        break;
                    }
                }
                targetrow.innerHTML = html;

                cellElement = targetrow.firstChild;
                if (attr) {
                    cellElement.setAttribute(attr, '');
                }

            } else {

                cellElement = dom.createElement('div');

                dom.addClass(cellElement, this.builder.opts.cols[this.builder.opts.cols.length - 1]);
                cellElement.innerHTML = html;

                if (attr) {
                    cellElement.setAttribute(attr, '');
                }

                rowElement = dom.createElement('div');
                dom.addClass(rowElement, this.builder.opts.row);
                dom.appendChild(rowElement, cellElement);

            }

            row.parentNode.insertBefore(rowElement, row);
            dom.moveAfter(rowElement, row);

            this.builder.applyBehavior();

            cellElement.click(); //change active block to the newly created

        }

        if (mode === 'elm') {

            let elm = this.builder.activeElement; // See elementtool.js line 195-196. // document.querySelector('.elm-active');
            if (!elm) return;

            this.builder.uo.saveForUndo();

            let element = elm;
            // while(!dom.hasClass(element.parentNode, 'cell-active')) {
            //     element = element.parentNode;
            // }
            element.insertAdjacentHTML('afterend', html);

            this.builder.applyBehavior();

            let newelement = element.nextElementSibling;

            if (newelement.tagName.toLowerCase() === 'img') {
                var timeoutId;
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => {
                    if (newelement.complete) {
                        newelement.click();
                        //console.log(2);
                    }
                }, 200);
            } else {
                newelement.click();
            }


            // LATER: auto scroll

            // LATER: If image, then it needs time to load (resulting incorrect position), so hide element tool. 

        }

        // Call onChange
        this.builder.opts.onChange();

    }

    // https://stackoverflow.com/questions/494143/creating-a-new-dom-element-from-an-html-string-using-built-in-dom-methods-or-pro
    htmlToElement(html) {
        var template = document.createElement('template');
        html = html.trim(); // Never return a text node of whitespace as the result
        template.innerHTML = html;
        return template.content.firstChild;
    }

    addSnippet(html, bSnippet, noedit) {

        this.builder.uo.saveForUndo();

        const dom = this.dom;
        let rowElement;
        let bAddLast = false;
        let cell;
        let cellElement;
        let columnTool;

        const builderStuff = this.builder.builderStuff;
        let quickadd = builderStuff.querySelector('.quickadd');
        const mode = quickadd.getAttribute('data-mode');

        if (bSnippet && (mode === 'cell' || mode === 'cell-left' || mode === 'cell-right')) {

            if (noedit) {
                this.addContent(html, mode, 'data-noedit');
            } else {
                this.addContent(html, mode);
            }
            return;

        } else if (bSnippet && mode === 'row') {

            /*
            Buttons, line, social, video, map (Grid layout not included).
            Can be inserted after current row, cell, element, or last row.
            */

            // NEW: See contentbuilder.js line 328
            // OLD: See contentbuilder-jquery.js addSnippet() line 16529

            // Just snippet (without row/column grid), ex. buttons, line, social, video, map.
            // Can be inserted after current row, column (cell), element, or last row.

            // html = `<div class="${this.builder.opts.row}"><div class="${this.builder.opts.cols[this.builder.opts.cols.length-1]}"${(noedit? ' data-noedit': '')}>${html}</div></div>`;
            // OR like addContent() in util.js line 245)

            cellElement = document.createElement('div');
            cellElement.className = this.builder.opts.cols[this.builder.opts.cols.length - 1];
            cellElement.innerHTML = html;
            if (noedit) {
                cellElement.setAttribute('data-noedit', '');
            }

            rowElement = document.createElement('div');
            rowElement.className = this.builder.opts.row;
            rowElement.appendChild(cellElement);

            // Add after selected row
            cell = this.builder.cellSelected();
            let row;
            if (cell) {
                row = cell.parentNode;
            } else {
                // If no active cell, check if it is from .row-add-initial (empty info)
                row = document.querySelector('.row-active');
                if (!row) {
                    bAddLast = true;
                }
            }
            // Add after last row
            if (bAddLast) {
                const nodes = document.querySelectorAll('.is-builder');
                const last = nodes[nodes.length - 1];
                const rows = dom.elementChildren(last);
                const lastrow = rows[rows.length - 1];
                row = lastrow;
            }

            row.parentNode.insertBefore(rowElement, row);
            dom.moveAfter(rowElement, row);

            this.builder.applyBehavior();

            cellElement.click(); //change active block to the newly created

            // Change to row selection
            rowElement.className = rowElement.className.replace('row-outline', '');
            // columnTool = parent.document.querySelector('.is-column-tool');
            columnTool = document.querySelector('.is-column-tool');
            columnTool.className = columnTool.className.replace('active', '');

        } else if (bSnippet) {

            if (noedit) {
                this.addContent(html, mode, 'data-noedit');
            } else {
                this.addContent(html, mode);
            }
            return;

        } else {

            /*
            Complete with grid layout. Also may containes custom script(data-html)
            Can be inserted after current row or last row.
            */

            // NEW: See contentbuilder.js line 341 AND contentbuilder-jquery.js (addContentMore) line 11526
            // OLD: See contentbuilder-jquery.js (addContentMore) line 11526

            // Snippet is wrapped in row/colum (may contain custom code or has [data-html] attribute)
            // Can only be inserted after current row or last row (not on column or element).

            var snippet = document.createElement('div');
            snippet.innerHTML = html;
            var blocks = snippet.querySelectorAll('[data-html]');
            Array.prototype.forEach.call(blocks, (block) => {

                // Render custom code block
                html = decodeURIComponent(block.getAttribute('data-html'));
                html = html.replace(/{id}/g, this.makeId());
                for (var i = 1; i <= 20; i++) {
                    html = html.replace('[%HTML' + i + '%]', (block.getAttribute('data-html-' + i) === undefined ? '' : decodeURIComponent(block.getAttribute('data-html-' + i))));//render editable area
                }
                block.innerHTML = html;

            });

            //html = snippet.innerHTML; 

            // NEW: This allows snippets with complete row & column format (should be single row/col) can be added as a column
            if (snippet.childNodes.length === 1) if (snippet.childNodes[0].childNodes.length === 1 && (mode === 'cell' || mode === 'cell-left' || mode === 'cell-right')) {

                // this.addContent(html, mode, 'data-noedit');

                const cell = this.cellSelected();
                let row = cell.parentNode;

                let maxCols = 4;
                if (this.builder.maxColumns) {
                    maxCols = this.builder.maxColumns;
                }
                //Limit up to 4 cells in a row
                if (row.querySelector('.is-row-overlay')) {
                    if (row.childElementCount >= maxCols + 3) { //+3 => includes is-row-tool, is-rowadd-tool & is-row-overlay
                        alert(this.out('You have reached the maximum number of columns'));
                        return false;
                    }
                } else {
                    if (row.childElementCount >= maxCols + 2) { //+2 => includes is-row-tool & is-rowadd-tool
                        alert(this.out('You have reached the maximum number of columns'));
                        return false;
                    }
                }

                this.builder.uo.saveForUndo();

                let cellElement = snippet.childNodes[0].childNodes[0];

                // row.insertBefore(cellElement, cell);
                var range = document.createRange();
                cell.parentNode.insertBefore(range.createContextualFragment(cellElement.outerHTML), cell);
                if (mode === 'cell' || mode === 'cell-right') {
                    // dom.moveAfter(cellElement, cell);
                    cell.parentNode.insertBefore(cell.previousElementSibling, cell);
                    cell.parentNode.insertBefore(cell, cell.previousElementSibling);
                }

                this.builder.applyBehavior();

                this.fixLayout(row);

                cellElement.click(); //change active block to the newly created

                // Call onChange
                this.builder.opts.onChange();

                return;
            }

            // Add after selected row
            cell = this.builder.activeCol;
            let row;
            if (cell) {
                row = cell.parentNode; // in email mode, cell active is also under row active (incorrect, but cell active is not needed in email mode. So this line works!)
            } else {
                // If no active cell, check if it is from .row-add-initial (empty info)
                row = document.querySelector('.row-active');
                if (!row) {
                    bAddLast = true;
                }
            }
            // Add after last row
            if (bAddLast) {
                const nodes = document.querySelectorAll('.is-builder');
                const last = nodes[nodes.length - 1];
                const rows = dom.elementChildren(last);
                const lastrow = rows[rows.length - 1];
                row = lastrow;
            }

            // Use createContextualFragment() to make embedded script executable
            // https://ghinda.net/article/script-tags/
            range = document.createRange();
            row.parentNode.insertBefore(range.createContextualFragment(snippet.innerHTML), row.nextSibling);
            rowElement = snippet.childNodes[0];

            // Auto scroll
            const y = row.getBoundingClientRect().top + row.offsetHeight + window.pageYOffset - 120;
            window.scroll({
                top: y,
                behavior: 'smooth'
            });
            // window.scrollTo(0, y);

            rowElement = row.nextElementSibling; // a must. Must be before applyBehavior() to prevent element delete during fixLayout

            this.builder.applyBehavior(); // checkEmpty & onRender called here

            const _childNodes = Array.from(rowElement.childNodes);
            const [firstClickableElement,] = !_childNodes?.length ?
                [] :
                _childNodes.filter(a => a instanceof HTMLElement) || [];

            firstClickableElement?.click() //change active block to the newly created

            // Change to row selection
            rowElement.className = rowElement.className.replace('row-outline', '');
            // columnTool = parent.document.querySelector('.is-column-tool');
            columnTool = document.querySelector('.is-column-tool');
            columnTool.className = columnTool.className.replace('active', '');

        }

        // Call onChange
        this.builder.opts.onChange();

    }

    clearActiveCell() {

        // this.builder.lastActiveCol = this.cellSelected(); // get active cell before cleared (will be used by snippets dialog)

        const builderStuff = this.builder.builderStuff;
        if (!builderStuff) return; // in case the builder is destroyed

        if (builderStuff.getAttribute('preventDevault')) {
            setTimeout(() => {
                builderStuff.removeAttribute('preventDevault');
            }, 30);
            return;
        }

        const dom = this.dom;

        let divs = document.getElementsByClassName('cell-active');
        while (divs.length) divs[0].classList.remove('cell-active');

        divs = document.getElementsByClassName('row-outline');
        while (divs.length) divs[0].classList.remove('row-outline');

        divs = document.getElementsByClassName('row-active');
        while (divs.length) divs[0].classList.remove('row-active');

        divs = document.getElementsByClassName('builder-active');
        while (divs.length) divs[0].classList.remove('builder-active');

        let columnTool = builderStuff.querySelector('.is-column-tool');
        dom.removeClass(columnTool, 'active');
        let elmTool = builderStuff.querySelector('.is-element-tool');
        elmTool.style.display = '';

        this.builder.activeCol = null;
    }

    clearAfterUndoRedo() {
        const dom = this.dom;

        const builderStuff = this.builder.builderStuff;
        let tools = builderStuff.querySelectorAll('.is-tool');
        Array.prototype.forEach.call(tools, (tool) => {
            tool.style.display = '';
        });
        this.builder.moveable.updateRect();
        document.querySelector('.moveable-control-box').style.display = 'none';

        this.builder.activeSpacer = null;
        this.builder.activeCodeBlock = null;
        this.builder.activeLink = null;
        this.builder.activeIframe = null;
        this.builder.activeTd = null;
        this.builder.activeTable = null;
        this.builder.activeModule = null;

        const icons = document.querySelectorAll('.icon-active');
        Array.prototype.forEach.call(icons, (icon) => {
            dom.removeClass(icon, 'icon-active');
        });
        this.builder.activeIcon = null;

        // RTE
        let rteTool = builderStuff.querySelector('.is-rte-tool');
        // rteTool.style.display = 'none';
        let rteButtons = rteTool.querySelectorAll('button');
        Array.prototype.forEach.call(rteButtons, (rteButton) => {
            dom.removeClass(rteButton, 'on');
        });

        let elementRteTool = builderStuff.querySelector('.is-elementrte-tool');
        // rteTool.style.display = 'none';
        rteButtons = elementRteTool.querySelectorAll('button');
        Array.prototype.forEach.call(rteButtons, (rteButton) => {
            dom.removeClass(rteButton, 'on');
        });

        let pops = builderStuff.querySelectorAll('.is-pop');
        Array.prototype.forEach.call(pops, (pop) => {
            pop.style.display = '';
        });
    }

    hideControls() {

        const builderStuff = this.builder.builderStuff;
        let tools = builderStuff.querySelectorAll('.is-tool');
        Array.prototype.forEach.call(tools, (tool) => {
            tool.style.display = '';
        });
        this.builder.moveable.updateRect();
        document.querySelector('.moveable-control-box').style.display = 'none';

    }

    clearActiveElement(all) {
        const dom = this.dom;
        const builderStuff = this.builder.builderStuff;

        const icons = document.querySelectorAll('.icon-active');
        Array.prototype.forEach.call(icons, (icon) => {
            dom.removeClass(icon, 'icon-active');
        });

        let elms = document.querySelectorAll('.elm-inspected');
        Array.prototype.forEach.call(elms, (elm) => {
            dom.removeClass(elm, 'elm-inspected');
        });

        elms = document.querySelectorAll('.elm-active');
        Array.prototype.forEach.call(elms, (elm) => {
            dom.removeClass(elm, 'elm-active');
        });

        let elmTool = builderStuff.querySelector('.is-element-tool');
        elmTool.style.display = '';

        let linkTool = builderStuff.querySelector('#divLinkTool');
        linkTool.style.display = '';

        if (all) {
            this.builder.activeIcon = null;
            this.builder.inspectedElement = null;
            this.builder.activeElement = null;

            // RTE
            let rtetool = builderStuff.querySelector('.is-rte-tool');
            if (rtetool) rtetool.style.display = 'none';
            let elementRtetool = builderStuff.querySelector('.is-elementrte-tool');
            if (elementRtetool) elementRtetool.style.display = 'flex';

            // Click ok on code view should hide these as well
            let rtetoolmore = builderStuff.querySelector('.rte-more-options');
            if (rtetoolmore) rtetoolmore.style.display = '';
            let elementRtetoolmore = builderStuff.querySelector('.elementrte-more-options');
            if (elementRtetoolmore) elementRtetoolmore.style.display = '';

            let btns = elementRtetool.querySelectorAll('button[data-align]');
            Array.prototype.forEach.call(btns, (btn) => {
                btn.style.display = 'none';
            });
            this.builder.rte.positionToolbar();
        }


    }

    clearControls() {
        const dom = this.dom;

        const builderStuff = this.builder.builderStuff;
        if (!builderStuff) return; // in case the builder is destroyed

        if (builderStuff.getAttribute('preventDevault')) {
            setTimeout(() => {
                builderStuff.removeAttribute('preventDevault');
            }, 30);
            return;
        }

        let tools = builderStuff.querySelectorAll('.is-tool');
        Array.prototype.forEach.call(tools, (tool) => {
            tool.style.display = '';
        });
        this.builder.moveable.updateRect();
        document.querySelector('.moveable-control-box').style.display = 'none';

        this.builder.activeSpacer = null;
        this.builder.activeCodeBlock = null;
        this.builder.activeLink = null;
        this.builder.activeIframe = null;
        this.builder.activeTd = null;
        this.builder.activeTable = null;
        this.builder.activeModule = null;
        this.builder.activeImage = null;

        const icons = document.querySelectorAll('.icon-active');
        Array.prototype.forEach.call(icons, (icon) => {
            dom.removeClass(icon, 'icon-active');
        });
        this.builder.activeIcon = null;

        // show iframe overlay to make it clickable
        let ovls = document.querySelectorAll('.ovl');
        Array.prototype.forEach.call(ovls, (ovl) => {
            ovl.style.display = 'block';
        });

        // Element Panel & Snippets sidebar
        var panels = builderStuff.querySelectorAll('.is-side.elementstyles');
        Array.prototype.forEach.call(panels, (panel) => {
            dom.removeClass(panel, 'active');
        });

        // Element Panel things
        let elms = document.querySelectorAll('[data-saveforundo]');
        Array.prototype.forEach.call(elms, (elm) => {
            elm.removeAttribute('data-saveforundo');
        });

        elms = document.querySelectorAll('.elm-inspected');
        Array.prototype.forEach.call(elms, (elm) => {
            dom.removeClass(elm, 'elm-inspected');
        });

        // RTE
        let rtetool = builderStuff.querySelector('.is-rte-tool');
        if (rtetool) rtetool.style.display = 'none';
        let elementRtetool = builderStuff.querySelector('.is-elementrte-tool');
        if (elementRtetool) elementRtetool.style.display = 'none';

        // Click ok on code view should hide these as well
        let rtetoolmore = builderStuff.querySelector('.rte-more-options');
        if (rtetoolmore) rtetoolmore.style.display = '';
        let elementRtetoolmore = builderStuff.querySelector('.elementrte-more-options');
        if (elementRtetoolmore) elementRtetoolmore.style.display = '';

        // Element
        elms = document.querySelectorAll('.elm-active');
        Array.prototype.forEach.call(elms, (elm) => {
            dom.removeClass(elm, 'elm-active');
        });

        let rtepops = builderStuff.querySelectorAll('.is-rte-pop');
        Array.prototype.forEach.call(rtepops, (rtepop) => {
            rtepop.style.display = '';
            dom.removeClass(rtepop, 'active');
            dom.removeClass(rtepop, 'deactive');
            // dom.addClass(rtepop, 'deactive');
        });

        // let pops = builderStuff.querySelectorAll('.is-pop');
        // Array.prototype.forEach.call(pops, (pop) => {
        //     pop.style.display = '';
        // });

        this.builder.colTool.lockIndicator.style.display = '';

    }

    // source: http://stackoverflow.com/questions/1349404/generate-a-string-of-5-random-characters-in-javascript
    makeId() {
        let text = '';
        let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        for (let i = 0; i < 2; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));

        let text2 = '';
        let possible2 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++)
            text2 += possible2.charAt(Math.floor(Math.random() * possible2.length));

        return text + text2;
    }

    // source: http://stackoverflow.com/questions/5605401/insert-link-in-contenteditable-element 
    saveSelection() {
        if (window.getSelection) {
            let sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                let ranges = [];
                for (let i = 0, len = sel.rangeCount; i < len; ++i) {
                    ranges.push(sel.getRangeAt(i));
                }

                this.builder.selection = ranges;

                return ranges;
            }
        } else if (document.selection && document.selection.createRange) {

            this.builder.selection = document.selection.createRange();

            return document.selection.createRange();
        }

        this.builder.selection = null;

        return null;
    }

    restoreSelection() {
        let savedSel = this.builder.selection;
        if (savedSel) {
            if (window.getSelection) {
                let sel = window.getSelection();
                // sel.removeAllRanges();
                if (document.body.createTextRange) { // All IE but Edge
                    var range = document.body.createTextRange();
                    range.collapse();
                    range.select();
                } else if (window.getSelection) {
                    if (window.getSelection().empty) {
                        window.getSelection().empty();
                    } else if (window.getSelection().removeAllRanges) {
                        window.getSelection().removeAllRanges();
                    }
                } else if (document.selection) {
                    document.selection.empty();
                }

                for (var i = 0, len = savedSel.length; i < len; ++i) {
                    sel.addRange(savedSel[i]);
                }
            } else if (document.selection && savedSel.select) {
                savedSel.select();
            }
        }
    }

    // Clean Word. Source: 
    // http://patisserie.keensoftware.com/en/pages/remove-word-formatting-from-rich-text-editor-with-javascript
    // http://community.sitepoint.com/t/strip-unwanted-formatting-from-pasted-content/16848/3
    // http://www.1stclassmedia.co.uk/developers/clean-ms-word-formatting.php
    cleanHTML(input, cleanstyle) {

        let stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
        let output = input.replace(stringStripper, ' ');

        let commentSripper = new RegExp('<!--(.*?)-->', 'g');
        output = output.replace(commentSripper, '');

        let tagStripper;
        if (cleanstyle) {
            tagStripper = new RegExp('<(/)*(meta|link|span|\\?xml:|st1:|o:|font)(.*?)>', 'gi');
        } else {
            tagStripper = new RegExp('<(/)*(meta|link|\\?xml:|st1:|o:|font)(.*?)>', 'gi');
        }
        output = output.replace(tagStripper, '');

        let badTags = ['style', 'script', 'applet', 'embed', 'noframes', 'noscript'];

        for (let i = 0; i < badTags.length; i++) {
            tagStripper = new RegExp('<' + badTags[i] + '.*?' + badTags[i] + '(.*?)>', 'gi');
            output = output.replace(tagStripper, '');
        }

        let badAttributes;
        if (cleanstyle) {
            badAttributes = ['style', 'start'];
        } else {
            badAttributes = ['start'];
        }
        for (let i = 0; i < badAttributes.length; i++) {
            let attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"', 'gi');
            output = output.replace(attributeStripper, '');
        }

        // https://gist.github.com/sbrin/6801034
        //output = output.replace(/<!--[\s\S]+?-->/gi, ''); //done (see above)
        //output = output.replace(/<(!|script[^>]*>.*?<\/script(?=[>\s])|\/?(\?xml(:\w+)?|img|meta|link|style|\w:\w+)(?=[\s\/>]))[^>]*>/gi, '');
        output = output.replace(/<(!|script[^>]*>.*?<\/script(?=[>\s])|\/?(\?xml(:\w+)?|img|meta|link|style|\w:\w+)(?=[\s/>]))[^>]*>/gi, '');
        output = output.replace(/<(\/?)s>/gi, '<$1strike>');
        output = output.replace(/&nbsp;/gi, ' ');
        //output = output.replace(/<span\s+style\s*=\s*"\s*mso-spacerun\s*:\s*yes\s*;?\s*"\s*>([\s\u00a0]*)<\/span>/gi, function(str, spaces) {
        //    return (spaces.length > 0) ? spaces.replace(/./, " ").slice(Math.floor(spaces.length/2)).split("").join("\u00a0") : '';
        //});

        //clean copied elm-active background-color (LATER: improve)
        output = output.replace(/background-color: rgba\(200, 200, 201, 0.11\);/gi, '');
        output = output.replace(/background-color: rgba\(200, 200, 201, 0.11\)/gi, '');

        return output;
    }

    checkEmpty() {

        const dom = this.dom;

        // Get all builder areas
        const builders = document.querySelectorAll(this.builder.opts.container);
        Array.prototype.forEach.call(builders, (builder) => {

            const rows = dom.elementChildren(builder);
            let empty = true;
            rows.forEach((row) => {

                if (dom.hasClass(row, 'row-add-initial')) return;
                if (dom.hasClass(row, 'dummy-space')) return;

                empty = false;
            });

            if (empty) {
                let emptyinfo = builder.querySelector('.row-add-initial');
                if (!emptyinfo) {
                    builder.innerHTML = `<button type="button" class="row-add-initial">${this.out('Empty')}<br><span>${this.out('+ Click to add content')}</span></div>`;
                    emptyinfo = builder.querySelector('.row-add-initial');
                }
                emptyinfo.addEventListener('click', () => {

                    this.clearActiveCell();

                    dom.addClass(emptyinfo, 'row-active'); // Needed for addContent(). Directly apply class in Util is fine.

                    const builderStuff = this.builder.builderStuff;
                    let quickadd = builderStuff.querySelector('.quickadd'); // see quickadd.js. Directly select by class in Util is fine.

                    let tabs = quickadd.querySelector('.is-pop-tabs');
                    tabs.style.display = 'none';

                    const viewportHeight = window.innerHeight;
                    let top = emptyinfo.getBoundingClientRect().top;
                    const left = emptyinfo.getBoundingClientRect().left + (emptyinfo.offsetWidth * this.builder.opts.zoom) / 2 - 11;
                    quickadd.style.display = 'flex';
                    const w = quickadd.offsetWidth; //to get value, element must not hidden (display:none). So set display:flex before this.
                    const h = quickadd.offsetHeight;

                    if (viewportHeight - top > h) {
                        top = top + (emptyinfo.offsetHeight * this.builder.opts.zoom) - 19;
                        quickadd.style.top = (top + window.pageYOffset) + 27 + 'px';
                        quickadd.style.left = (left - w / 2 + 7) + 'px';
                        dom.removeClass(quickadd, 'arrow-bottom');
                        dom.removeClass(quickadd, 'arrow-right');
                        dom.removeClass(quickadd, 'arrow-left');
                        dom.removeClass(quickadd, 'center');
                        dom.addClass(quickadd, 'arrow-top');
                        dom.addClass(quickadd, 'center');
                    } else {
                        quickadd.style.top = (top + window.pageYOffset - h - 8) + 'px';
                        quickadd.style.left = (left - w / 2 + 7) + 'px';
                        dom.removeClass(quickadd, 'arrow-top');
                        dom.removeClass(quickadd, 'arrow-right');
                        dom.removeClass(quickadd, 'arrow-left');
                        dom.removeClass(quickadd, 'center');
                        dom.addClass(quickadd, 'arrow-bottom');
                        dom.addClass(quickadd, 'center');
                    }

                    quickadd.setAttribute('data-mode', 'row');

                });
            } else {
                let emptyinfo = builder.querySelector('.row-add-initial');
                if (emptyinfo) emptyinfo.parentNode.removeChild(emptyinfo);
            }

        });

    }

    clearPreferences() {
        localStorage.removeItem('_theme'); //zoom
        localStorage.removeItem('_zoom'); //zoom
        localStorage.removeItem('_buildermode'); //builderMode
        localStorage.removeItem('_editingtoolbar'); //toolbar
        localStorage.removeItem('_editingtoolbardisplay'); //toolbarDisplay
        localStorage.removeItem('_hidecelltool'); //columnTool
        localStorage.removeItem('_rowtool'); //rowTool
        localStorage.removeItem('_hideelementtool'); //elementTool
        localStorage.removeItem('_hidesnippetaddtool'); //snippetAddTool
        localStorage.removeItem('_outlinemode'); //outlineMode
        localStorage.removeItem('_hiderowcoloutline'); //rowcolOutline
        localStorage.removeItem('_outlinestyle'); //outlineStyle
        localStorage.removeItem('_hideelementhighlight'); //elementHighlight
        localStorage.removeItem('_opensnippets'); //snippetOpen
        localStorage.removeItem('_toolstyle'); //toolStyle
        localStorage.removeItem('_snippetssidebardisplay'); //snippetsSidebarDisplay

        localStorage.removeItem('_pasteresult'); //DON'T HAVE PROP

        //NOT USED
        localStorage.removeItem('_scrollableeditor');
        localStorage.removeItem('_animatedsorting');
        localStorage.removeItem('_addbuttonplace');
        localStorage.removeItem('_hiderowtool');
        localStorage.removeItem('_dragwithouthandle');
        localStorage.removeItem('_advancedhtmleditor');
        localStorage.removeItem('_hidecolhtmleditor');
        localStorage.removeItem('_hiderowhtmleditor');
    }

    // source: http://stackoverflow.com/questions/6690752/insert-html-at-caret-in-a-contenteditable-div */
    pasteHtmlAtCaret(html, selectPastedContent) {

        this.restoreSelection();

        var sel, range;

        if (window.getSelection) {

            if (!this.builder.activeCol) return;

            sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {

                range = sel.getRangeAt(0);
                range.deleteContents();

                var el = document.createElement('div');
                el.innerHTML = html;
                var frag = document.createDocumentFragment(), node, lastNode;
                while ((node = el.firstChild)) {
                    lastNode = frag.appendChild(node);
                }
                var firstNode = frag.firstChild;
                range.insertNode(frag);

                if (lastNode) {
                    range = range.cloneRange();
                    range.setStartAfter(lastNode);
                    if (selectPastedContent) {
                        range.setStartBefore(firstNode);
                    } else {
                        range.collapse(true);
                    }
                    sel.removeAllRanges();
                    if (!this.builder.isTouchSupport) sel.addRange(range);
                }
            }
        } else if ((sel = document.selection) && sel.type !== 'Control') {

            if (!this.builder.activeCol) return;

            var originalRange = sel.createRange();
            originalRange.collapse(true);
            sel.createRange().pasteHTML(html);
            if (selectPastedContent) {
                range = sel.createRange();
                range.setEndPoint('StartToStart', originalRange);
                if (!this.builder.isTouchSupport) range.select();
            }
        }
    }

    refreshModule() {
        let module = this.builder.activeModule;
        if (!module) return;

        let index = 1;
        let subblocks = module.querySelectorAll('[data-subblock]');
        Array.prototype.forEach.call(subblocks, (subblock) => {

            let builderhtml = subblock.innerHTML;

            module.setAttribute('data-html-' + index, encodeURIComponent(builderhtml));
            index++;
        });

        let html = decodeURIComponent(module.getAttribute('data-html'));
        html = html.replace(/{id}/g, this.makeId());

        module.innerHTML = '';

        var range = document.createRange();
        range.setStart(module, 0);
        module.appendChild(
            range.createContextualFragment(html)
        );

        subblocks = module.querySelectorAll('[data-subblock]');
        var i = 1;
        Array.prototype.forEach.call(subblocks, (subblock) => {
            if (module.getAttribute('data-html-' + i)) {
                subblock.innerHTML = decodeURIComponent(module.getAttribute('data-html-' + i));
            }
            i++;
        });

    }

    isTouchSupport() {
        /*
        if(('ontouchstart' in window) || (navigator.MaxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
            return true;
        }else {
            return false;
        }
        */

        // https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
        if (window.matchMedia('(pointer: coarse)').matches) {
            return true;
        } else {
            return false;
        }
    }

    // https://stackoverflow.com/questions/31757852/how-can-i-detect-internet-explorer-ie-and-microsoft-edge-using-javascript
    detectIE() {

        /*
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }
    
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
    
        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }
        
        return false;
        */

        // https://stackoverflow.com/questions/49986720/how-to-detect-internet-explorer-11-and-below-versions/49986758
        if (document.documentMode) {
            return true;
        }
        return false;
    }

    // Source: https://css-tricks.com/snippets/javascript/lighten-darken-color/
    LightenDarkenColor(col, amt) {

        var usePound = false;

        if (col[0] === '#') {
            col = col.slice(1);
            usePound = true;
        }

        var num = parseInt(col, 16);

        var r = (num >> 16) + amt;

        if (r > 255) r = 255;
        else if (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) b = 255;
        else if (b < 0) b = 0;

        var g = (num & 0x0000FF) + amt;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        //return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
        return (usePound ? '#' : '') + String('000000' + (g | (b << 8) | (r << 16)).toString(16)).slice(-6);
    }

    getUIStyles() {
        const dom = this.dom;
        const html = `<button class="style-helper"><svg><use xlink:href="#ion-code-working"></use></svg></button>
        <input type="text" class="style-helper-input" style="display:none;">
        <label class="style-helper-label" style="display:none;"></label>
        <input class="style-helper-checkbox" type="checkbox" style="display:none;">
        <button class="style-helper-button-classic classic" style="display:none;"><svg><use xlink:href="#ion-code-working"></use></svg></button>
        <select class="style-helper-select" style="display:none;"><option value=""></option></select>
        `;
        dom.appendHtml(this.builder.builderStuff, html);

        // Get some styles
        const btn = this.builder.builderStuff.querySelector('.style-helper');
        const inp = this.builder.builderStuff.querySelector('.style-helper-input');
        const lbl = this.builder.builderStuff.querySelector('.style-helper-label');
        // const chk = this.builder.builderStuff.querySelector('.style-helper-checkbox');
        const sel = this.builder.builderStuff.querySelector('.style-helper-select');
        const btnClassic = this.builder.builderStuff.querySelector('.style-helper-button-classic');

        this.builder.styleModalColor = this.getUIStyleValue(btn, 'modal-color', 'background-color');
        this.builder.styleModalBackground = this.getUIStyleValue(btn, 'modal-background', 'background-color');
        this.builder.styleButtonPickColorBorder = this.getUIStyleValue(btn, 'button-pickcolor-border', 'border');
        this.builder.styleButtonPickColorBackground = this.getUIStyleValue(btn, 'button-pickcolor-background', 'background-color');

        this.builder.styleToolBackground = window.getComputedStyle(btn, null).getPropertyValue('background-color');
        this.builder.styleButtonColor = window.getComputedStyle(btn, null).getPropertyValue('color');
        this.builder.styleButtonSvgFill = window.getComputedStyle(btn.querySelector('svg'), null).getPropertyValue('fill');
        // this.builder.styleButtonBackgroundOn = this.getUIStyleValue(btn, 'on', 'background-color');
        this.builder.styleButtonBackgroundHover = this.getUIStyleValue(btn, 'hover', 'background-color');

        this.builder.styleSnippetColor = this.getUIStyleValue(btn, 'snippet-color', 'background-color');
        this.builder.styleSnippetBackground = this.getUIStyleValue(btn, 'snippet-background', 'background-color');
        this.builder.styleSnippetTabsBackground = this.getUIStyleValue(btn, 'snippet-tabs-background', 'background-color');
        this.builder.styleSnippetTabItemBackground = this.getUIStyleValue(btn, 'snippet-tab-item-background', 'background-color');
        this.builder.styleSnippetTabItemBackgroundActive = this.getUIStyleValue(btn, 'snippet-tab-item-background-active', 'background-color');
        this.builder.styleSnippetTabItemBackgroundHover = this.getUIStyleValue(btn, 'snippet-tab-item-background-hover', 'background-color');
        this.builder.styleSnippetTabItemColor = this.getUIStyleValue(btn, 'snippet-tab-item-color', 'background-color');

        this.builder.styleSnippetMoreItemBackground = this.getUIStyleValue(btn, 'snippet-more-item-background', 'background-color');
        this.builder.styleSnippetMoreItemBackgroundActive = this.getUIStyleValue(btn, 'snippet-more-item-background-active', 'background-color');
        this.builder.styleSnippetMoreItemBackgroundHover = this.getUIStyleValue(btn, 'snippet-more-item-background-hover', 'background-color');
        this.builder.styleSnippetMoreItemColor = this.getUIStyleValue(btn, 'snippet-more-item-color', 'background-color');

        // Normal Tabs (ex. used in 'Symbol' plugin)

        this.builder.styleTabsBackground = this.getUIStyleValue(btn, 'tabs-background', 'background-color');
        this.builder.styleTabItemBorderBottomActive = this.getUIStyleValue(btn, 'tab-item-active-border-bottom', 'border');
        this.builder.styleTabItemColor = this.getUIStyleValue(btn, 'tab-item-color', 'background-color');

        this.builder.styleTabsMoreBackground = this.getUIStyleValue(btn, 'tabs-more-background', 'background-color');
        this.builder.styleTabsMoreBorder = this.getUIStyleValue(btn, 'tabs-more-border', 'border');
        this.builder.styleTabsMoreItemColor = this.getUIStyleValue(btn, 'tabs-more-item-color', 'background-color');
        this.builder.styleTabsMoreBackgroundHover = this.getUIStyleValue(btn, 'tabs-more-item-background-hover', 'background-color');

        this.builder.styleSeparatorColor = this.getUIStyleValue(btn, 'separator-color', 'background-color');

        // Preview (ex. used in 'Preview' plugin)

        // this.builder.styleModalPreviewColor = this.getUIStyleValue(btn, 'modal-preview-color', 'background-color');
        // this.builder.styleModalPreviewBackground = this.getUIStyleValue(btn, 'modal-preview-background', 'background-color');
        // this.builder.styleModalPreviewSizeControlBackground = this.getUIStyleValue(btn, 'modal-preview-sizecontrol-background', 'background-color');
        // this.builder.styleModalPreviewSizeControlBackgroundHover = this.getUIStyleValue(btn, 'modal-preview-sizecontrol-background-hover', 'background-color');
        // this.builder.styleModalPreviewSizeControlSeparatorColor = this.getUIStyleValue(btn, 'modal-preview-sizecontrol-separator-color', 'background-color');

        // UI/Modal (ex. used in 'Button Editor' plugin)

        // this.builder.styleTextColor = this.getUIStyleValue(btn, 'text-color', 'background-color');

        // Select (ex. used in 'Button Editor' plugin, 'Slider' plugin, 'Slider Content' plugin)

        this.builder.styleSelectBackground = window.getComputedStyle(sel, null).getPropertyValue('background-color');
        this.builder.styleSelectColor = window.getComputedStyle(sel, null).getPropertyValue('color');
        this.builder.styleSelectOptionBackground = window.getComputedStyle(sel.querySelector('option'), null).getPropertyValue('background-color');

        // Input (ex. used in 'Search & Replace' plugin)

        this.builder.styleInputBackground = window.getComputedStyle(inp, null).getPropertyValue('background-color');
        this.builder.styleInputBorderBottom = window.getComputedStyle(inp, null).getPropertyValue('border-bottom');
        this.builder.styleInputColor = window.getComputedStyle(inp, null).getPropertyValue('color');

        // Label (ex. used in 'Search & Replace' plugin)

        this.builder.styleLabelColor = window.getComputedStyle(lbl, null).getPropertyValue('color');

        // Button Classic (ex. used in 'Search & Replace' plugin)

        this.builder.styleButtonClassicBackground = window.getComputedStyle(btnClassic, null).getPropertyValue('background-color');
        this.builder.styleButtonClassicColor = window.getComputedStyle(btnClassic, null).getPropertyValue('color');
        this.builder.styleButtonClassicBackgroundHover = this.getUIStyleValue(btnClassic, 'hover', 'background-color');

        // console.log(this.builder.styleButtonClassicBackgroundHover);

        this.builder.styleDark = false;
        this.builder.styleColored = false;
        this.builder.styleColoredDark = false;
        this.builder.styleLight = false;
        if (document.body.getAttribute('class')) {

            if (document.body.getAttribute('class').indexOf('colored-dark') !== -1) {
                this.builder.styleColoredDark = true;
            } else if (document.body.getAttribute('class').indexOf('dark') !== -1) {
                this.builder.styleDark = true;
            } else if (document.body.getAttribute('class').indexOf('colored') !== -1) {
                this.builder.styleColored = true;
            } else if (document.body.getAttribute('class').indexOf('light') !== -1) {
                this.builder.styleLight = true;
            }
        }

    }

    getUIStyleValue(elm, classname, prop) {
        const dom = this.dom;
        dom.addClass(elm, classname);
        let val = window.getComputedStyle(elm, null).getPropertyValue(prop);
        dom.removeClass(elm, classname);
        return val;
    }

    getFontFamilyStyle(showInModal) {
        let css = `
            html, body {height:100%}
            body {overflow:hidden;margin:0;
                font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                font-size:100%; 
                line-height:1.7;
            }
            #divFontList {margin:0;padding:0 0 9px 9px;height:100%;overflow-y:scroll !important;box-sizing:border-box;}
            #divFontList > div {width:100%;cursor:pointer;overflow:hidden;text-align:center;position:relative;}
            #divFontList > div img {margin:7px 5px 0px 5px;max-width: 230px;max-height: 27px;}
            #divFontList > div div {position:absolute;top:0;left:0;width:100%;height:100%;}

            #divFontList > div > div {
                position:absolute;left:0px;top:0px;width:100%;height:100%;
                // z-index: 1;
            }

            #divFontList > div {
                color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
            }
            #divFontList > div > div:after {
                // background: rgba(0, 0, 0, 0.04); 
                background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
                position: absolute;
                content: "";
                display: block;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
            }
            #divFontList > div:hover > div:after {
                opacity: 1;
            }
            #divFontList > div.on > div:after {
                opacity: 1;
            }

            ${(!this.builder.styleDark && !this.builder.styleColoredDark && !this.builder.styleColore) ? `
            #divFontList > div img {
                mix-blend-mode: multiply;
            }
            ` : ''}

            .dark #divFontList > div img {
                ${(showInModal ? '' : 'mix-blend-mode: screen;')};
                filter: invert(1);
            }
            .dark #divFontList > div {
                color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
            }
            .dark #divFontList > div > div:after {
                background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
            }
            
            .colored-dark #divFontList > div img {
                ${(showInModal ? '' : 'mix-blend-mode: screen;')};
                ${(showInModal ? '' : 'filter: invert(1);')};
            }
            .colored-dark #divFontList > div {
                color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
            }
            .colored-dark #divFontList > div > div:after {
                background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
            }


            .colored #divFontList > div img {
                ${(showInModal ? '' : 'mix-blend-mode: screen;')};
                ${(showInModal ? '' : 'filter: invert(1);')};
            }
            .colored #divFontList > div {
                color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
            }
            .colored #divFontList > div > div:after {
                background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
            }


            /* Scrollbar for toolbar/RTE and modal */

            .dark * {
                scrollbar-width: thin;
                scrollbar-color: rgba(255, 255, 255, 0.3) auto;
            }
            .dark *::-webkit-scrollbar {
                width: 12px;
            }
            .dark *::-webkit-scrollbar-track {
                background: transparent;
            }
            .dark *::-webkit-scrollbar-thumb {
                background-color:rgba(255, 255, 255, 0.3);
            } 

            .colored-dark * {
                scrollbar-width: thin;
                scrollbar-color: rgb(100, 100, 100) auto;
            }
            .colored-dark *::-webkit-scrollbar {
                width: 12px;
            }
            .colored-dark *::-webkit-scrollbar-track {
                background: transparent;
            }
            .colored-dark *::-webkit-scrollbar-thumb {
                background-color:rgb(100, 100, 100);
            } 

            .colored * {
                scrollbar-width: thin;
                scrollbar-color: rgba(0, 0, 0, 0.4) auto;
            }
            .colored *::-webkit-scrollbar {
                width: 12px;
            }
            .colored *::-webkit-scrollbar-track {
                background: transparent;
            }
            .colored *::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.4);
            } 

            .light * {
                scrollbar-width: thin;
                scrollbar-color: rgba(0, 0, 0, 0.4) auto;
            }
            .light *::-webkit-scrollbar {
                width: 12px;
            }
            .light *::-webkit-scrollbar-track {
                background: transparent;
            }
            .light *::-webkit-scrollbar-thumb {
                background-color: rgba(0, 0, 0, 0.4);
            }`;

        return css;
    }

    refreshFontFamilyStyle1() {
        let iframeRte = this.builder.rte.rteFontFamilyOptions.querySelector('iframe');
        let doc1 = iframeRte.contentWindow.document;
        const divMainStyle1 = doc1.querySelector('#mainstyle');
        divMainStyle1.innerHTML = this.getFontFamilyStyle();

        if (this.builder.styleDark) {
            doc1.body.setAttribute('class', 'dark');
        } else if (this.builder.styleColored) {
            doc1.body.className = 'colored';
        } else if (this.builder.styleColoredDark) {
            doc1.body.className = 'colored-dark';
        } else if (this.builder.styleLight) {
            doc1.body.className = 'light';
        } else {
            doc1.body.className = '';
        }
    }

    refreshFontFamilyStyle2() {
        const fontModal = this.builder.builderStuff.querySelector('.is-modal.pickfontfamily');
        let iframePanel = fontModal.querySelector('iframe');
        let doc2 = iframePanel.contentWindow.document;
        const divMainStyle2 = doc2.querySelector('#mainstyle');
        divMainStyle2.innerHTML = this.getFontFamilyStyle(true);

        if (this.builder.styleDark) {
            doc2.body.setAttribute('class', 'dark');
        } else if (this.builder.styleColored) {
            doc2.body.className = 'colored';
        } else if (this.builder.styleColoredDark) {
            doc2.body.className = 'colored-dark';
        } else if (this.builder.styleLight) {
            doc2.body.className = 'light';
        } else {
            doc2.body.className = '';
        }
    }


    getFontPreview() {

        // let path = this.builder.scriptPath + 'fonts/';
        let path = this.builder.fontAssetPath;

        let html = '';

        /*
        const base64 = true;
        if(base64) {
            html = `
            <div data-provider="" data-font-family="" style="font-size:12px;padding:10px 7px;box-sizing:border-box;"><div></div>
                <span style="z-index:1;position: relative;">${this.out('None')}</span>
            </div>
            <div data-provider="" data-font-family="Arial, sans-serif"><div></div><img loading="lazy" src="${font_arial}"></div>
            <div data-provider="" data-font-family="courier"><div></div><img loading="lazy" src="${font_courier}"></div>
            <div data-provider="" data-font-family="Georgia, serif"><div></div><img loading="lazy" src="${font_georgia}"></div>
            <div data-provider="" data-font-family="monospace"><div></div><img loading="lazy" src="${font_monospace}"></div>
            <div data-provider="" data-font-family="sans-serif"><div></div><img loading="lazy" src="${font_sans_serif}"></div>
            <div data-provider="" data-font-family="serif"><div></div><img loading="lazy" src="${font_serif}"></div>
            <div data-provider="google" data-font-family="Abel, sans-serif"><div></div><img loading="lazy" src="${font_abel}"></div>
            <div data-provider="google" data-font-family="Abril Fatface"><div></div><img loading="lazy" src="${font_abril_fatface}"></div>
            <div data-provider="google" data-font-family="Advent Pro, sans-serif" data-font-style="300"><div></div><img loading="lazy" src="${font_advent_pro}"></div>
            <div data-provider="google" data-font-family="Aladin, cursive"><div></div><img loading="lazy" src="${font_aladin}"></div>
            <div data-provider="google" data-font-family="Alegreya, serif" data-font-style="400,400i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${font_alegreya}"></div>
            <div data-provider="google" data-font-family="Alegreya Sans SC, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${font_alegreya_sans_sc}"></div>
            <div data-provider="google" data-font-family="Alegreya SC, serif" data-font-style="400,400i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${font_alegreya_sc}"></div>
            <div data-provider="google" data-font-family="Alice, serif"><div></div><img loading="lazy" src="${font_alice}"></div>
            <div data-provider="google" data-font-family="Allerta Stencil, sans-serif"><div></div><img loading="lazy" src="${font_allerta_stencil}"></div>
            <div data-provider="google" data-font-family="Allura, cursive"><div></div><img loading="lazy" src="${font_allura}"></div>
            <div data-provider="google" data-font-family="Almendra Display, cursive"><div></div><img loading="lazy" src="${font_almendra_display}"></div>
            <div data-provider="google" data-font-family="Amatic SC, cursive" data-font-style="400,700"><div></div><img loading="lazy" src="${font_amatic_sc}"></div>
            <div data-provider="google" data-font-family="Andika, sans-serif"><div></div><img loading="lazy" src="${font_andika}"></div>
            <div data-provider="google" data-font-family="Anonymous Pro, monospace" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${font_anonymous_pro}"></div>
            <div data-provider="google" data-font-family="Architects Daughter, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${font_architects_daughter}"></div>
            <div data-provider="google" data-font-family="Arimo, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${font_arimo}"></div>
            <div data-provider="google" data-font-family="Arsenal, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${font_arsenal}"></div>
            <div data-provider="google" data-font-family="Assistant" data-font-style="300,700"><div></div><img loading="lazy" src="${font_assistant}"></div>
            <div data-provider="google" data-font-family="Aubrey, cursive"><div></div><img loading="lazy" src="${font_aubrey}"></div>
            <div data-provider="google" data-font-family="Anton, sans-serif"><div></div><img loading="lazy" src="${font_anton}"></div>
            <div data-provider="google" data-font-family="Archivo Narrow, sans-serif"><div></div><img loading="lazy" src="${font_archivo_narrow}"></div>
            <div data-provider="google" data-font-family="Bad Script, cursive"><div></div><img loading="lazy" src="${font_bad_script}"></div>
            <div data-provider="google" data-font-family="BenchNine, sans-serif"><div></div><img loading="lazy" src="${font_benchNine}"></div>
            <div data-provider="google" data-font-family="Bevan, cursive"><div></div><img loading="lazy" src="${font_bevan}"></div>
            <div data-provider="google" data-font-family="Bigelow Rules, cursive"><div></div><img loading="lazy" src="${font_bigelow_rules}"></div>
            <div data-provider="google" data-font-family="Bilbo, cursive"><div></div><img loading="lazy" src="${font_bilbo}"></div>
            <div data-provider="google" data-font-family="Bonbon, cursive"><div></div><img loading="lazy" src="${font_bonbon}"></div>
            <div data-provider="google" data-font-family="Bowlby One SC, cursive"><div></div><img loading="lazy" src="${font_bowlby_one_sc}"></div>
            <div data-provider="google" data-font-family="Cabin Condensed, sans-serif"><div></div><img loading="lazy" src="${font_cabin_condensed}"></div>
            <div data-provider="google" data-font-family="Carrois Gothic SC, sans-serif"><div></div><img loading="lazy" src="${font_carrois_gothic_sc}"></div>
            <div data-provider="google" data-font-family="Caveat, cursive" data-font-style="400,700"><div></div><img loading="lazy" src="${font_caveat}"></div>
            <div data-provider="google" data-font-family="Chewy, cursive"><div></div><img loading="lazy" src="${font_chewy}"></div>
            <div data-provider="google" data-font-family="Cinzel, serif"><div></div><img loading="lazy" src="${font_cinzel}"></div>
            <div data-provider="google" data-font-family="Comfortaa, cursive" data-font-style="300"><div></div><img loading="lazy" src="${font_comfortaa}"></div>
            <div data-provider="google" data-font-family="Concert One, cursive"><div></div><img loading="lazy" src="${font_concert_one}"></div>
            <div data-provider="google" data-font-family="Cormorant, serif" data-font-style="300,300i,600,600i,700,700i"><div></div><img loading="lazy" src="${font_cormorant}"></div>
            <div data-provider="google" data-font-family="Cormorant Garamond, serif" data-font-style="300,300i,600,600i,700,700i"><div></div><img loading="lazy" src="${font_cormorant_garamond}"></div>
            <div data-provider="google" data-font-family="Cormorant Infant, serif" data-font-style="300,300i,600,600i,700,700i"><div></div><img loading="lazy" src="${font_cormorant_infant}"></div>
            <div data-provider="google" data-font-family="Cormorant SC, serif" data-font-style="300,600,700"><div></div><img loading="lazy" src="${font_cormorant_sc}"></div>
            <div data-provider="google" data-font-family="Cormorant Unicase, serif" data-font-style="300,600,700"><div></div><img loading="lazy" src="${font_cormorant_unicase}"></div>
            <div data-provider="google" data-font-family="Cousine" data-font-style="400,700"><div></div><img loading="lazy" src="${font_cousine}"></div>
            <div data-provider="google" data-font-family="Crafty Girls, cursive"><div></div><img loading="lazy" src="${font_crafty_girls}"></div>
            <div data-provider="google" data-font-family="Cuprum, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${font_cuprum}"></div>
            <div data-provider="google" data-font-family="Cutive Mono, monospace"><div></div><img loading="lazy" src="${font_cutive_mono}"></div>
            <div data-provider="google" data-font-family="Devonshire, cursive"><div></div><img loading="lazy" src="${font_devonshire}"></div>
            <div data-provider="google" data-font-family="Didact Gothic, sans-serif"><div></div><img loading="lazy" src="${font_didact_gothic}"></div>
            <div data-provider="google" data-font-family="Diplomata SC, cursive"><div></div><img loading="lazy" src="${font_diplomata_sc}"></div>
            <div data-provider="google" data-font-family="Dosis, sans-serif" data-font-style="200"><div></div><img loading="lazy" src="${font_dosis}"></div>
            <div data-provider="google" data-font-family="EB Garamond, serif" data-font-style="400,400i,600,600i,700,700i,800,800i"><div></div><img loading="lazy" src="${font_eb_garamond}"></div>
            <div data-provider="google" data-font-family="El Messiri, sans-serif" data-font-style="400,600,700"><div></div><img loading="lazy" src="${font_el_messiri}"></div>
            <div data-provider="google" data-font-family="Elsie, cursive" data-font-style="400,900"><div></div><img loading="lazy" src="${font_elsie}"></div>
            <div data-provider="google" data-font-family="Encode Sans, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${font_encode_sans}"></div>
            <div data-provider="google" data-font-family="Exo, sans-serif" data-font-style="100"><div></div><img loading="lazy" src="${font_exo}"></div>
            <div data-provider="google" data-font-family="'Exo 2', sans-serif" data-font-style="200,200i,600,600i,700,700i,800,800i,900,900i" data-font-display="swap"><div></div><img loading="lazy" src="${font_exo_2}"></div>
            <div data-provider="google" data-font-family="Felipa, cursive"><div></div><img loading="lazy" src="${font_felipa}"></div>
            <div data-provider="google" data-font-family="Fira Code, monospace" data-font-style="300,500,600,700"><div></div><img loading="lazy" src="${font_fira_code}"></div>
            <div data-provider="google" data-font-family="Fira Mono, monospace" data-font-style="400,500,700"><div></div><img loading="lazy" src="${font_fira_mono}"></div>
            <div data-provider="google" data-font-family="Fira Sans, sans-serif" data-font-style="200,200i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${font_fira_sans}"></div>
            <div data-provider="google" data-font-family="Fira Sans Condensed, sans-serif" data-font-style="200,200i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${font_fira_sans_condensed}"></div>
            <div data-provider="google" data-font-family="Fira Sans Extra Condensed, sans-serif" data-font-style="200,200i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${font_fira_sans_extra_condensed}"></div>
            <div data-provider="google" data-font-family="Fjalla One, sans-serif"><div></div><img loading="lazy" src="${font_fjalla_one}"></div>
            <div data-provider="google" data-font-family="Forum, cursive"><div></div><img loading="lazy" src="${font_forum}"></div>
            <div data-provider="google" data-font-family="Frank Ruhl Libre" data-font-style="300,700"><div></div><img loading="lazy" src="${font_frank_ruhl_libre}"></div>
            <div data-provider="google" data-font-family="Fredericka the Great, cursive"><div></div><img loading="lazy" src="${font_fredericka_the_great}"></div>
            <div data-provider="google" data-font-family="Gabriela, serif"><div></div><img loading="lazy" src="${font_gabriela}"></div>
            <div data-provider="google" data-font-family="Gilda Display, serif"><div></div><img loading="lazy" src="${font_gilda_display}"></div>
            <div data-provider="google" data-font-family="Give You Glory, cursive"><div></div><img loading="lazy" style="transform:scale(1.3);" src="${font_give_you_glory}"></div>
            <div data-provider="google" data-font-family="Gruppo, cursive"><div></div><img loading="lazy" src="${font_gruppo}"></div>
            <div data-provider="google" data-font-family="Handlee, cursive"><div></div><img loading="lazy" src="${font_handlee}"></div>
            <div data-provider="google" data-font-family="Happy Monkey, cursive"><div></div><img loading="lazy" src="${font_happy_monkey}"></div>
            <div data-provider="google" data-font-family="Hind" data-font-style="300,700"><div></div><img loading="lazy" src="${font_hind}"></div>
            <div data-provider="google" data-font-family="IBM Plex Mono, monospace" data-font-style="300,300i,500,500i,600,600i,700,700i"><div></div><img loading="lazy" src="${font_ibm_plex_mono}"></div>
            <div data-provider="google" data-font-family="IBM Plex Sans, sans-serif" data-font-style="300,300i,500,500i,600,600i,700,700i"><div></div><img loading="lazy" src="${font_ibm_plex_sans}"></div>
            <div data-provider="google" data-font-family="IBM Plex Serif, serif" data-font-style="300,300i,500,500i,600,600i,700,700i"><div></div><img loading="lazy" src="${font_ibm_plex_serif}"></div>
            <div data-provider="google" data-font-family="Iceland, cursive"><div></div><img loading="lazy" src="${font_iceland}"></div>
            <div data-provider="google" data-font-family="Inconsolata, monospace" data-font-style="400,700"><div></div><img loading="lazy" src="${font_inconsolata}"></div>
            <div data-provider="google" data-font-family="Josefin Sans, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${font_josefin_sans}"></div>
            <div data-provider="google" data-font-family="Istok Web, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${font_istok_web}"></div>
            <div data-provider="google" data-font-family="Julee, cursive"><div></div><img loading="lazy" src="${font_julee}"></div>
            <div data-provider="google" data-font-family="Julius Sans One, sans-serif"><div></div><img loading="lazy" src="${font_julius_sans_one}"></div>
            <div data-provider="google" data-font-family="Junge, serif"><div></div><img loading="lazy" src="${font_junge}"></div>
            <div data-provider="google" data-font-family="Jura, sans-serif" data-font-style="300,600,700"><div></div><img loading="lazy" src="${font_jura}"></div>
            <div data-provider="google" data-font-family="Just Me Again Down Here, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${font_just_me_again_down_here}"></div>
            <div data-provider="google" data-font-family="Kaushan Script, cursive"><div></div><img loading="lazy" src="${font_kaushan_script}"></div>
            <div data-provider="google" data-font-family="Kelly Slab, cursive"><div></div><img loading="lazy" src="${font_kelly_slab}"></div>
            <div data-provider="google" data-font-family="Kite One, sans-serif"><div></div><img loading="lazy" src="${font_kite_one}"></div>
            <div data-provider="google" data-font-family="Kosugi, sans-serif"><div></div><img loading="lazy" src="${font_kosugi}"></div>
            <div data-provider="google" data-font-family="Kosugi Maru, sans-serif"><div></div><img loading="lazy" src="${font_kosugi_maru}"></div>
            <div data-provider="google" data-font-family="Kurale, serif"><div></div><img loading="lazy" src="${font_kurale}"></div>
            <div data-provider="google" data-font-family="Lato, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${font_lato}"></div>
            <div data-provider="google" data-font-family="Ledger, serif" data-font-style="300,700" data-font-display="swap"><div></div><img loading="lazy" src="${font_ledger}"></div>
            <div data-provider="google" data-font-family="Lekton, sans-serif" data-font-style="400,700"><div></div><img loading="lazy" src="${font_lekton}"></div>
            <div data-provider="google" data-font-family="Life Savers, cursive"><div></div><img loading="lazy" src="${font_life_savers}"></div>
            <div data-provider="google" data-font-family="Literata, serif" data-font-style="400,400i,600,600i,700,700i"><div></div><img loading="lazy" src="${font_literata}"></div>
            <div data-provider="google" data-font-family="Lobster, cursive"><div></div><img loading="lazy" src="${font_lobster}"></div>
            <div data-provider="google" data-font-family="Lobster Two, cursive"><div></div><img loading="lazy" src="${font_lobster_two}"></div>
            <div data-provider="google" data-font-family="Londrina Shadow, cursive"><div></div><img loading="lazy" src="${font_londrina_shadow}"></div>
            <div data-provider="google" data-font-family="Lora, serif" data-font-style="400,700"><div></div><img loading="lazy" src="${font_lora}"></div>
            <div data-provider="google" data-font-family="Lovers Quarrel, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${font_lovers_quarrel}"></div>
            <div data-provider="google" data-font-family="'M PLUS 1p', sans-serif" data-font-style="300,500,700,800,900" data-font-display="swap"><div></div><img loading="lazy" src="${font_m_plus_1p}"></div>
            <div data-provider="google" data-font-family="'M PLUS Rounded 1c', sans-serif" data-font-style="300,500,700,800,900" data-font-display="swap"><div></div><img loading="lazy" src="${font_m_plus_rounded_1c}"></div>
            <div data-provider="google" data-font-family="Macondo, cursive"><div></div><img loading="lazy" src="${font_macondo}"></div>
            <div data-provider="google" data-font-family="Marcellus SC, serif"><div></div><img loading="lazy" src="${font_marcellus_sc}"></div>
            <div data-provider="google" data-font-family="Marck Script, cursive"><div></div><img loading="lazy" src="${font_marck_script}"></div>
            <div data-provider="google" data-font-family="Martel, serif" data-font-style="300,700"><div></div><img loading="lazy" src="${font_martel}"></div>
            <div data-provider="google" data-font-family="Maven Pro, sans-serif"><div></div><img loading="lazy" src="${font_maven_pro}"></div>
            <div data-provider="google" data-font-family="Merriweather, serif" data-font-style="300,700"><div></div><img loading="lazy" src="${font_merriweather}"></div>
            <div data-provider="google" data-font-family="Merriweather Sans" data-font-style="300,700"><div></div><img loading="lazy" src="${font_merriweather_sans}"></div>
            <div data-provider="google" data-font-family="Mogra, cursive"><div></div><img loading="lazy" src="${font_mogra}"></div>
            <div data-provider="google" data-font-family="Monoton, cursive"><div></div><img loading="lazy" src="${font_monoton}"></div>
            <div data-provider="google" data-font-family="Montez, cursive"><div></div><img loading="lazy" src="${font_montez}"></div>
            <div data-provider="google" data-font-family="Montserrat, sans-serif" data-font-style="300,400,700"><div></div><img loading="lazy" src="${font_montserrat}"></div>
            <div data-provider="google" data-font-family="Montserrat Alternates, sans-serif" data-font-style="300,300i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${font_montserrat_alternates}"></div>
            <div data-provider="google" data-font-family="Montserrat Subrayada, sans-serif"><div></div><img loading="lazy" src="${font_montserrat_subrayada}"></div>
            <div data-provider="google" data-font-family="Neucha, cursive"><div></div><img loading="lazy" src="${font_neucha}"></div>
            <div data-provider="google" data-font-family="Neuton, serif" data-font-style="200,700"><div></div><img loading="lazy" src="${font_neuton}"></div>
            <div data-provider="google" data-font-family="Nixie One, cursive"><div></div><img loading="lazy" src="${font_nixie_one}"></div>
            <div data-provider="google" data-font-family="Nothing You Could Do, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${font_nothing_you_could_do}"></div>
            <div data-provider="google" data-font-family="Noto Sans, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${font_noto_sans}"></div>
            <div data-provider="google" data-font-family="Noto Sans SC, sans-serif" data-font-style="300,500,700,900"><div></div><img loading="lazy" src="${font_noto_sans_sc}"></div>
            <div data-provider="google" data-font-family="Noto Serif, serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${font_noto_serif}"></div>
            <div data-provider="google" data-font-family="Noto Serif TC, serif" data-font-style="300,600,700,900"><div></div><img loading="lazy" src="${font_noto_serif_tc}"></div>
            <div data-provider="google" data-font-family="Nunito, sans-serif" data-font-style="200,200i,600,600i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${font_nunito}"></div>
            <div data-provider="google" data-font-family="Old Standard TT, serif" data-font-style="400,400i,700"><div></div><img loading="lazy" src="${font_old_standard_tt}"></div>
            <div data-provider="google" data-font-family="Open Sans, sans-serif" data-font-style="300,400,600,800"><div></div><img loading="lazy" src="${font_open_sans}"></div>
            <div data-provider="google" data-font-family="Open Sans Condensed, sans-serif" data-font-style="300,300i,700"><div></div><img loading="lazy" src="${font_open_sans_condensed}"></div>
            <div data-provider="google" data-font-family="Oranienbaum, serif"><div></div><img loading="lazy" src="${font_oranienbaum}"></div>
            <div data-provider="google" data-font-family="Oswald, sans-serif" data-font-style="300,400,700"><div></div><img loading="lazy" src="${font_oswald}"></div>
            <div data-provider="google" data-font-family="Oxygen, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${font_oxygen}"></div>
            <div data-provider="google" data-font-family="Pacifico, cursive"><div></div><img loading="lazy" src="${font_pacifico}"></div>
            <div data-provider="google" data-font-family="Pangolin, cursive"><div></div><img loading="lazy" src="${font_pangolin}"></div>
            <div data-provider="google" data-font-family="Passion One, cursive"><div></div><img loading="lazy" src="${font_passion_one}"></div>
            <div data-provider="google" data-font-family="Pathway Gothic One, sans-serif"><div></div><img loading="lazy" src="${font_pathway_gothic_one}"></div>
            <div data-provider="google" data-font-family="Pattaya, sans-serif"><div></div><img loading="lazy" src="${font_pattaya}"></div>
            <div data-provider="google" data-font-family="Petit Formal Script, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${font_petit_formal_script}"></div>
            <div data-provider="google" data-font-family="Philosopher, sans-serif"><div></div><img loading="lazy" src="${font_philosopher}"></div>
            <div data-provider="google" data-font-family="Play, sans-serif" data-font-style="400,700"><div></div><img loading="lazy" src="${font_play}"></div>
            <div data-provider="google" data-font-family="Playfair Display, serif" data-font-style="400,400i,700,700i,900,900i"><div></div><img loading="lazy" src="${font_playfair_display}"></div>
            <div data-provider="google" data-font-family="Playfair Display SC, serif" data-font-style="400,400i,700,700i,900,900i"><div></div><img loading="lazy" src="${font_playfair_display_sc}"></div>
            <div data-provider="google" data-font-family="Podkova, serif" data-font-style="400,600,700,800"><div></div><img loading="lazy" src="${font_podkova}"></div>
            <div data-provider="google" data-font-family="Poiret One, cursive"><div></div><img loading="lazy" src="${font_poiret_one}"></div>
            <div data-provider="google" data-font-family="Pompiere, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${font_pompiere}"></div>
            <div data-provider="google" data-font-family="Poppins, sans-serif" data-font-style="400,600"><div></div><img loading="lazy" src="${font_poppins}"></div>
            <div data-provider="google" data-font-family="Prata, serif"><div></div><img loading="lazy" src="${font_prata}"></div>
            <div data-provider="google" data-font-family="'Press Start 2P', cursive" data-font-display="swap"><div></div><img loading="lazy" src="${font_press_start_2p}"></div>
            <div data-provider="google" data-font-family="Prosto One, cursive"><div></div><img loading="lazy" src="${font_prosto_one}"></div>
            <div data-provider="google" data-font-family="PT Mono, monospace"><div></div><img loading="lazy" src="${font_pt_mono}"></div>
            <div data-provider="google" data-font-family="PT Sans, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${font_pt_sans}"></div>
            <div data-provider="google" data-font-family="PT Sans Caption, sans-serif" data-font-style="400,700"><div></div><img loading="lazy" src="${font_pt_sans_caption}"></div>
            <div data-provider="google" data-font-family="PT Sans Narrow, sans-serif" data-font-style="400,700"><div></div><img loading="lazy" src="${font_pt_sans_narrow}"></div>
            <div data-provider="google" data-font-family="PT Serif, serif" data-font-style="400,700"><div></div><img loading="lazy" src="${font_pt_serif}"></div>
            <div data-provider="google" data-font-family="PT Serif Caption, serif" data-font-style="400,700"><div></div><img loading="lazy" src="${font_pt_serif_caption}"></div>
            <div data-provider="google" data-font-family="Quattrocento Sans, sans-serif"><div></div><img loading="lazy" src="${font_quattrocento_sans}"></div>
            <div data-provider="google" data-font-family="Quattrocento, serif"><div></div><img loading="lazy" src="${font_quattrocento}"></div>
            <div data-provider="google" data-font-family="Quicksand, sans-serif"><div></div><img loading="lazy" src="${font_quicksand}"></div>
            <div data-provider="google" data-font-family="Qwigley, cursive"><div></div><img loading="lazy" src="${font_qwigley}"></div>
            <div data-provider="google" data-font-family="Raleway, sans-serif" data-font-style="100"><div></div><img loading="lazy" src="${font_raleway}"></div>
            <div data-provider="google" data-font-family="Raleway Dots, sans-serif"><div></div><img loading="lazy" src="${font_raleway_dots}"></div>
            <div data-provider="google" data-font-family="Redressed, cursive"><div></div><img loading="lazy" src="${font_redressed}"></div>
            <div data-provider="google" data-font-family="Ribeye Marrow, cursive"><div></div><img loading="lazy" src="${font_ribeye_marrow}"></div>
            <div data-provider="google" data-font-family="Righteous, cursive"><div></div><img loading="lazy" src="${font_righteous}"></div>
            <div data-provider="google" data-font-family="Roboto, sans-serif" data-font-style="300"><div></div><img loading="lazy" src="${font_roboto}"></div>
            <div data-provider="google" data-font-family="Roboto Condensed, sans-serif" data-font-style="300,300i,700,700i"><div></div><img loading="lazy" src="${font_roboto_condensed}"></div>
            <div data-provider="google" data-font-family="Roboto Mono, monospace" data-font-style="300,700"><div></div><img loading="lazy" src="${font_roboto_mono}"></div>
            <div data-provider="google" data-font-family="Roboto Slab, serif" data-font-style="200,600,700,800,900"><div></div><img loading="lazy" src="${font_roboto_slab}"></div>
            <div data-provider="google" data-font-family="Rochester, cursive"><div></div><img loading="lazy" src="${font_rochester}"></div>
            <div data-provider="google" data-font-family="Rouge Script, cursive"><div></div><img loading="lazy" src="${font_rouge_script}"></div>
            <div data-provider="google" data-font-family="Rubik, sans-serif" data-font-style="300,300i,500,500i,700,700i,900,900i"><div></div><img loading="lazy" src="${font_rubik}"></div>
            <div data-provider="google" data-font-family="Rubik Mono One, sans-serif"><div></div><img loading="lazy" src="${font_rubik_mono_one}"></div>
            <div data-provider="google" data-font-family="Ruslan Display, cursive"><div></div><img loading="lazy" src="${font_ruslan_display}"></div>
            <div data-provider="google" data-font-family="Russo One, sans-serif"><div></div><img loading="lazy" src="${font_russo_one}"></div>
            <div data-provider="google" data-font-family="Sacramento, cursive"><div></div><img loading="lazy" src="${font_sacramento}"></div>
            <div data-provider="google" data-font-family="Sanchez, serif"><div></div><img loading="lazy" src="${font_sanchez}"></div>
            <div data-provider="google" data-font-family="Satisfy, cursive"><div></div><img loading="lazy" src="${font_satisfy}"></div>
            <div data-provider="google" data-font-family="Sawarabi Gothic, sans-serif"><div></div><img loading="lazy" src="${font_sawarabi_gothic}"></div>
            <div data-provider="google" data-font-family="Scada, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${font_scada}"></div>
            <div data-provider="google" data-font-family="Seaweed Script, cursive"><div></div><img loading="lazy" src="${font_seaweed_script}"></div>
            <div data-provider="google" data-font-family="Seymour One, sans-serif"><div></div><img loading="lazy" src="${font_seymour_one}"></div>
            <div data-provider="google" data-font-family="Shadows Into Light Two, cursive"><div></div><img loading="lazy" src="${font_shadows_into_light_two}"></div>
            <div data-provider="google" data-font-family="Six Caps, sans-serif"><div></div><img loading="lazy" src="${font_six_caps}"></div>
            <div data-provider="google" data-font-family="Snowburst One, cursive"><div></div><img loading="lazy" src="${font_snowburst_one}"></div>
            <div data-provider="google" data-font-family="Source Code Pro, monospace" data-font-style="300,700"><div></div><img loading="lazy" src="${font_source_code_pro}"></div>
            <div data-provider="google" data-font-family="Source Sans Pro, sans-serif" data-font-style="200"><div></div><img loading="lazy" src="${font_source_sans_pro}"></div>
            <div data-provider="google" data-font-family="Special Elite, cursive"><div></div><img loading="lazy" src="${font_special_elite}"></div>
            <div data-provider="google" data-font-family="Spectral, serif" data-font-style="200,200i,600,600i,700,700i,800,800i"><div></div><img loading="lazy" src="${font_spectral}"></div>
            <div data-provider="google" data-font-family="Spectral SC, serif" data-font-style="300,300i,600,600i,700,700i,800,800i"><div></div><img loading="lazy" src="${font_spectral_sc}"></div>
            <div data-provider="google" data-font-family="Squada One, cursive"><div></div><img loading="lazy" src="${font_squada_one}"></div>
            <div data-provider="google" data-font-family="Stalinist One, cursive"><div></div><img loading="lazy" src="${font_stalinist_one}"></div>
            <div data-provider="google" data-font-family="Stint Ultra Expanded, cursive"><div></div><img loading="lazy" src="${font_stint_ultra_expanded}"></div>
            <div data-provider="google" data-font-family="Syncopate, sans-serif"><div></div><img loading="lazy" src="${font_syncopate}"></div>
            <div data-provider="google" data-font-family="Tangerine, cursive"><div></div><img loading="lazy" src="${font_tangerine}"></div>
            <div data-provider="google" data-font-family="Tenor Sans, sans-serif"><div></div><img loading="lazy" src="${font_tenor_sans}"></div>
            <div data-provider="google" data-font-family="Tinos, serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${font_tinos}"></div>
            <div data-provider="google" data-font-family="Ubuntu, sans-serif" data-font-style="300,300i,500,500i,700,700i"><div></div><img loading="lazy" src="${font_ubuntu}"></div>
            <div data-provider="google" data-font-family="Ubuntu Condensed, sans-serif"><div></div><img loading="lazy" src="${font_ubuntu_condensed}"></div>
            <div data-provider="google" data-font-family="Ubuntu Mono, monospace" data-font-style="400,700"><div></div><img loading="lazy" src="${font_ubuntu_mono}"></div>
            <div data-provider="google" data-font-family="Underdog, cursive"><div></div><img loading="lazy" src="${font_underdog}"></div>
            <div data-provider="google" data-font-family="UnifrakturMaguntia, cursive"><div></div><img loading="lazy" src="${font_unifrakturmaguntia}"></div>
            <div data-provider="google" data-font-family="Vast Shadow, cursive"><div></div><img loading="lazy" src="${font_vast_shadow}"></div>
            <div data-provider="google" data-font-family="Viga, sans-serif"><div></div><img loading="lazy" src="${font_viga}"></div>
            <div data-provider="google" data-font-family="Vollkorn, serif" data-font-style="400,400i,600,600i,700,700i,900,900i"><div></div><img loading="lazy" src="${font_vollkorn}"></div>
            <div data-provider="google" data-font-family="Vollkorn SC, serif" data-font-style="400,600,700,900"><div></div><img loading="lazy" src="${font_vollkorn_sc}"></div>
            <div data-provider="google" data-font-family="Voltaire, sans-serif"><div></div><img loading="lazy" src="${font_voltaire}"></div>
            <div data-provider="google" data-font-family="Wire One, sans-serif"><div></div><img loading="lazy" src="${font_wire_one}"></div>
            <div data-provider="google" data-font-family="Yanone Kaffeesatz, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${font_yanone_kaffeesatz}"></div>
            <div data-provider="google" data-font-family="Yeseva One, cursive"><div></div><img loading="lazy" src="${font_yeseva_one}"></div>
            `;
            return;
        } 
        */

        html = `
        <div data-provider="" data-font-family="" style="font-size:12px;padding:10px 7px;box-sizing:border-box;"><div></div>
            <span style="z-index:1;position: relative;">${this.out('None')}</span>
        </div>
        <div data-provider="" data-font-family="Arial, sans-serif"><div></div><img loading="lazy" src="${path}arial.png"></div>
        <div data-provider="" data-font-family="courier"><div></div><img loading="lazy" src="${path}courier.png"></div>
        <div data-provider="" data-font-family="Georgia, serif"><div></div><img loading="lazy" src="${path}georgia.png"></div>
        <div data-provider="" data-font-family="monospace"><div></div><img loading="lazy" src="${path}monospace.png"></div>
        <div data-provider="" data-font-family="sans-serif"><div></div><img loading="lazy" src="${path}sans_serif.png"></div>
        <div data-provider="" data-font-family="serif"><div></div><img loading="lazy" src="${path}serif.png"></div>
        <div data-provider="google" data-font-family="Abel, sans-serif"><div></div><img loading="lazy" src="${path}abel.png"></div>
        <div data-provider="google" data-font-family="Abril Fatface"><div></div><img loading="lazy" src="${path}abril_fatface.png"></div>
        <div data-provider="google" data-font-family="Advent Pro, sans-serif" data-font-style="300"><div></div><img loading="lazy" src="${path}advent_pro.png"></div>
        <div data-provider="google" data-font-family="Aladin, cursive"><div></div><img loading="lazy" src="${path}aladin.png"></div>
        <div data-provider="google" data-font-family="Alegreya, serif" data-font-style="400,400i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${path}alegreya.png"></div>
        <div data-provider="google" data-font-family="Alegreya Sans SC, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${path}alegreya_sans_sc.png"></div>
        <div data-provider="google" data-font-family="Alegreya SC, serif" data-font-style="400,400i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${path}alegreya_sc.png"></div>
        <div data-provider="google" data-font-family="Alice, serif"><div></div><img loading="lazy" src="${path}alice.png"></div>
        <div data-provider="google" data-font-family="Allerta Stencil, sans-serif"><div></div><img loading="lazy" src="${path}allerta_stencil.png"></div>
        <div data-provider="google" data-font-family="Allura, cursive"><div></div><img loading="lazy" src="${path}allura.png"></div>
        <div data-provider="google" data-font-family="Almendra Display, cursive"><div></div><img loading="lazy" src="${path}almendra_display.png"></div>
        <div data-provider="google" data-font-family="Amatic SC, cursive" data-font-style="400,700"><div></div><img loading="lazy" src="${path}amatic_sc.png"></div>
        <div data-provider="google" data-font-family="Andika, sans-serif"><div></div><img loading="lazy" src="${path}andika.png"></div>
        <div data-provider="google" data-font-family="Anonymous Pro, monospace" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${path}anonymous_pro.png"></div>
        <div data-provider="google" data-font-family="Architects Daughter, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${path}architects_daughter.png"></div>
        <div data-provider="google" data-font-family="Arimo, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${path}arimo.png"></div>
        <div data-provider="google" data-font-family="Arsenal, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${path}arsenal.png"></div>
        <div data-provider="google" data-font-family="Assistant" data-font-style="300,700"><div></div><img loading="lazy" src="${path}assistant.png"></div>
        <div data-provider="google" data-font-family="Aubrey, cursive"><div></div><img loading="lazy" src="${path}aubrey.png"></div>
        <div data-provider="google" data-font-family="Anton, sans-serif"><div></div><img loading="lazy" src="${path}anton.png"></div>
        <div data-provider="google" data-font-family="Archivo Narrow, sans-serif"><div></div><img loading="lazy" src="${path}archivo_narrow.png"></div>
        <div data-provider="google" data-font-family="Bad Script, cursive"><div></div><img loading="lazy" src="${path}bad_script.png"></div>
        <div data-provider="google" data-font-family="BenchNine, sans-serif"><div></div><img loading="lazy" src="${path}benchNine.png"></div>
        <div data-provider="google" data-font-family="Bevan, cursive"><div></div><img loading="lazy" src="${path}bevan.png"></div>
        <div data-provider="google" data-font-family="Bigelow Rules, cursive"><div></div><img loading="lazy" src="${path}bigelow_rules.png"></div>
        <div data-provider="google" data-font-family="Bilbo, cursive"><div></div><img loading="lazy" src="${path}bilbo.png"></div>
        <div data-provider="google" data-font-family="Bonbon, cursive"><div></div><img loading="lazy" src="${path}bonbon.png"></div>
        <div data-provider="google" data-font-family="Bowlby One SC, cursive"><div></div><img loading="lazy" src="${path}bowlby_one_sc.png"></div>
        <div data-provider="google" data-font-family="Cabin Condensed, sans-serif"><div></div><img loading="lazy" src="${path}cabin_condensed.png"></div>
        <div data-provider="google" data-font-family="Carrois Gothic SC, sans-serif"><div></div><img loading="lazy" src="${path}carrois_gothic_sc.png"></div>
        <div data-provider="google" data-font-family="Caveat, cursive" data-font-style="400,700"><div></div><img loading="lazy" src="${path}caveat.png"></div>
        <div data-provider="google" data-font-family="Chewy, cursive"><div></div><img loading="lazy" src="${path}chewy.png"></div>
        <div data-provider="google" data-font-family="Cinzel, serif"><div></div><img loading="lazy" src="${path}cinzel.png"></div>
        <div data-provider="google" data-font-family="Comfortaa, cursive" data-font-style="300"><div></div><img loading="lazy" src="${path}comfortaa.png"></div>
        <div data-provider="google" data-font-family="Concert One, cursive"><div></div><img loading="lazy" src="${path}concert_one.png"></div>
        <div data-provider="google" data-font-family="Cormorant, serif" data-font-style="300,300i,600,600i,700,700i"><div></div><img loading="lazy" src="${path}cormorant.png"></div>
        <div data-provider="google" data-font-family="Cormorant Garamond, serif" data-font-style="300,300i,600,600i,700,700i"><div></div><img loading="lazy" src="${path}cormorant_garamond.png"></div>
        <div data-provider="google" data-font-family="Cormorant Infant, serif" data-font-style="300,300i,600,600i,700,700i"><div></div><img loading="lazy" src="${path}cormorant_infant.png"></div>
        <div data-provider="google" data-font-family="Cormorant SC, serif" data-font-style="300,600,700"><div></div><img loading="lazy" src="${path}cormorant_sc.png"></div>
        <div data-provider="google" data-font-family="Cormorant Unicase, serif" data-font-style="300,600,700"><div></div><img loading="lazy" src="${path}cormorant_unicase.png"></div>
        <div data-provider="google" data-font-family="Cousine" data-font-style="400,700"><div></div><img loading="lazy" src="${path}cousine.png"></div>
        <div data-provider="google" data-font-family="Crafty Girls, cursive"><div></div><img loading="lazy" src="${path}crafty_girls.png"></div>
        <div data-provider="google" data-font-family="Cuprum, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${path}cuprum.png"></div>
        <div data-provider="google" data-font-family="Cutive Mono, monospace"><div></div><img loading="lazy" src="${path}cutive_mono.png"></div>
        <div data-provider="google" data-font-family="Devonshire, cursive"><div></div><img loading="lazy" src="${path}devonshire.png"></div>
        <div data-provider="google" data-font-family="Didact Gothic, sans-serif"><div></div><img loading="lazy" src="${path}didact_gothic.png"></div>
        <div data-provider="google" data-font-family="Diplomata SC, cursive"><div></div><img loading="lazy" src="${path}diplomata_sc.png"></div>
        <div data-provider="google" data-font-family="Dosis, sans-serif" data-font-style="200"><div></div><img loading="lazy" src="${path}dosis.png"></div>
        <div data-provider="google" data-font-family="EB Garamond, serif" data-font-style="400,400i,600,600i,700,700i,800,800i"><div></div><img loading="lazy" src="${path}eb_garamond.png"></div>
        <div data-provider="google" data-font-family="El Messiri, sans-serif" data-font-style="400,600,700"><div></div><img loading="lazy" src="${path}el_messiri.png"></div>
        <div data-provider="google" data-font-family="Elsie, cursive" data-font-style="400,900"><div></div><img loading="lazy" src="${path}elsie.png"></div>
        <div data-provider="google" data-font-family="Encode Sans, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${path}encode_sans.png"></div>
        <div data-provider="google" data-font-family="Exo, sans-serif" data-font-style="100"><div></div><img loading="lazy" src="${path}exo.png"></div>
        <div data-provider="google" data-font-family="'Exo 2', sans-serif" data-font-style="200,200i,600,600i,700,700i,800,800i,900,900i" data-font-display="swap"><div></div><img loading="lazy" src="${path}exo_2.png"></div>
        <div data-provider="google" data-font-family="Felipa, cursive"><div></div><img loading="lazy" src="${path}felipa.png"></div>
        <div data-provider="google" data-font-family="Fira Code, monospace" data-font-style="300,500,600,700"><div></div><img loading="lazy" src="${path}fira_code.png"></div>
        <div data-provider="google" data-font-family="Fira Mono, monospace" data-font-style="400,500,700"><div></div><img loading="lazy" src="${path}fira_mono.png"></div>
        <div data-provider="google" data-font-family="Fira Sans, sans-serif" data-font-style="200,200i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${path}fira_sans.png"></div>
        <div data-provider="google" data-font-family="Fira Sans Condensed, sans-serif" data-font-style="200,200i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${path}fira_sans_condensed.png"></div>
        <div data-provider="google" data-font-family="Fira Sans Extra Condensed, sans-serif" data-font-style="200,200i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${path}fira_sans_extra_condensed.png"></div>
        <div data-provider="google" data-font-family="Fjalla One, sans-serif"><div></div><img loading="lazy" src="${path}fjalla_one.png"></div>
        <div data-provider="google" data-font-family="Forum, cursive"><div></div><img loading="lazy" src="${path}forum.png"></div>
        <div data-provider="google" data-font-family="Frank Ruhl Libre" data-font-style="300,700"><div></div><img loading="lazy" src="${path}frank_ruhl_libre.png"></div>
        <div data-provider="google" data-font-family="Fredericka the Great, cursive"><div></div><img loading="lazy" src="${path}fredericka_the_great.png"></div>
        <div data-provider="google" data-font-family="Gabriela, serif"><div></div><img loading="lazy" src="${path}gabriela.png"></div>
        <div data-provider="google" data-font-family="Gilda Display, serif"><div></div><img loading="lazy" src="${path}gilda_display.png"></div>
        <div data-provider="google" data-font-family="Give You Glory, cursive"><div></div><img loading="lazy" style="transform:scale(1.3);" src="${path}give_you_glory.png"></div>
        <div data-provider="google" data-font-family="Gruppo, cursive"><div></div><img loading="lazy" src="${path}gruppo.png"></div>
        <div data-provider="google" data-font-family="Handlee, cursive"><div></div><img loading="lazy" src="${path}handlee.png"></div>
        <div data-provider="google" data-font-family="Happy Monkey, cursive"><div></div><img loading="lazy" src="${path}happy_monkey.png"></div>
        <div data-provider="google" data-font-family="Hind" data-font-style="300,700"><div></div><img loading="lazy" src="${path}hind.png"></div>
        <div data-provider="google" data-font-family="IBM Plex Mono, monospace" data-font-style="300,300i,500,500i,600,600i,700,700i"><div></div><img loading="lazy" src="${path}ibm_plex_mono.png"></div>
        <div data-provider="google" data-font-family="IBM Plex Sans, sans-serif" data-font-style="300,300i,500,500i,600,600i,700,700i"><div></div><img loading="lazy" src="${path}ibm_plex_sans.png"></div>
        <div data-provider="google" data-font-family="IBM Plex Serif, serif" data-font-style="300,300i,500,500i,600,600i,700,700i"><div></div><img loading="lazy" src="${path}ibm_plex_serif.png"></div>
        <div data-provider="google" data-font-family="Iceland, cursive"><div></div><img loading="lazy" src="${path}iceland.png"></div>
        <div data-provider="google" data-font-family="Inconsolata, monospace" data-font-style="400,700"><div></div><img loading="lazy" src="${path}inconsolata.png"></div>
        <div data-provider="google" data-font-family="Josefin Sans, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${path}josefin_sans.png"></div>
        <div data-provider="google" data-font-family="Istok Web, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${path}istok_web.png"></div>
        <div data-provider="google" data-font-family="Julee, cursive"><div></div><img loading="lazy" src="${path}julee.png"></div>
        <div data-provider="google" data-font-family="Julius Sans One, sans-serif"><div></div><img loading="lazy" src="${path}julius_sans_one.png"></div>
        <div data-provider="google" data-font-family="Junge, serif"><div></div><img loading="lazy" src="${path}junge.png"></div>
        <div data-provider="google" data-font-family="Jura, sans-serif" data-font-style="300,600,700"><div></div><img loading="lazy" src="${path}jura.png"></div>
        <div data-provider="google" data-font-family="Just Me Again Down Here, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${path}just_me_again_down_here.png"></div>
        <div data-provider="google" data-font-family="Kaushan Script, cursive"><div></div><img loading="lazy" src="${path}kaushan_script.png"></div>
        <div data-provider="google" data-font-family="Kelly Slab, cursive"><div></div><img loading="lazy" src="${path}kelly_slab.png"></div>
        <div data-provider="google" data-font-family="Kite One, sans-serif"><div></div><img loading="lazy" src="${path}kite_one.png"></div>
        <div data-provider="google" data-font-family="Kosugi, sans-serif"><div></div><img loading="lazy" src="${path}kosugi.png"></div>
        <div data-provider="google" data-font-family="Kosugi Maru, sans-serif"><div></div><img loading="lazy" src="${path}kosugi_maru.png"></div>
        <div data-provider="google" data-font-family="Kurale, serif"><div></div><img loading="lazy" src="${path}kurale.png"></div>
        <div data-provider="google" data-font-family="Lato, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${path}lato.png"></div>
        <div data-provider="google" data-font-family="Ledger, serif" data-font-style="300,700" data-font-display="swap"><div></div><img loading="lazy" src="${path}ledger.png"></div>
        <div data-provider="google" data-font-family="Lekton, sans-serif" data-font-style="400,700"><div></div><img loading="lazy" src="${path}lekton.png"></div>
        <div data-provider="google" data-font-family="Life Savers, cursive"><div></div><img loading="lazy" src="${path}life_savers.png"></div>
        <div data-provider="google" data-font-family="Literata, serif" data-font-style="400,400i,600,600i,700,700i"><div></div><img loading="lazy" src="${path}literata.png"></div>
        <div data-provider="google" data-font-family="Lobster, cursive"><div></div><img loading="lazy" src="${path}lobster.png"></div>
        <div data-provider="google" data-font-family="Lobster Two, cursive"><div></div><img loading="lazy" src="${path}lobster_two.png"></div>
        <div data-provider="google" data-font-family="Londrina Shadow, cursive"><div></div><img loading="lazy" src="${path}londrina_shadow.png"></div>
        <div data-provider="google" data-font-family="Lora, serif" data-font-style="400,700"><div></div><img loading="lazy" src="${path}lora.png"></div>
        <div data-provider="google" data-font-family="Lovers Quarrel, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${path}lovers_quarrel.png"></div>
        <div data-provider="google" data-font-family="'M PLUS 1p', sans-serif" data-font-style="300,500,700,800,900" data-font-display="swap"><div></div><img loading="lazy" src="${path}m_plus_1p.png"></div>
        <div data-provider="google" data-font-family="'M PLUS Rounded 1c', sans-serif" data-font-style="300,500,700,800,900" data-font-display="swap"><div></div><img loading="lazy" src="${path}m_plus_rounded_1c.png"></div>
        <div data-provider="google" data-font-family="Macondo, cursive"><div></div><img loading="lazy" src="${path}macondo.png"></div>
        <div data-provider="google" data-font-family="Marcellus SC, serif"><div></div><img loading="lazy" src="${path}marcellus_sc.png"></div>
        <div data-provider="google" data-font-family="Marck Script, cursive"><div></div><img loading="lazy" src="${path}marck_script.png"></div>
        <div data-provider="google" data-font-family="Martel, serif" data-font-style="300,700"><div></div><img loading="lazy" src="${path}martel.png"></div>
        <div data-provider="google" data-font-family="Maven Pro, sans-serif"><div></div><img loading="lazy" src="${path}maven_pro.png"></div>
        <div data-provider="google" data-font-family="Merriweather, serif" data-font-style="300,700"><div></div><img loading="lazy" src="${path}merriweather.png"></div>
        <div data-provider="google" data-font-family="Merriweather Sans" data-font-style="300,700"><div></div><img loading="lazy" src="${path}merriweather_sans.png"></div>
        <div data-provider="google" data-font-family="Mogra, cursive"><div></div><img loading="lazy" src="${path}mogra.png"></div>
        <div data-provider="google" data-font-family="Monoton, cursive"><div></div><img loading="lazy" src="${path}monoton.png"></div>
        <div data-provider="google" data-font-family="Montez, cursive"><div></div><img loading="lazy" src="${path}montez.png"></div>
        <div data-provider="google" data-font-family="Montserrat, sans-serif" data-font-style="300,400,700"><div></div><img loading="lazy" src="${path}montserrat.png"></div>
        <div data-provider="google" data-font-family="Montserrat Alternates, sans-serif" data-font-style="300,300i,500,500i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${path}montserrat_alternates.png"></div>
        <div data-provider="google" data-font-family="Montserrat Subrayada, sans-serif"><div></div><img loading="lazy" src="${path}montserrat_subrayada.png"></div>
        <div data-provider="google" data-font-family="Neucha, cursive"><div></div><img loading="lazy" src="${path}neucha.png"></div>
        <div data-provider="google" data-font-family="Neuton, serif" data-font-style="200,700"><div></div><img loading="lazy" src="${path}neuton.png"></div>
        <div data-provider="google" data-font-family="Nixie One, cursive"><div></div><img loading="lazy" src="${path}nixie_one.png"></div>
        <div data-provider="google" data-font-family="Nothing You Could Do, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${path}nothing_you_could_do.png"></div>
        <div data-provider="google" data-font-family="Noto Sans, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${path}noto_sans.png"></div>
        <div data-provider="google" data-font-family="Noto Sans SC, sans-serif" data-font-style="300,500,700,900"><div></div><img loading="lazy" src="${path}noto_sans_sc.png"></div>
        <div data-provider="google" data-font-family="Noto Serif, serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${path}noto_serif.png"></div>
        <div data-provider="google" data-font-family="Noto Serif TC, serif" data-font-style="300,600,700,900"><div></div><img loading="lazy" src="${path}noto_serif_tc.png"></div>
        <div data-provider="google" data-font-family="Nunito, sans-serif" data-font-style="200,200i,600,600i,700,700i,800,800i,900,900i"><div></div><img loading="lazy" src="${path}nunito.png"></div>
        <div data-provider="google" data-font-family="Old Standard TT, serif" data-font-style="400,400i,700"><div></div><img loading="lazy" src="${path}old_standard_tt.png"></div>
        <div data-provider="google" data-font-family="Open Sans, sans-serif" data-font-style="300,400,600,800"><div></div><img loading="lazy" src="${path}open_sans.png"></div>
        <div data-provider="google" data-font-family="Open Sans Condensed, sans-serif" data-font-style="300,300i,700"><div></div><img loading="lazy" src="${path}open_sans_condensed.png"></div>
        <div data-provider="google" data-font-family="Oranienbaum, serif"><div></div><img loading="lazy" src="${path}oranienbaum.png"></div>
        <div data-provider="google" data-font-family="Oswald, sans-serif" data-font-style="300,400,700"><div></div><img loading="lazy" src="${path}oswald.png"></div>
        <div data-provider="google" data-font-family="Oxygen, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${path}oxygen.png"></div>
        <div data-provider="google" data-font-family="Pacifico, cursive"><div></div><img loading="lazy" src="${path}pacifico.png"></div>
        <div data-provider="google" data-font-family="Pangolin, cursive"><div></div><img loading="lazy" src="${path}pangolin.png"></div>
        <div data-provider="google" data-font-family="Passion One, cursive"><div></div><img loading="lazy" src="${path}passion_one.png"></div>
        <div data-provider="google" data-font-family="Pathway Gothic One, sans-serif"><div></div><img loading="lazy" src="${path}pathway_gothic_one.png"></div>
        <div data-provider="google" data-font-family="Pattaya, sans-serif"><div></div><img loading="lazy" src="${path}pattaya.png"></div>
        <div data-provider="google" data-font-family="Petit Formal Script, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${path}petit_formal_script.png"></div>
        <div data-provider="google" data-font-family="Philosopher, sans-serif"><div></div><img loading="lazy" src="${path}philosopher.png"></div>
        <div data-provider="google" data-font-family="Play, sans-serif" data-font-style="400,700"><div></div><img loading="lazy" src="${path}play.png"></div>
        <div data-provider="google" data-font-family="Playfair Display, serif" data-font-style="400,400i,700,700i,900,900i"><div></div><img loading="lazy" src="${path}playfair_display.png"></div>
        <div data-provider="google" data-font-family="Playfair Display SC, serif" data-font-style="400,400i,700,700i,900,900i"><div></div><img loading="lazy" src="${path}playfair_display_sc.png"></div>
        <div data-provider="google" data-font-family="Podkova, serif" data-font-style="400,600,700,800"><div></div><img loading="lazy" src="${path}podkova.png"></div>
        <div data-provider="google" data-font-family="Poiret One, cursive"><div></div><img loading="lazy" src="${path}poiret_one.png"></div>
        <div data-provider="google" data-font-family="Pompiere, cursive"><div></div><img loading="lazy" style="transform:scale(1.1);" src="${path}pompiere.png"></div>
        <div data-provider="google" data-font-family="Poppins, sans-serif" data-font-style="400,600"><div></div><img loading="lazy" src="${path}poppins.png"></div>
        <div data-provider="google" data-font-family="Prata, serif"><div></div><img loading="lazy" src="${path}prata.png"></div>
        <div data-provider="google" data-font-family="'Press Start 2P', cursive" data-font-display="swap"><div></div><img loading="lazy" src="${path}press_start_2p.png"></div>
        <div data-provider="google" data-font-family="Prosto One, cursive"><div></div><img loading="lazy" src="${path}prosto_one.png"></div>
        <div data-provider="google" data-font-family="PT Mono, monospace"><div></div><img loading="lazy" src="${path}pt_mono.png"></div>
        <div data-provider="google" data-font-family="PT Sans, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${path}pt_sans.png"></div>
        <div data-provider="google" data-font-family="PT Sans Caption, sans-serif" data-font-style="400,700"><div></div><img loading="lazy" src="${path}pt_sans_caption.png"></div>
        <div data-provider="google" data-font-family="PT Sans Narrow, sans-serif" data-font-style="400,700"><div></div><img loading="lazy" src="${path}pt_sans_narrow.png"></div>
        <div data-provider="google" data-font-family="PT Serif, serif" data-font-style="400,700"><div></div><img loading="lazy" src="${path}pt_serif.png"></div>
        <div data-provider="google" data-font-family="PT Serif Caption, serif" data-font-style="400,700"><div></div><img loading="lazy" src="${path}pt_serif_caption.png"></div>
        <div data-provider="google" data-font-family="Quattrocento Sans, sans-serif"><div></div><img loading="lazy" src="${path}quattrocento_sans.png"></div>
        <div data-provider="google" data-font-family="Quattrocento, serif"><div></div><img loading="lazy" src="${path}quattrocento.png"></div>
        <div data-provider="google" data-font-family="Quicksand, sans-serif"><div></div><img loading="lazy" src="${path}quicksand.png"></div>
        <div data-provider="google" data-font-family="Qwigley, cursive"><div></div><img loading="lazy" src="${path}qwigley.png"></div>
        <div data-provider="google" data-font-family="Raleway, sans-serif" data-font-style="100"><div></div><img loading="lazy" src="${path}raleway.png"></div>
        <div data-provider="google" data-font-family="Raleway Dots, sans-serif"><div></div><img loading="lazy" src="${path}raleway_dots.png"></div>
        <div data-provider="google" data-font-family="Redressed, cursive"><div></div><img loading="lazy" src="${path}redressed.png"></div>
        <div data-provider="google" data-font-family="Ribeye Marrow, cursive"><div></div><img loading="lazy" src="${path}ribeye_marrow.png"></div>
        <div data-provider="google" data-font-family="Righteous, cursive"><div></div><img loading="lazy" src="${path}righteous.png"></div>
        <div data-provider="google" data-font-family="Roboto, sans-serif" data-font-style="300"><div></div><img loading="lazy" src="${path}roboto.png"></div>
        <div data-provider="google" data-font-family="Roboto Condensed, sans-serif" data-font-style="300,300i,700,700i"><div></div><img loading="lazy" src="${path}roboto_condensed.png"></div>
        <div data-provider="google" data-font-family="Roboto Mono, monospace" data-font-style="300,700"><div></div><img loading="lazy" src="${path}roboto_mono.png"></div>
        <div data-provider="google" data-font-family="Roboto Slab, serif" data-font-style="200,600,700,800,900"><div></div><img loading="lazy" src="${path}roboto_slab.png"></div>
        <div data-provider="google" data-font-family="Rochester, cursive"><div></div><img loading="lazy" src="${path}rochester.png"></div>
        <div data-provider="google" data-font-family="Rouge Script, cursive"><div></div><img loading="lazy" src="${path}rouge_script.png"></div>
        <div data-provider="google" data-font-family="Rubik, sans-serif" data-font-style="300,300i,500,500i,700,700i,900,900i"><div></div><img loading="lazy" src="${path}rubik.png"></div>
        <div data-provider="google" data-font-family="Rubik Mono One, sans-serif"><div></div><img loading="lazy" src="${path}rubik_mono_one.png"></div>
        <div data-provider="google" data-font-family="Ruslan Display, cursive"><div></div><img loading="lazy" src="${path}ruslan_display.png"></div>
        <div data-provider="google" data-font-family="Russo One, sans-serif"><div></div><img loading="lazy" src="${path}russo_one.png"></div>
        <div data-provider="google" data-font-family="Sacramento, cursive"><div></div><img loading="lazy" src="${path}sacramento.png"></div>
        <div data-provider="google" data-font-family="Sanchez, serif"><div></div><img loading="lazy" src="${path}sanchez.png"></div>
        <div data-provider="google" data-font-family="Satisfy, cursive"><div></div><img loading="lazy" src="${path}satisfy.png"></div>
        <div data-provider="google" data-font-family="Sawarabi Gothic, sans-serif"><div></div><img loading="lazy" src="${path}sawarabi_gothic.png"></div>
        <div data-provider="google" data-font-family="Scada, sans-serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${path}scada.png"></div>
        <div data-provider="google" data-font-family="Seaweed Script, cursive"><div></div><img loading="lazy" src="${path}seaweed_script.png"></div>
        <div data-provider="google" data-font-family="Seymour One, sans-serif"><div></div><img loading="lazy" src="${path}seymour_one.png"></div>
        <div data-provider="google" data-font-family="Shadows Into Light Two, cursive"><div></div><img loading="lazy" src="${path}shadows_into_light_two.png"></div>
        <div data-provider="google" data-font-family="Six Caps, sans-serif"><div></div><img loading="lazy" src="${path}six_caps.png"></div>
        <div data-provider="google" data-font-family="Snowburst One, cursive"><div></div><img loading="lazy" src="${path}snowburst_one.png"></div>
        <div data-provider="google" data-font-family="Source Code Pro, monospace" data-font-style="300,700"><div></div><img loading="lazy" src="${path}source_code_pro.png"></div>
        <div data-provider="google" data-font-family="Source Sans Pro, sans-serif" data-font-style="200"><div></div><img loading="lazy" src="${path}source_sans_pro.png"></div>
        <div data-provider="google" data-font-family="Special Elite, cursive"><div></div><img loading="lazy" src="${path}special_elite.png"></div>
        <div data-provider="google" data-font-family="Spectral, serif" data-font-style="200,200i,600,600i,700,700i,800,800i"><div></div><img loading="lazy" src="${path}spectral.png"></div>
        <div data-provider="google" data-font-family="Spectral SC, serif" data-font-style="300,300i,600,600i,700,700i,800,800i"><div></div><img loading="lazy" src="${path}spectral_sc.png"></div>
        <div data-provider="google" data-font-family="Squada One, cursive"><div></div><img loading="lazy" src="${path}squada_one.png"></div>
        <div data-provider="google" data-font-family="Stalinist One, cursive"><div></div><img loading="lazy" src="${path}stalinist_one.png"></div>
        <div data-provider="google" data-font-family="Stint Ultra Expanded, cursive"><div></div><img loading="lazy" src="${path}stint_ultra_expanded.png"></div>
        <div data-provider="google" data-font-family="Syncopate, sans-serif"><div></div><img loading="lazy" src="${path}syncopate.png"></div>
        <div data-provider="google" data-font-family="Tangerine, cursive"><div></div><img loading="lazy" src="${path}tangerine.png"></div>
        <div data-provider="google" data-font-family="Tenor Sans, sans-serif"><div></div><img loading="lazy" src="${path}tenor_sans.png"></div>
        <div data-provider="google" data-font-family="Tinos, serif" data-font-style="400,400i,700,700i"><div></div><img loading="lazy" src="${path}tinos.png"></div>
        <div data-provider="google" data-font-family="Ubuntu, sans-serif" data-font-style="300,300i,500,500i,700,700i"><div></div><img loading="lazy" src="${path}ubuntu.png"></div>
        <div data-provider="google" data-font-family="Ubuntu Condensed, sans-serif"><div></div><img loading="lazy" src="${path}ubuntu_condensed.png"></div>
        <div data-provider="google" data-font-family="Ubuntu Mono, monospace" data-font-style="400,700"><div></div><img loading="lazy" src="${path}ubuntu_mono.png"></div>
        <div data-provider="google" data-font-family="Underdog, cursive"><div></div><img loading="lazy" src="${path}underdog.png"></div>
        <div data-provider="google" data-font-family="UnifrakturMaguntia, cursive"><div></div><img loading="lazy" src="${path}unifrakturmaguntia.png"></div>
        <div data-provider="google" data-font-family="Vast Shadow, cursive"><div></div><img loading="lazy" src="${path}vast_shadow.png"></div>
        <div data-provider="google" data-font-family="Viga, sans-serif"><div></div><img loading="lazy" src="${path}viga.png"></div>
        <div data-provider="google" data-font-family="Vollkorn, serif" data-font-style="400,400i,600,600i,700,700i,900,900i"><div></div><img loading="lazy" src="${path}vollkorn.png"></div>
        <div data-provider="google" data-font-family="Vollkorn SC, serif" data-font-style="400,600,700,900"><div></div><img loading="lazy" src="${path}vollkorn_sc.png"></div>
        <div data-provider="google" data-font-family="Voltaire, sans-serif"><div></div><img loading="lazy" src="${path}voltaire.png"></div>
        <div data-provider="google" data-font-family="Wire One, sans-serif"><div></div><img loading="lazy" src="${path}wire_one.png"></div>
        <div data-provider="google" data-font-family="Yanone Kaffeesatz, sans-serif" data-font-style="300,700"><div></div><img loading="lazy" src="${path}yanone_kaffeesatz.png"></div>
        <div data-provider="google" data-font-family="Yeseva One, cursive"><div></div><img loading="lazy" src="${path}yeseva_one.png"></div>
        `;

        return html;
    }


    getFontFamilyHTML(showInModal) {
        const html = `
        <!DOCTYPE HTML>
        <html>
        <head>
            <meta charset="utf-8">
            <title>Fonts</title>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <meta name="description" content="">  
            <style id="mainstyle">
                html, body {height:100%}
                body {overflow:hidden;margin:0;
                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                    font-size:100%; 
                    line-height:1.7;
                }
                #divFontList {margin:0;padding:0 0 9px 9px;height:100%;overflow-y:scroll !important;box-sizing:border-box;}
                #divFontList > div {width:100%;cursor:pointer;overflow:hidden;text-align:center;position:relative;}
                #divFontList > div img {margin:7px 5px 0px 5px;max-width: 230px;max-height: 27px;}
                #divFontList > div div {position:absolute;top:0;left:0;width:100%;height:100%;}

                #divFontList > div > div {
                    position:absolute;left:0px;top:0px;width:100%;height:100%;
                    // z-index: 1;
                }

                #divFontList > div {
                    color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                    background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
                }
                #divFontList > div > div:after {
                    // background: rgba(0, 0, 0, 0.04); 
                    background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
                    position: absolute;
                    content: "";
                    display: block;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                }
                #divFontList > div:hover > div:after {
                    opacity: 1;
                }
                #divFontList > div.on > div:after {
                    opacity: 1;
                }

                ${(!this.builder.styleDark && !this.builder.styleColoredDark && !this.builder.styleColore) ? `
                #divFontList > div img {
                    mix-blend-mode: multiply;
                }
                ` : ''}

                .dark #divFontList > div img {
                    ${(showInModal ? '' : 'mix-blend-mode: screen;')};
                    filter: invert(1);
                }
                .dark #divFontList > div {
                    color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                    background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
                }
                .dark #divFontList > div > div:after {
                    background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
                }
                
                .colored-dark #divFontList > div img {
                    ${(showInModal ? '' : 'mix-blend-mode: screen;')};
                    ${(showInModal ? '' : 'filter: invert(1);')};
                }
                .colored-dark #divFontList > div {
                    color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                    background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
                }
                .colored-dark #divFontList > div > div:after {
                    background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
                }


                .colored #divFontList > div img {
                    ${(showInModal ? '' : 'mix-blend-mode: screen;')};
                    ${(showInModal ? '' : 'filter: invert(1);')};
                }
                .colored #divFontList > div {
                    color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                    background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
                }
                .colored #divFontList > div > div:after {
                    background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
                }


                /* Scrollbar for toolbar/RTE and modal */

                .dark * {
                    scrollbar-width: thin;
                    scrollbar-color: rgba(255, 255, 255, 0.3) auto;
                }
                .dark *::-webkit-scrollbar {
                    width: 12px;
                }
                .dark *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .dark *::-webkit-scrollbar-thumb {
                    background-color:rgba(255, 255, 255, 0.3);
                } 

                .colored-dark * {
                    scrollbar-width: thin;
                    scrollbar-color: rgb(100, 100, 100) auto;
                }
                .colored-dark *::-webkit-scrollbar {
                    width: 12px;
                }
                .colored-dark *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .colored-dark *::-webkit-scrollbar-thumb {
                    background-color:rgb(100, 100, 100);
                } 

                .colored * {
                    scrollbar-width: thin;
                    scrollbar-color: rgba(0, 0, 0, 0.4) auto;
                }
                .colored *::-webkit-scrollbar {
                    width: 12px;
                }
                .colored *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .colored *::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, 0.4);
                } 

                .light * {
                    scrollbar-width: thin;
                    scrollbar-color: rgba(0, 0, 0, 0.4) auto;
                }
                .light *::-webkit-scrollbar {
                    width: 12px;
                }
                .light *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .light *::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, 0.4);
                } 
            </style>

        </head>
        <body${this.builder.styleDark ? ' class="dark"' : ''}${this.builder.styleColored ? ' class="colored"' : ''}${this.builder.styleColoredDark ? ' class="colored-dark"' : ''}${this.builder.styleLight ? ' class="light"' : ''}>

        <div id="divFontList">
        ${this.getFontPreview()}
        </div>

        <script type="text/javascript">
            var elms = document.querySelectorAll('#divFontList > div');
            for(var i=0;i<elms.length;i++) {
                elms[i].addEventListener('click', function(e){

                    var elm = e.target.parentNode;
                    var on = false;
                    if(elm.className) {
                        if(elm.className.indexOf('on')!==-1) {
                            on = true;
                        }
                    }
                    if(on) {
                        parent._cb.clearFont();
                    } else {
                        var provider = elm.getAttribute('data-provider');
                        var fontfamily = elm.getAttribute('data-font-family');
                        var fontstyle = elm.getAttribute('data-font-style');
                        var fontdisplay = elm.getAttribute('data-font-display');
                        //parent._cb.applyFont(fontfamily, fontstyle, provider);
                        parent._cb.setFont(fontfamily, fontstyle, fontdisplay, provider);
                    }

                });
            }
        </script>
        
        </body>
        </html>
                
        
        `;
        return html;
    }

    getFontFamilyEmail(showInModal) {
        // let path = this.builder.scriptPath + 'fonts/';
        let path = this.builder.fontAssetPath;

        const html = `
        <!DOCTYPE HTML>
        <html>
        <head>
            <meta charset="utf-8">
            <title>Fonts</title>
            <meta name="viewport" content="width=device-width, initial-scale=1">
            <meta name="description" content="">  
            <style>
                html, body {height:100%}
                body {overflow:hidden;margin:0;
                    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
                    font-size:100%; 
                    line-height:1.7;
                }
                #divFontList {margin:0;padding:0 0 9px 9px;height:100%;overflow-y:scroll !important;box-sizing:border-box;}
                #divFontList > div {width:100%;cursor:pointer;overflow:hidden;text-align:center;position:relative;}
                #divFontList > div img {margin:7px 5px 0px 5px;max-width: 230px;max-height: 27px;}
                #divFontList > div div {position:absolute;top:0;left:0;width:100%;height:100%;}

                #divFontList > div > div {
                    position:absolute;left:0px;top:0px;width:100%;height:100%;
                    // z-index: 1;
                }

                #divFontList > div {
                    color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                    background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
                }
                #divFontList > div > div:after {
                    // background: rgba(0, 0, 0, 0.04); 
                    background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
                    position: absolute;
                    content: "";
                    display: block;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                }
                #divFontList > div:hover > div:after {
                    opacity: 1;
                }
                #divFontList > div.on > div:after {
                    opacity: 1;
                }

                ${(!this.builder.styleDark && !this.builder.styleColoredDark && !this.builder.styleColore) ? `
                #divFontList > div img {
                    mix-blend-mode: multiply;
                }
                ` : ''}

                .dark #divFontList > div img {
                    ${(showInModal ? '' : 'mix-blend-mode: screen;')};
                    filter: invert(1);
                }
                .dark #divFontList > div {
                    color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                    background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
                }
                .dark #divFontList > div > div:after {
                    background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
                }
                
                .colored-dark #divFontList > div img {
                    ${(showInModal ? '' : 'mix-blend-mode: screen;')};
                    ${(showInModal ? '' : 'filter: invert(1);')};
                }
                .colored-dark #divFontList > div {
                    color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                    background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
                }
                .colored-dark #divFontList > div > div:after {
                    background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
                }


                .colored #divFontList > div img {
                    ${(showInModal ? '' : 'mix-blend-mode: screen;')};
                    ${(showInModal ? '' : 'filter: invert(1);')};
                }
                .colored #divFontList > div {
                    color: ${(showInModal ? this.builder.styleModalColor : this.builder.styleButtonColor)};
                    background: ${(showInModal ? this.builder.styleModalBackground : this.builder.styleToolBackground)};
                }
                .colored #divFontList > div > div:after {
                    background: ${(showInModal ? this.builder.styleButtonClassicBackgroundHover : this.builder.styleButtonBackgroundHover)};
                }


                /* Scrollbar for toolbar/RTE and modal */

                .dark * {
                    scrollbar-width: thin;
                    scrollbar-color: rgba(255, 255, 255, 0.3) auto;
                }
                .dark *::-webkit-scrollbar {
                    width: 12px;
                }
                .dark *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .dark *::-webkit-scrollbar-thumb {
                    background-color:rgba(255, 255, 255, 0.3);
                } 

                .colored-dark * {
                    scrollbar-width: thin;
                    scrollbar-color: rgb(100, 100, 100) auto;
                }
                .colored-dark *::-webkit-scrollbar {
                    width: 12px;
                }
                .colored-dark *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .colored-dark *::-webkit-scrollbar-thumb {
                    background-color:rgb(100, 100, 100);
                } 

                .colored * {
                    scrollbar-width: thin;
                    scrollbar-color: rgba(0, 0, 0, 0.4) auto;
                }
                .colored *::-webkit-scrollbar {
                    width: 12px;
                }
                .colored *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .colored *::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, 0.4);
                } 

                .light * {
                    scrollbar-width: thin;
                    scrollbar-color: rgba(0, 0, 0, 0.4) auto;
                }
                .light *::-webkit-scrollbar {
                    width: 12px;
                }
                .light *::-webkit-scrollbar-track {
                    background: transparent;
                }
                .light *::-webkit-scrollbar-thumb {
                    background-color: rgba(0, 0, 0, 0.4);
                } 
            </style>
        </head>
        <body${this.builder.styleDark ? ' class="dark"' : ''}${this.builder.styleColored ? ' class="colored"' : ''}${this.builder.styleColoredDark ? ' class="colored-dark"' : ''}${this.builder.styleLight ? ' class="light"' : ''}>

        <div id="divFontList">
        <div data-provider="" data-font-family="" style="font-size:12px;padding:10px 7px;box-sizing:border-box;"><div></div>None</div>
        <div data-provider="" data-font-family="Arial, sans-serif"><div></div><img loading="lazy" src="${path}arial.png"></div>
        <div data-provider="" data-font-family="courier"><div></div><img loading="lazy" src="${path}courier.png"></div>
        <div data-provider="" data-font-family="Georgia, serif"><div></div><img loading="lazy" src="${path}georgia.png"></div>
        <div data-provider="" data-font-family="monospace"><div></div><img loading="lazy" src="${path}monospace.png"></div>
        <div data-provider="" data-font-family="sans-serif"><div></div><img loading="lazy" src="${path}sans_serif.png"></div>
        <div data-provider="" data-font-family="serif"><div></div><img loading="lazy" src="${path}serif.png"></div>
        </div>

        <script type="text/javascript">
            var elms = document.querySelectorAll('#divFontList > div');
            for(var i=0;i<elms.length;i++) {
                elms[i].addEventListener('click', function(e){

                    var elm = e.target.parentNode;
                    var on = false;
                    if(elm.className) {
                        if(elm.className.indexOf('on')!==-1) {
                            on = true;
                        }
                    }
                    if(on) {
                        parent._cb.clearFont();
                    } else {
                        var provider = elm.getAttribute('data-provider');
                        var fontfamily = elm.getAttribute('data-font-family');
                        var fontstyle = elm.getAttribute('data-font-style');
                        var fontdisplay = elm.getAttribute('data-font-display');
                        //parent._cb.applyFont(fontfamily, fontstyle, provider);
                        parent._cb.setFont(fontfamily, fontstyle, fontdisplay, provider);
                    }

                });
            }

        </script>
        
        </body>
        </html>
                
        
        `;
        return html;
    }

}

export class Dom {

    createElement(tag) {
        return document.createElement(tag);
    }

    appendChild(parent, child) {
        if (parent) parent.appendChild(child);
    }

    appendHtml(parent, html) {
        if (parent) parent.insertAdjacentHTML('beforeend', html);
    }

    addEventListener(parent, type, listener) {
        if (parent) parent.addEventListener(type, listener);
    }

    addClass(element, classname) {
        if (!element) return;
        if (this.hasClass(element, classname)) return;
        if (element.classList.length === 0) element.className = classname;
        else element.className = element.className + ' ' + classname;
        element.className = element.className.replace(/  +/g, ' ');
        //else element.classList.add(classname); //error if there is -
    }

    removeClass(element, classname) {
        if (!element) return;
        if (element.classList.length > 0) {
            // element.className = element.className.replace(new RegExp('\\b'+ classname+'\\b', 'g'), '');
            // element.className = element.className.replace(/  +/g, ' ');

            let i, j, imax, jmax;
            let classesToDel = classname.split(' ');
            for (i = 0, imax = classesToDel.length; i < imax; ++i) {
                if (!classesToDel[i]) continue;
                let classtoDel = classesToDel[i];

                // https://jsperf.com/removeclass-methods 
                let sClassName = '';
                let currentClasses = element.className.split(' ');
                for (j = 0, jmax = currentClasses.length; j < jmax; ++j) {
                    if (!currentClasses[j]) continue;
                    if (currentClasses[j] !== classtoDel) sClassName += currentClasses[j] + ' ';
                }
                element.className = sClassName.trim();
            }

            if (element.className === '') element.removeAttribute('class');

        }
    }

    // https://plainjs.com/javascript/attributes/adding-removing-and-testing-for-classes-9/
    // addClass(element, classname) {
    //     console.log(element.classList)
    //     if (element.classList) element.classList.add(classname);
    //     else if (!this.hasClass(element, classname)) element.className += ' ' + classname;
    // }
    // removeClass(element, classname) {
    //     if (element.classList) element.classList.remove(classname);
    //     else element.className = element.className.replace(new RegExp('\\b'+ classname+'\\b', 'g'), '');
    // }
    hasClass(element, classname) {
        if (!element) return false;
        try {
            let s = element.getAttribute('class');
            return new RegExp('\\b' + classname + '\\b').test(s);
        } catch (e) {
            // Do Nothing
            // console.log(element);
        }
        //return element.classList ? element.classList.contains(classname) : new RegExp('\\b'+ classname+'\\b').test(element.className);
    }

    moveAfter(element, targetElement) {
        targetElement.parentNode.insertBefore(element, targetElement);
        targetElement.parentNode.insertBefore(targetElement, targetElement.previousElementSibling);
    }

    // https://stackoverflow.com/questions/10381296/best-way-to-get-child-nodes
    elementChildren(element) {
        const childNodes = element.childNodes;
        let children = [];
        let i = childNodes.length;
        while (i--) {
            if (childNodes[i].nodeType === 1 /*&& childNodes[i].tagName === 'DIV'*/) {
                children.unshift(childNodes[i]);
            }
        }
        return children;
    }

    parentsHasClass(element, classname) {
        while (element) {
            // if(classname==='is-side') console.log(element.nodeName); // NOTE: click on svg can still returns undefined in IE11
            if (!element.tagName) return false;
            if (element.tagName === 'BODY' || element.tagName === 'HTML') return false;
            // if(!element.classList) {
            //     console.log('no classList');
            //     return false;
            // }
            if (this.hasClass(element, classname)) {
                return true;
            }
            // TODO: if(element.nodeName.toLowerCase() === 'svg') console.log(element);
            element = element.parentNode;
        }
    }

    parentsHasId(element, id) {
        while (element) {
            if (!element.tagName) return false;
            if (element.tagName === 'BODY' || element.tagName === 'HTML') return false;
            if (element.id === id) {
                return true;
            }
            element = element.parentNode;
        }
    }

    parentsHasTag(element, tagname) {
        while (element) {
            if (!element.tagName) return false;
            if (element.tagName === 'BODY' || element.tagName === 'HTML') return false;
            if (element.tagName.toLowerCase() === tagname.toLowerCase()) {
                return true;
            }
            element = element.parentNode;
        }
    }

    parentsHasAttribute(element, attrname) {
        while (element) {
            if (!element.tagName) return false;
            if (element.tagName === 'BODY' || element.tagName === 'HTML') return false;
            try {
                if (element.hasAttribute(attrname)) { // error on svg element
                    return true;
                }
            } catch (e) {
                // Do Nothing
                // console.log(element);
                // return false;
            }
            element = element.parentNode;
        }
    }

    parentsHasElement(element, tagname) {
        while (element) {
            if (!element.tagName) return false;
            if (element.tagName === 'BODY' || element.tagName === 'HTML') return false;

            element = element.parentNode;

            if (!element) return false;
            if (!element.tagName) return false;

            if (element.tagName.toLowerCase() === tagname) {
                return true;
            }
        }
    }

    getParentElement(element, tagname) {
        while (element) {
            if (!element.tagName) return false;
            if (element.tagName === 'BODY' || element.tagName === 'HTML') return false;

            element = element.parentNode;

            if (!element) return false;
            if (!element.tagName) return false;

            if (element.tagName.toLowerCase() === tagname) {
                return element;
            }
        }
    }

    removeClasses(elms, classname) {
        for (let i = 0; i < elms.length; i++) {
            elms[i].classList.remove(classname);
        }
    }

    removeAttributes(elms, attrname) {
        for (let i = 0; i < elms.length; i++) {
            elms[i].removeAttribute(attrname);
        }
    }

    removeElements(elms) {
        Array.prototype.forEach.call(elms, (el) => {
            el.parentNode.removeChild(el);
        });
    }

    // source: https://stackoverflow.com/questions/2871081/jquery-setting-cursor-position-in-contenteditable-div
    moveCursorToElement(element) {
        var sel, range;
        if (window.getSelection && document.createRange) {
            range = document.createRange();
            range.selectNodeContents(element);
            range.collapse(false);
            sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (document.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(element);
            range.collapse(false);
            range.select();
        }
    }

    // source: https://stackoverflow.com/questions/6139107/programmatically-select-text-in-a-contenteditable-html-element
    selectElementContents(el) {
        var range = document.createRange();
        range.selectNodeContents(el);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }

    // Get selected text
    getSelected() {
        if (window.getSelection) {
            return window.getSelection().toString();
        } else if (document.getSelection) {
            return document.getSelection().toString();
        } else {
            var selection = document.selection && document.selection.createRange();
            if (selection.text) {
                return selection.text;
            }
            return false;
        }
    }

    checkEditable() {
        try {
            var el;
            var curr;
            if (window.getSelection) {
                curr = window.getSelection().getRangeAt(0).commonAncestorContainer;
                if (curr.nodeType === 3) {  //ini text node
                    el = curr.parentNode;
                } else {
                    el = curr;
                }
            } else if (document.selection) {
                curr = document.selection.createRange();
                el = document.selection.createRange().parentElement();
            }
            if (this.parentsHasAttribute(el, 'contenteditable')) return true;
            else return false;
        } catch (e) {
            return false;
        }
    }

    textSelection() {
        try {
            var elm;
            var curr = window.getSelection().getRangeAt(0).commonAncestorContainer;
            //console.log(curr.nodeType)
            if (curr.nodeType === 3) {  // text node
                elm = curr.parentNode;
                if (this.parentsHasClass(elm, 'is-builder')) {
                    return elm;
                } else {
                    return false;
                }
            } else {
                elm = curr;
                var nodeName = elm.nodeName.toLowerCase();
                if (nodeName === 'i' && elm.innerHTML === '') { //icon
                    if (this.parentsHasClass(elm, 'is-builder')) {
                        return elm;
                    }
                }

                // Check if a block (because when placing cursor using arrow keys on empty block, nodeType=1 not 3)
                if (nodeName === 'p' || nodeName === 'h1' || nodeName === 'h2'
                    || nodeName === 'h3' || nodeName === 'h4' || nodeName === 'h5'
                    || nodeName === 'h6' || nodeName === 'li' || nodeName === 'pre'
                    || nodeName === 'blockquote') {
                    return elm;
                }
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    getStyle(element, property) {
        return window.getComputedStyle ? window.getComputedStyle(element, null).getPropertyValue(property) : element.style[property.replace(/-([a-z])/g, function (g) {
            return g[1].toUpperCase();
        })];
    }

    /** added by Jack */
    doFunction(elm, fn, incChilds) {
        fn(elm);
        if (incChilds) {
            let elmns = Array.prototype.slice.call(elm.getElementsByTagName('*'));
            for (var i = 0; i < elmns.length; i++) {
                fn(elmns[i]);
            }
        }
    }

    // ---

}

